import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '@vendasta/galaxy/snackbar-service';
import { CompetitionAnalysis } from '@vendasta/snapshot';
import { Observable, combineLatest } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { SectionTableCompetitorItemInterface, SectionTableItem } from '../../section-table/section-table';
import { Media, MediaToken } from '../../providers/providers';
import { BaseSectionComponent } from '../../section/base-section.component';
import { SectionServiceInterfaceToken } from '../../section/section.service';
import { SnapshotReportService } from '../../snapshot-report/snapshot-report.service';
import { SocialService } from '../social.service';
import { createCompetitorGradeTable, createCompetitorGradeTableItem } from '../util';

@Component({
  selector: 'snap-twitter-subsection',
  templateUrl: './twitter-subsection.component.html',
  styleUrls: ['../social-section.component.scss'],
  providers: [
    {
      provide: SectionServiceInterfaceToken,
      useExisting: SocialService,
    },
  ],
  standalone: false,
})
export class TwitterSubsectionComponent extends BaseSectionComponent implements OnInit {
  twitterTableItems$: Observable<SectionTableItem[]> = new Observable<SectionTableItem[]>();
  competitorTwitterTableItems$: Observable<SectionTableItem[]> = new Observable<SectionTableItem[]>();

  isTwitterWorking = false;
  twitterUrlControl: UntypedFormControl = new UntypedFormControl('');

  constructor(
    @Inject(SectionServiceInterfaceToken) public service: SocialService,
    public snapshotService: SnapshotReportService,
    @Inject(MediaToken) public readonly media: Media,
    public translateService: TranslateService,
    public snackbarService: SnackbarService,
  ) {
    super(service, snackbarService, translateService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.twitterTableItems$ = this.service.data$.pipe(
      map((sd) => {
        return !sd.twitterData
          ? []
          : [
              {
                title: 'COMMON.FOLLOWERS',
                control: this.controls.controls['hideTwitterFollowers'],
                data: { ...sd.twitterData.followers, hasColor: true },
              },
              {
                title: 'SOCIAL.TWITTER.FOLLOWING',
                control: this.controls.controls['hideTwitterFollowing'],
                data: { ...sd.twitterData.following, hasColor: true },
              },
              {
                title: 'SOCIAL.TWITTER.TWEETS',
                control: this.controls.controls['hideTwitterTweets'],
                data: { ...sd.twitterData.tweets, hasColor: true },
              },
            ];
      }),
    );

    this.competitorTwitterTableItems$ = combineLatest([
      this.service.data$,
      this.snapshotService.competitionAnalysis$,
    ]).pipe(
      filter(([sc]) => !!sc.twitterData),
      map(([sc, ca]) => {
        if (ca === CompetitionAnalysis.INDUSTRY_DATA) {
          return [];
        }
        const competitorTwitterFollowers: SectionTableCompetitorItemInterface[] = [];
        sc.competitors.forEach((c: any) => {
          competitorTwitterFollowers.push(createCompetitorGradeTableItem(c.companyName, c.twitterData?.followers));
        });
        const twitterFollowers = createCompetitorGradeTable(
          competitorTwitterFollowers,
          'COMMON.FOLLOWERS',
          sc.twitterData?.followers,
          this.controls.controls['hideTwitterFollowers'],
        );

        const competitorTwitterFollowing: SectionTableCompetitorItemInterface[] = [];
        sc.competitors.forEach((c: any) => {
          competitorTwitterFollowing.push(createCompetitorGradeTableItem(c.companyName, c.twitterData?.following));
        });
        const twitterFollowing = createCompetitorGradeTable(
          competitorTwitterFollowing,
          'SOCIAL.TWITTER.FOLLOWING',
          sc.twitterData?.following,
          this.controls.controls['hideTwitterFollowing'],
        );

        const competitorTweets: SectionTableCompetitorItemInterface[] = [];
        sc.competitors.forEach((c: any) => {
          competitorTweets.push(createCompetitorGradeTableItem(c.companyName, c.twitterData?.tweets));
        });
        const tweets = createCompetitorGradeTable(
          competitorTweets,
          'SOCIAL.TWITTER.TWEETS',
          sc.twitterData?.tweets,
          this.controls.controls['hideTwitterTweets'],
        );

        return [twitterFollowers, twitterFollowing, tweets];
      }),
      startWith([]),
    );
  }

  addTwitterUrl(): void {
    const twitterUrl = this.twitterUrlControl.value;
    if (!twitterUrl) {
      return;
    }
    this.twitterUrlControl.markAsPending();
    this.service.updateTwitterUrl(this.snapshotService.businessId, this.twitterUrlControl.value).subscribe(
      () => {
        this.isTwitterWorking = true;
      },
      (err) => {
        this.twitterUrlControl.setErrors({ error: err.message });
        this.twitterUrlControl.setErrors({ message: 'COMMON.INVALID_URL' });
      },
    );
  }
}
