import { Component, ChangeDetectionStrategy } from '@angular/core';

/**
 * @deprecated Use `VaFilteredMatTableComponent`
 */
@Component({
  selector: 'va-mat-table',
  templateUrl: './va-mat-table.component.html',
  styleUrls: ['./va-mat-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class VaMaterialTableComponent {}
