import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ProductAnalyticsService } from '@vendasta/product-analytics';
import { ANALYTICS_CATEGORY } from './share-report';

@Component({
  selector: 'app-share-report-button',
  templateUrl: './share-report-button.component.html',
  styleUrls: ['./share-report-button.component.scss'],
  standalone: false,
})
export class ShareReportButtonComponent {
  @Output() shareSnapshotDialog: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() snapshotId: string;
  @Input() edit: boolean;

  constructor(private analyticsService: ProductAnalyticsService) {}

  onClick(): void {
    this.analyticsService.trackEvent('share-button', ANALYTICS_CATEGORY, 'click', 0, { snapshotId: this.snapshotId });
    this.shareSnapshotDialog.emit(this.edit);
  }
}
