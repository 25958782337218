import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CenterNavigationItem } from '@vendasta/atlas';
import {
  MediumConfiguration,
  NotificationContextInterface,
  NotificationMedium,
  NotificationSetting,
  NotificationSettingInterface,
  NotificationStatus,
  NotificationType,
  NotificationTypeInterface,
} from '@vendasta/notifications-sdk';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { AtlasConfigService, AtlasDataService } from '../../../../../core/src';
import { SnackbarService } from '../../../core-components/snackbar/snackbar.service';
import { NotificationsService } from '../../notifications.service';

enum CategoryState {
  On = 0,
  Off = 1,
  Indeterminate = 2,
}

const ACTIVITY_NOTIFICATION_TYPE_ID = 'business-center-activity';

@Component({
  selector: 'atlas-notification-settings-form',
  templateUrl: './settings-form.component.html',
  styleUrls: ['./settings-form.component.scss'],
  standalone: false,
})
export class SettingsFormComponent {
  @Input() set partnerId(partnerId: string) {
    this.partnerId$$.next(partnerId);
  }

  get partnerId(): string {
    return this.partnerId$$.getValue();
  }

  @Input() set userId(partnerId: string) {
    this.userId$$.next(partnerId);
  }

  get userId(): string {
    return this.userId$$.getValue();
  }

  private partnerId$$ = new BehaviorSubject<string>(null);
  private userId$$ = new BehaviorSubject<string>(null);

  private partnerIdContext$ = this.partnerId$$.asObservable().pipe(
    map((partnerId: string): NotificationContextInterface => {
      return { partner: { partnerId: partnerId } };
    }),
    distinctUntilChanged(),
    shareReplay(1),
  );

  private userIdContext$ = this.userId$$.asObservable().pipe(
    map((userId: string): NotificationContextInterface => {
      return { user: { userId: userId } };
    }),
    distinctUntilChanged(),
    shareReplay(1),
  );

  availableMedium = {
    App: NotificationMedium.NOTIFICATION_MEDIUM_WEB,
    Email: NotificationMedium.NOTIFICATION_MEDIUM_EMAIL,
  };
  enabledMedium: Map<string, NotificationMedium[]> = new Map();

  public saving = false;

  private VBCCenterData$: Observable<CenterNavigationItem> = this.dataService.centerData$.pipe(
    map((items: CenterNavigationItem[]) => items.find((item: CenterNavigationItem) => item.centerId === 'VBC')),
    shareReplay({ bufferSize: 1, refCount: true }),
  );
  activitySettingsURL$: Observable<string> = combineLatest([this.config.accountId$, this.dataService.userId$]).pipe(
    map(([accountId, userId]: [string, string]) => {
      if (!accountId || !userId) {
        return '';
      }
      return `/account/location/${accountId}/settings/notifications/${userId}/settings`;
    }),
  );
  whitelabelledBusinessCenterName$: Observable<string> = this.VBCCenterData$.pipe(
    filter((vbcCenterData: CenterNavigationItem) => !!vbcCenterData),
    map((vbcCenterData: CenterNavigationItem) => vbcCenterData.name),
  );

  sections: Map<string, NotificationTypeInterface[]> = new Map<string, NotificationTypeInterface[]>();
  checked: Map<string, boolean> = new Map<string, boolean>();
  rollbackCheckState: Map<string, boolean> = new Map<string, boolean>();
  error: boolean;

  partnerSettings$ = this.partnerIdContext$.pipe(
    filter((ctx) => !!ctx),
    switchMap((ctx) => this.notificationsService.settings$(this.partnerId, ctx)),
    catchError(() => {
      if (this.checked.size === 0) {
        this.snackbarService.show(
          this.translate.instant('NOTIFICATIONS.SETTINGS.SNACKBAR.FAILURE'),
          this.translate.instant('NOTIFICATIONS.ACTIONS.DISMISS'),
          { duration: 3000 },
        );
        this.error = true;
      }
      return of(null);
    }),
    filter((settings) => !!settings),
    tap((settings: NotificationSettingInterface[]) => {
      settings.forEach((setting) => {
        if (setting.context) {
          const enabled = setting.status === NotificationStatus.NOTIFICATION_STATUS_ENABLED;
          this.checked.set(this.checkboxId('partner', setting.notificationTypeId, setting.notificationMedium), enabled);
        }
      });
      this.rollbackCheckState = new Map<string, boolean>(this.checked);
    }),
  );

  userSettings$ = this.userIdContext$.pipe(
    filter((ctx) => !!ctx),
    switchMap((ctx) => this.notificationsService.settings$(this.partnerId, ctx)),
    catchError(() => {
      if (this.checked.size === 0) {
        this.snackbarService.show(
          this.translate.instant('NOTIFICATIONS.SETTINGS.SNACKBAR.FAILURE'),
          this.translate.instant('NOTIFICATIONS.ACTIONS.DISMISS'),
          { duration: 3000 },
        );
        this.error = true;
      }
      return of(null);
    }),
    filter((settings) => !!settings),
    tap((settings: NotificationSettingInterface[]) => {
      settings.forEach((setting) => {
        if (setting.context) {
          const enabled = setting.status === NotificationStatus.NOTIFICATION_STATUS_ENABLED;
          this.checked.set(this.checkboxId('user', setting.notificationTypeId, setting.notificationMedium), enabled);
        }
      });
      this.rollbackCheckState = new Map<string, boolean>(this.checked);
    }),
  );

  notificationTypes$ = this.partnerIdContext$.pipe(
    filter((ctx) => !!ctx),
    switchMap(() => this.notificationsService.listNotificationTypes$(this.partnerId)),
    catchError(() => {
      this.snackbarService.show(
        this.translate.instant('NOTIFICATIONS.SETTINGS.SNACKBAR.FAILURE'),
        this.translate.instant('NOTIFICATIONS.ACTIONS.DISMISS'),
        { duration: 3000 },
      );
      this.error = true;
      return of(null);
    }),
    filter((types) => !!types),
    tap((types: NotificationType[]) => {
      const out = new Map<string, NotificationTypeInterface[]>();
      const enabledMedium = new Map<string, NotificationMedium[]>();

      types.forEach((t: NotificationType) => {
        // Skip the special activity notification type -- it will surfaced specially as a link to VBC
        if (t.notificationTypeId === ACTIVITY_NOTIFICATION_TYPE_ID) {
          return;
        }
        const k = t.category.join(' > ');
        let ts = out.get(k);
        if (ts === undefined) {
          ts = [];
        }
        ts.push(t);
        out.set(k, ts);

        t.configurations.forEach((conf: MediumConfiguration) => {
          if (conf.disabled) {
            return;
          }
          const enabledMediumForType = enabledMedium.get(t.notificationTypeId);
          const medium = conf.medium;
          if (enabledMediumForType === undefined) {
            enabledMedium.set(t.notificationTypeId, [medium]);
          } else {
            enabledMediumForType.push(medium);
          }
        });
      });
      this.sections = out;
      this.enabledMedium = enabledMedium;
    }),
  );

  hasActivityNotificationType$: Observable<boolean> = this.notificationTypes$.pipe(
    map((types: NotificationType[]) =>
      types.some((t: NotificationType) => t.notificationTypeId === ACTIVITY_NOTIFICATION_TYPE_ID),
    ),
  );

  hasVBCData$: Observable<boolean> = this.VBCCenterData$.pipe(
    map((vbcCenterData: CenterNavigationItem) => !!vbcCenterData),
  );

  showActivitySettings$: Observable<boolean> = this.hasVBCData$.pipe(map((hasData) => hasData));

  formReady$ = combineLatest([this.partnerSettings$, this.userSettings$, this.notificationTypes$]);

  constructor(
    public notificationsService: NotificationsService,
    private translate: TranslateService,
    private snackbarService: SnackbarService,
    private dataService: AtlasDataService,
    private config: AtlasConfigService,
  ) {}

  clickCategoryIcon(category: string, event: Event): void {
    event.stopPropagation();
    const catState = this.getCategoryState(category);
    if (catState === CategoryState.On) {
      this.setEntireCategory(category, false);
    } else if (catState === CategoryState.Off) {
      this.setEntireCategory(category, true);
    } else {
      this.setEntireCategory(category, false);
    }
  }

  dirty(): boolean {
    let dirty = false;
    this.checked.forEach((v, k) => {
      const untouched = v === this.rollbackCheckState.get(k);
      if (!untouched) {
        dirty = true;
      }
    });
    return dirty;
  }

  private getCategoryState(category: string): CategoryState {
    const set = new Set();
    this.sections.get(category).forEach((type) => {
      const ownProps = Object.keys(this.availableMedium);
      let i = ownProps.length;
      const resArray = new Array(i);
      while (i--) {
        resArray[i] = [ownProps[i], this.availableMedium[ownProps[i]]];
      }
      for (const [, medium] of resArray) {
        const config = type.configurations.find((conf) => conf.medium === medium);
        if (config && config.disabled) {
          // If the config id disabled, don't include it in our check.
          continue;
        }
        set.add(this.checked.get(this.checkboxId('partner', type.notificationTypeId, medium)) || false);
        set.add(this.checked.get(this.checkboxId('user', type.notificationTypeId, medium)) || false);
      }
    });
    if (set.has(true) && set.has(false)) {
      return CategoryState.Indeterminate;
    }
    if (set.has(true)) {
      return CategoryState.On;
    }
    return CategoryState.Off;
  }

  getCategoryIcon(category: string): string {
    switch (this.getCategoryState(category)) {
      case CategoryState.Indeterminate:
        return 'notifications_none';
      case CategoryState.On:
        return 'notifications';
      default:
        return 'notifications_off';
    }
  }

  getCategoryEnabledState(category: string): string {
    switch (this.getCategoryState(category)) {
      case CategoryState.Indeterminate:
        return 'NOTIFICATIONS.SETTINGS.DESCRIPTION.SOME_ON';
      case CategoryState.On:
        return 'NOTIFICATIONS.SETTINGS.DESCRIPTION.ALL_ON';
      default:
        return 'NOTIFICATIONS.SETTINGS.DESCRIPTION.ALL_OFF';
    }
  }

  setEntireCategory(category: string, toggleState: boolean): void {
    const ts = this.sections.get(category);
    if (ts === undefined) {
      return;
    }

    ts.forEach((t) => {
      t.configurations.forEach((mediumConfig) => {
        this.checked.set(this.checkboxId('partner', t.notificationTypeId, mediumConfig.medium), toggleState);
        this.checked.set(this.checkboxId('user', t.notificationTypeId, mediumConfig.medium), toggleState);
      });
    });
  }

  public reset(): void {
    this.checked = new Map<string, boolean>(this.rollbackCheckState);
  }

  public save(): void {
    this.saving = true;
    combineLatest([this.partnerIdContext$, this.userIdContext$])
      .pipe(
        take(1),
        map(([partnerContext, userContext]: [NotificationContextInterface, NotificationContextInterface]) => {
          const ns: NotificationSetting[] = [];
          this.checked.forEach((v, k) => {
            // Get the notification ID from the checkbox id. The format is notificationid-medium-type, so this strips off the medium and type.
            const notificationId = k.split('~').slice(0, 1).join('-');
            // This strips off the notification id from the check box id and casts it into a Notification Medium.
            const ids = k.split('~')[1].split('-');
            const medium: NotificationMedium = NotificationMedium[NotificationMedium[+ids[0]]];
            const setting: string = ids[1];
            if (setting === 'user') {
              ns.push(
                new NotificationSetting({
                  notificationTypeId: notificationId,
                  notificationMedium: medium,
                  status: v
                    ? NotificationStatus.NOTIFICATION_STATUS_ENABLED
                    : NotificationStatus.NOTIFICATION_STATUS_DISABLED,
                  context: userContext,
                }),
              );
            } else {
              ns.push(
                new NotificationSetting({
                  notificationTypeId: notificationId,
                  notificationMedium: medium,
                  status: v
                    ? NotificationStatus.NOTIFICATION_STATUS_ENABLED
                    : NotificationStatus.NOTIFICATION_STATUS_DISABLED,
                  context: partnerContext,
                }),
              );
            }
          });
          return ns;
        }),
        switchMap((ns: NotificationSetting[]) => this.notificationsService.saveSettingMulti$(this.partnerId, ns)),
        catchError(() => {
          this.snackbarService.show(
            this.translate.instant('NOTIFICATIONS.SETTINGS.SNACKBAR.SAVE_FAILURE'),
            this.translate.instant('NOTIFICATIONS.ACTIONS.DISMISS'),
            { duration: 3000 },
          );
          this.checked = new Map<string, boolean>(this.rollbackCheckState);
          this.saving = false;
          return of(null);
        }),
        filter((resp) => !!resp),
      )
      .subscribe(() => {
        this.rollbackCheckState = new Map<string, boolean>(this.checked);
        this.snackbarService.show(this.translate.instant('NOTIFICATIONS.SETTINGS.SNACKBAR.UPDATED'), null, {
          duration: 3000,
        });
        this.saving = false;
      });
  }

  isMediumEnabled(category: string, notificationTypeId: string, medium: NotificationMedium): boolean {
    const mediumForType = this.enabledMedium.get(notificationTypeId);
    if (!mediumForType) {
      return false;
    }
    return mediumForType.indexOf(medium) > -1;
  }

  checkboxId(setting: string, notificationTypeId: string, medium: NotificationMedium): string {
    return `${notificationTypeId}~${medium}-${setting}`;
  }

  getTranslationKeyForMedium(medium: NotificationMedium): string {
    switch (medium) {
      case NotificationMedium.NOTIFICATION_MEDIUM_WEB:
        return 'NOTIFICATIONS.SETTINGS.MEDIUMS.APP';
      case NotificationMedium.NOTIFICATION_MEDIUM_EMAIL:
        return 'NOTIFICATIONS.SETTINGS.MEDIUMS.EMAIL';
      default:
        return 'NOTIFICATIONS.SETTINGS.MEDIUMS.UNKNOWN';
    }
  }
}
