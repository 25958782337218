import {
  AfterContentChecked,
  AfterViewInit,
  Component,
  ContentChildren,
  effect,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  signal,
  ViewChild,
} from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { MenuItemComponent } from '../menu-item/menu-item.component';

import { AtlasMenuService } from './menu.service';

class Guid {
  static newGuid(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c: string) => {
      // tslint:disable-next-line:no-bitwise
      const r = (Math.random() * 16) | 0,
        // tslint:disable-next-line: no-bitwise
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
}

@Component({
  selector: 'atlas-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss', '../common.scss'],
  standalone: false,
})
export class MenuComponent implements OnInit, AfterViewInit, OnDestroy, AfterContentChecked {
  @ViewChild('menu', { static: true }) menu: ElementRef;
  @ContentChildren(MenuItemComponent) menuItems: MenuItemComponent[];
  @Input() relative: boolean;
  @Input() anchorRight: boolean;
  @Input() anchorBottom: boolean;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onClose: EventEmitter<null> = new EventEmitter<null>();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onOpen: EventEmitter<null> = new EventEmitter<null>();
  private anchor: HTMLElement;

  public id: string;

  _destroyOpenSubscriptions$$: Subject<void> = new Subject<void>();
  _destroyed$$: Subject<void> = new Subject<void>();
  isOpen = signal(false);

  constructor(
    private elRef: ElementRef,
    private menuService: AtlasMenuService,
    private injector: Injector,
  ) {
    this.id = Guid.newGuid();
  }

  ngOnInit(): void {
    if (this.relative) {
      this.elRef.nativeElement.parentElement.style.setProperty('position', 'relative');
      this.elRef.nativeElement.style.setProperty('position', 'absolute');
      this.elRef.nativeElement.style.setProperty('left', '0');
      this.elRef.nativeElement.style.setProperty('bottom', '0');
      this.elRef.nativeElement.style.setProperty('min-width', '100%');
      this.menu.nativeElement.style.setProperty('min-width', '100%');
    }
  }

  ngAfterContentChecked(): void {
    this.menuItems.map((tab: MenuItemComponent) => {
      tab.parentMenuId = this.id;
    });
  }

  ngAfterViewInit(): void {
    this.anchor = this.elRef.nativeElement.parentElement;

    effect(
      () => {
        if (this.isOpen()) {
          this.elRef.nativeElement.style.setProperty('display', 'flex');
          fromEvent(document, 'click')
            .pipe(takeUntil(this._destroyOpenSubscriptions$$))
            .subscribe((event: MouseEvent) => {
              if (
                !(
                  this.pointInBounds(event.clientX, event.clientY, this.menuRect) ||
                  this.pointInBounds(event.clientX, event.clientY, this.anchorRect)
                )
              ) {
                this.isOpen.set(false);
              }
            });

          fromEvent(window, 'resize')
            .pipe(takeUntil(this._destroyOpenSubscriptions$$))
            .subscribe(() => {
              this.repositionMenu();
            });

          this.menuService.closeMenuEvent$.pipe(takeUntil(this._destroyOpenSubscriptions$$)).subscribe((id: string) => {
            if (this.isOpen() && (!id || id === this.id)) {
              this.isOpen.set(false);
            }
          });
          this.onOpen.emit();
        } else {
          this._destroyOpenSubscriptions$$.next();
          this.onClose.emit();
        }
      },
      {
        injector: this.injector,
        allowSignalWrites: true,
      },
    );

    fromEvent(this.anchor, 'click')
      .pipe(
        takeUntil(this._destroyed$$),
        filter((event: MouseEvent) => this.pointInBounds(event.clientX, event.clientY, this.anchorRect)),
      )
      .subscribe(() => {
        this.toggleMenu();
      });
  }

  private get anchorRect(): DOMRect {
    return this.anchor.getBoundingClientRect() as DOMRect;
  }

  private get menuRect(): DOMRect {
    return this.menu.nativeElement.getBoundingClientRect() as DOMRect;
  }

  private pointInBounds(pointX: number, pointY: number, rect: DOMRect): boolean {
    return pointX >= rect.left && pointX <= rect.right && pointY >= rect.top && pointY <= rect.bottom;
  }

  private toggleMenu(): void {
    this.isOpen.set(!this.isOpen());
    this.repositionMenu();
  }

  private repositionMenu(): void {
    if (this.relative) {
      // tslint:disable-next-line: no-shadowed-variable
      let menuStyles = ``;
      if (this.anchorRight) {
        menuStyles += `right: 30px;`;
      }
      if (this.anchorBottom) {
        menuStyles += ` bottom: 0;`;
      } else {
        menuStyles += ` top: -30px;`;
      }
      this.menu.nativeElement.setAttribute('style', menuStyles);
      return;
    }
    if (!this.isOpen()) {
      return;
    }
    let menuStyles = `top: ${this.anchorRect.bottom}px;`;
    if (window.innerWidth <= 600) {
      menuStyles += ` left: 0; width: 100%;`;
      this.menu.nativeElement.setAttribute('style', menuStyles);
      return;
    }
    if (window.innerWidth / 2 < this.anchorRect.left) {
      menuStyles += ` left: ${this.anchorRect.right}px;`;
      menuStyles += ` transform: translate(-100%, 0%);`;
    } else {
      menuStyles += ` left: ${this.anchorRect.left}px;`;
    }
    menuStyles += ` min-width: ${this.anchorRect.width}px`;
    this.menu.nativeElement.setAttribute('style', menuStyles);
  }

  ngOnDestroy(): void {
    this._destroyed$$.next();
    this._destroyed$$.complete();
  }
}
