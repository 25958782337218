import { booleanAttribute, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';

export type ComponentSize = 'normal' | 'small';

@Component({
  selector: 'glxy-empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: false,
})
export class EmptyStateComponent {
  @HostBinding('class.glxy-empty-state') public true = 1;

  // Optional: set different size for the component layout. Affects spacing, text, image size
  @Input() size: ComponentSize = 'normal';
  // Optional: set a width constraint for the component
  @Input({ transform: booleanAttribute }) widthConstraint = true;

  @HostBinding('class.glxy-empty-state--width-constraint') get widthConstraintClass(): boolean {
    return this.widthConstraint && this.size === 'small';
  }

  @HostBinding('class') get componentSize(): string {
    return this.size === 'normal' ? '' : 'glxy-empty-state--' + this.size;
  }
}
