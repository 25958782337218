import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '@vendasta/galaxy/snackbar-service';
import { EMPTY, Subscription } from 'rxjs';
import { catchError, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { FooterCtaConfigInterface } from './section';
import { AbstractSectionService, SectionServiceInterfaceToken } from './section.service';

@Component({
  // abstract class
  template: ``,
  standalone: false,
})
export abstract class BaseSectionComponent implements OnInit, OnDestroy {
  @Input() edit = false;
  @Input() snapshotId: string;
  @Input() hideControl: UntypedFormControl;
  @Output() sectionFooterClick: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() sectionFooterEditClick = new EventEmitter<FooterCtaConfigInterface>();

  controls = new UntypedFormGroup({});
  subscriptions: Subscription[] = [];
  snackbarDuration = 3000;

  constructor(
    @Inject(SectionServiceInterfaceToken) public service: AbstractSectionService<any, any, any>,
    public snackbarService: SnackbarService,
    public translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    const configTemplate = this.service.newConfig();
    const onSuccess = () => {
      this.snackbarService.openSuccessSnack('EDIT_REPORT.UPDATE_CONFIG_SUCCESS', {
        duration: this.snackbarDuration,
      });
    };
    const onError = (err: any) => {
      this.snackbarService.openErrorSnack('EDIT_REPORT.ERRORS.UPDATE_CONFIG', {
        duration: this.snackbarDuration,
      });
      console.error(err);
      return EMPTY;
    };

    const controls = configTemplate.buildFormControls();
    for (const [controlKey, control] of controls) {
      this.controls.addControl(controlKey, control);
      this.subscriptions.push(
        control.valueChanges
          .pipe(
            distinctUntilChanged(),
            switchMap((v) => this.service.updateConfig({ [controlKey]: v }).pipe(catchError(onError))),
          )
          .subscribe(onSuccess),
      );
    }

    const groups = configTemplate.buildFormGroups();
    for (const [groupKey, group] of groups) {
      this.controls.addControl(groupKey, group);
      this.subscriptions.push(
        group.valueChanges
          .pipe(
            distinctUntilChanged(),
            switchMap((v) => this.service.updateConfig(v).pipe(catchError(onError))),
          )
          .subscribe(onSuccess),
      );
    }

    this.subscriptions.push(
      this.service.config$.subscribe((rc: any) => {
        const toSet: any = this.controls.value;
        for (const key in rc) {
          if (Object.prototype.hasOwnProperty.call(rc, key) && this.controls.contains(key)) {
            toSet[key] = rc[key] || null;
          }
        }
        this.controls.setValue(toSet, { emitEvent: false });
      }),
    );

    this.service.init();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  handleSectionFooterClick(): void {
    this.sectionFooterClick.emit(true);
  }

  handleSectionFooterEditClick(event: FooterCtaConfigInterface): void {
    this.sectionFooterEditClick.emit(event);
  }
}
