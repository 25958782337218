import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'httpStrip',
  standalone: false,
})
export class HttpStripPipe implements PipeTransform {
  transform(url: string): string {
    return url.replace(/^(https?:|)\/\//, '');
  }
}
