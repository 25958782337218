import { Component, Inject, OnInit } from '@angular/core';
import { SectionServiceInterfaceToken } from '../../section/section.service';
import { ListingService } from '../listing.service';
import { SnapshotReportService } from '../../snapshot-report/snapshot-report.service';
import { Observable } from 'rxjs';
import { DataProviderAccuracy } from '../listing-data';
import { map } from 'rxjs/operators';
import { ListingDistributionVisibilityStatus } from '../listing-distribution/listing-distribution';
import { DataProviderAccuracyStatusV2 } from '@vendasta/snapshot';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '@vendasta/galaxy/snackbar-service';
import { BaseSectionComponent } from '../../section/base-section.component';

@Component({
  selector: 'snap-listing-provider-subsection',
  templateUrl: './listing-provider-subsection.component.html',
  styleUrls: ['../listing-section.component.scss'],
  providers: [
    {
      provide: SectionServiceInterfaceToken,
      useExisting: ListingService,
    },
  ],
  standalone: false,
})
export class ListingProviderSubsectionComponent extends BaseSectionComponent implements OnInit {
  listingDistributionAccuracy$: Observable<DataProviderAccuracy> = new Observable<DataProviderAccuracy>();
  visibleDataProviderSources$: Observable<ListingDistributionVisibilityStatus> =
    new Observable<ListingDistributionVisibilityStatus>();

  constructor(
    @Inject(SectionServiceInterfaceToken) public service: ListingService,
    public snapshotService: SnapshotReportService,
    public translateService: TranslateService,
    public snackbarService: SnackbarService,
  ) {
    super(service, snackbarService, translateService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.listingDistributionAccuracy$ = this.service.data$.pipe(
      map((ld) => {
        return ld.dataProviderAccuracy;
      }),
    );
    this.visibleDataProviderSources$ = this.listingDistributionAccuracy$.pipe(
      map((ld) => {
        // we want to display only Foursquare or Factual in the table, not both
        // Factual should only be displayed for existing snapshots where we don't have data saved for Foursquare
        const showFoursquare = ld.foursquareStatus !== DataProviderAccuracyStatusV2.UNSPECIFIED_V2;
        const showFactual = ld.factualStatus !== DataProviderAccuracyStatusV2.UNSPECIFIED_V2 && !showFoursquare;

        return <ListingDistributionVisibilityStatus>{
          showInfogroup: ld.infogroupStatus !== DataProviderAccuracyStatusV2.UNSPECIFIED_V2,
          showNeustar: ld.neustarStatus !== DataProviderAccuracyStatusV2.UNSPECIFIED_V2,
          showFoursquare: showFoursquare,
          showFactual: showFactual,
        };
      }),
    );
  }
}
