import { Component, EventEmitter } from '@angular/core';

@Component({
  selector: 'atlas-panel-header',
  templateUrl: './panel-header.component.html',
  styleUrls: ['./panel-header.component.scss'],
  standalone: false,
})
export class PanelHeaderComponent {
  public collapsible: boolean;
  public collapsed: boolean;
  public collapsedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  collapseClicked(event: Event): void {
    event.stopPropagation();
    this.collapsed = !this.collapsed;
    this.collapsedChange.emit(this.collapsed);
  }
}
