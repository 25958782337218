import { Component, Inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Audit } from '../../website-data';
import { partitionAudits } from '../../website';
import { StatusSummaryInterface, SummaryItem } from '../../../status-summary/status-summary';
import { TranslateService } from '@ngx-translate/core';
import { Media, MediaToken } from '../../../providers/providers';
import { GradeColor } from '../../../../core';

export type ReportType = 'mobile' | 'desktop';

@Component({
  selector: 'snap-audit-report',
  templateUrl: './audit-report.component.html',
  styleUrls: ['./audit-report.component.scss'],
  standalone: false,
})
export class AuditReportComponent implements OnChanges {
  @Input() audits: Audit[];
  @Input() reportType: ReportType;

  statusSummary: StatusSummaryInterface;
  panelOpenState = false;

  constructor(
    private translateService: TranslateService,
    @Inject(MediaToken) public readonly media: Media,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.audits.currentValue) {
      this.statusSummary = this.buildStatusSummaryItems(this.audits);
    }
  }

  buildStatusSummaryItems(audits: Audit[]): StatusSummaryInterface {
    const partitionedAudits = partitionAudits(audits);
    return <StatusSummaryInterface>{
      SummaryItems: [
        <SummaryItem>{
          value: partitionedAudits?.shouldFix?.length,
          description: this.translateService.instant('WEBSITE.SHOULD_FIX'),
          icon: 'cancel',
          iconColor: GradeColor.negative,
        },
        <SummaryItem>{
          value: partitionedAudits?.considerFix?.length,
          description: this.translateService.instant('WEBSITE.CONSIDER_FIX'),
          icon: 'error',
          iconColor: GradeColor.neutral,
        },
        <SummaryItem>{
          value: partitionedAudits?.passed?.length,
          description: this.translateService.instant('WEBSITE.PASSED_RULES'),
          icon: 'check_circle',
          iconColor: GradeColor.positive,
        },
      ],
    };
  }
}
