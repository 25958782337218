<div
  class="avatar-wrapper"
  [style.background-color]="imageExists ? '#efefef' : computedBackgroundColor"
  [style.background-image]="imageExists ? 'url(' + src + ')' : null"
  [style.filter]="useGrayScale ? 'grayscale(100%)' : null"
  [style.font-size]="this.width + 'px'"
>
  @if (icon && !imageExists) {
    <ng-container *ngTemplateOutlet="avatarMatIcon; context: { icon: icon }" />
  }

  @if (svgIcon && !icon && !imageExists) {
    <mat-icon [inline]="true" [svgIcon]="svgIcon" class="avatar-svg-icon" />
  }

  @if (!imageExists && !icon && !svgIcon) {
    @if (computerAbbreviation !== 'UNKNOWN') {
      <span>{{ computerAbbreviation }}</span>
    } @else {
      <ng-container *ngTemplateOutlet="avatarMatIcon; context: { icon: 'person' }" />
    }
  }
</div>

<ng-template #avatarMatIcon let-icon="icon">
  <mat-icon [inline]="true" class="avatar-mat-icon">{{ icon }}</mat-icon>
</ng-template>
