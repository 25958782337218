import { AfterViewChecked, Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BarController, Chart, ChartConfiguration, ChartData, ChartDataset, LegendItem } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { ListingChartHeadingText, ListingChartReferenceData, ListingCompetitorsData } from './chart';
import { ChartColors } from '../../../core';

@Component({
  selector: 'snap-listing-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
  standalone: false,
})
export class ChartComponent implements OnInit, OnChanges, AfterViewChecked {
  @Input() public chartTitles: ListingChartHeadingText;
  @Input() public businessName: string;
  @Input() public businessCount: number;
  @Input() public competitors: ListingCompetitorsData[];
  @Input() public referenceData: ListingChartReferenceData;
  @Input() public maxCount: number;
  @Input() public units = '';

  public config: ChartConfiguration;
  datasets: ChartData;

  @ViewChild('chartGraph', { static: true }) chartGraph: ElementRef;
  @ViewChild('chartImage', { static: true }) chartImage: ElementRef;
  @ViewChild('listingChart', { static: true }) listingChart: ElementRef;
  @ViewChild('listingImage', { static: true }) listingImage: ElementRef;

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    Chart.register(ChartDataLabels);

    const maxChartBarLength = this.calculateMaxCount();
    this.createConfig(maxChartBarLength);
    this.refreshData(this.units);
    this.drawReferenceLine();
  }

  calculateMaxCount(): number {
    let mc = this.maxCount;
    this.competitors.forEach((c) => {
      if (c.value > mc) {
        mc = c.value;
      }
    });
    return mc;
  }

  createConfig(maxCount: number): void {
    const filterLabels = (legendItem: LegendItem, data): boolean => {
      return !!data.datasets[legendItem.datasetIndex].label;
    };
    this.config = {
      type: 'bar',
      data: {
        labels: [''],
        datasets: [],
      },

      options: {
        indexAxis: 'y',
        hover: { mode: null },
        plugins: {
          title: { display: false, text: '' },
          tooltip: { enabled: false },

          legend: {
            position: 'bottom',
            align: 'center',
            labels: {
              usePointStyle: true,
              boxWidth: 6,
              boxHeight: 6,

              filter: filterLabels,
            },
          },
        },
        maintainAspectRatio: false,
        responsive: true,
        events: ['click'],
        layout: {
          padding: {
            left: 16,
          },
        },
        scales: {
          x: {
            min: 0,
            max: maxCount,

            grid: {
              display: false,
            },
            display: false,
            ticks: {
              backdropPadding: {
                left: 20,
              },
              display: false,
              maxTicksLimit: maxCount,
            },
            afterBuildTicks: function (scale: { ticks: any[] }): any {
              scale.ticks = [];
              return scale.ticks;
            },
          },
          y: {
            display: false,
            grid: {
              display: false,
            },
            ticks: {
              display: false,
            },
          },
        },
      },
    };
  }

  ngOnChanges(): void {
    const maxChartBarLength = this.calculateMaxCount();
    this.createConfig(maxChartBarLength);
    this.refreshData(this.units);
    this.drawReferenceLine();
  }

  // Convert the chart into png picture, because dynamic chartjs can not be shown in PDF print version
  ngAfterViewChecked(): void {
    this.chartImage.nativeElement.src = this.chartGraph.nativeElement.toDataURL();
    if (this.chartImage.nativeElement.src !== '' && this.chartImage.nativeElement.src !== 'data:,') {
      if (this.listingChart.nativeElement.classList.contains('chart-hide')) {
        return;
      }
      this.listingChart.nativeElement.classList.add('chart-hide');
      this.listingImage.nativeElement.classList.remove('chart-hide');
    } else {
      this.listingChart.nativeElement.classList.remove('chart-hide');
    }
  }

  refreshData(units: string): void {
    const chartData: ChartData = {
      labels: [''],
      datasets: [],
    };
    chartData.datasets.push(
      {
        data: [0],
        backgroundColor: ChartColors.referenceLabel,
        barPercentage: 0,
        categoryPercentage: 1,
        datalabels: {
          anchor: 'start',
          color: ChartColors.businessCount,
          font: { size: 12, weight: 'bold' },
          align: 'end',
          offset: -4,
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          formatter: function (value: any, context: any): string {
            return 'Your Business';
          },
        },
      },
      {
        label: this.businessName,
        data: [this.businessCount],
        backgroundColor: ChartColors.businessCount,
        barPercentage: 1,
        categoryPercentage: 1,
        minBarLength: 20,
        datalabels: {
          anchor: 'end',
          color: ChartColors.datalabel,
          align: 'start',
          font: { weight: 'bold' },
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          formatter: function (value: any, context: any): string {
            return value + units;
          },
        },
      },
      {
        label: this.translate.instant(this.referenceData.label),
        data: [0],
        backgroundColor: ChartColors.referenceLabel,
        barPercentage: 0,
        categoryPercentage: 1,
        datalabels: {
          anchor: 'start',
          color: ChartColors.competitorsLabel,
          font: { size: 12, weight: 'bold' },
          align: 'end',
          offset: -4,
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          formatter: function (value: any, context: any): string {
            return 'Your Competitors';
          },
        },
      },
    );
    this.competitors.forEach((c, index) => {
      chartData.datasets.push(<ChartDataset>{
        label: c.name,
        data: [c.value],
        backgroundColor: ChartColors.competitors[index],
        barPercentage: 0.95,
        categoryPercentage: 1,
        minBarLength: 30,
        datalabels: {
          anchor: 'end',
          color: ChartColors.datalabel,
          align: 'start',
          font: { weight: 'bold' },
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          formatter: function (value: any, context: any): string {
            return value + units;
          },
        },
      });
    });
    this.datasets = chartData;
  }

  private drawReferenceLine(): void {
    // Create the plug in to draw the vertical line
    const referenceValue = Math.round(this.referenceData.value);
    const originalLineDraw = BarController.prototype.draw;
    Object.assign(BarController.prototype, {
      draw: function (args): void {
        originalLineDraw.apply(this, args);
        const chart: Chart = this.chart;
        const ctx = chart.ctx;
        const refVal = referenceValue;
        if (refVal) {
          const xaxis = chart.scales.x;
          const yaxis = chart.scales.y;
          const x1 = xaxis.getPixelForValue(refVal) - 10;
          const y1 = yaxis.top + 10;
          const x2 = xaxis.getPixelForValue(refVal) - 10;
          const y2 = yaxis.bottom;

          // value of the reference value converted to string
          const referenceLabel = refVal.toString();
          ctx.save();
          // draw dashed line for reference value
          ctx.beginPath();
          ctx.moveTo(x1, y1);
          ctx.strokeStyle = 'grey';
          ctx.setLineDash([3, 5]);
          ctx.lineTo(x2, y2);
          ctx.stroke();
          ctx.restore();
          // triangle to indicate reference value
          ctx.beginPath();
          ctx.moveTo(x1, y1);
          ctx.lineTo(x1 + 6, y1 - 8);
          ctx.lineTo(x1 - 6, y1 - 8);
          ctx.lineTo(x1 - 2, y1 - 2);
          ctx.closePath();
          // the outline
          ctx.lineWidth = 1;
          // the fill color
          ctx.fillStyle = 'rgb(241,183,157)';
          ctx.fill();
          // add avg number
          ctx.beginPath();
          ctx.font = '10px Arial';
          ctx.fillText(referenceLabel, x1 - 20, y1 + 1);
        }
      },
    });
  }
}
