import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { SnapshotReportService } from '../snapshot-report/snapshot-report.service';
import { WebsiteService } from './website.service';
import { SectionServiceInterfaceToken } from '../section/section.service';
import { BaseSectionComponent } from '../section/base-section.component';
import { analyticsCategory } from '../section-footer/section-footer.component';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '@vendasta/galaxy/snackbar-service';

@Component({
  selector: 'snap-website-section',
  templateUrl: './website-section.component.html',
  styleUrls: ['./website-section.component.scss'],
  providers: [
    {
      provide: SectionServiceInterfaceToken,
      useExisting: WebsiteService,
    },
  ],
  standalone: false,
})
export class WebsiteSectionComponent extends BaseSectionComponent implements OnInit {
  @Output() hasWebsiteData = new EventEmitter<boolean>();
  footerCategory = analyticsCategory.WEBSITE;

  constructor(
    @Inject(SectionServiceInterfaceToken) public service: WebsiteService,
    public snapshotService: SnapshotReportService,
    public translate: TranslateService,
    public snackbar: SnackbarService,
  ) {
    super(service, snackbar, translate);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
}
