import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TechnologyCategoryComponent } from '../../technology-category/technology-category.component';
import { TechnologyCategoryInterface } from '../../technology-category/technology';
import { SectionServiceInterfaceToken } from '../../section/section.service';
import { TechnologyService } from '../technology.service';
import { SnapshotReportService } from '../../snapshot-report/snapshot-report.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '@vendasta/galaxy/snackbar-service';
import { BaseSectionComponent } from '../../section/base-section.component';
import { Observable } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { GalaxyEmptyStateModule } from '@vendasta/galaxy/empty-state';

@Component({
  selector: 'snap-technology-section-content',
  imports: [
    CommonModule,
    TechnologyCategoryComponent,
    MatButtonModule,
    MatIconModule,
    TranslateModule,
    MatDividerModule,
    GalaxyEmptyStateModule,
  ],
  templateUrl: './technology-section-content.component.html',
  styleUrls: ['./technology-section-content.component.scss'],
  providers: [
    {
      provide: SectionServiceInterfaceToken,
      useExisting: TechnologyService,
    },
  ],
})
export class TechnologySectionContentComponent extends BaseSectionComponent implements OnInit {
  categories$: Observable<TechnologyCategoryInterface[]>;
  hasTechnologies$: Observable<boolean>;
  totalRows = 0;
  limitRows = 24;
  showAllRows = false;

  constructor(
    @Inject(SectionServiceInterfaceToken) public service: TechnologyService,
    public snapshotService: SnapshotReportService,
    public translate: TranslateService,
    public snackbar: SnackbarService,
  ) {
    super(service, snackbar, translate);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.categories$ = this.service.data$.pipe(
      switchMap((data) => this.service.getGroupingsFromTechnologyIds(data?.technologyIds)),
      tap((categories) => (this.totalRows = categories?.length ?? 0)),
      shareReplay(1),
    );

    this.hasTechnologies$ = this.categories$.pipe(map((categories) => categories?.length > 0));
  }

  toggleRows() {
    this.showAllRows = !this.showAllRows;
  }
}
