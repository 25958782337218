import { CdkOverlayOrigin, ConnectedPosition, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { booleanAttribute, Directive, ElementRef, HostListener, Input, OnChanges, OnDestroy } from '@angular/core';
import { TooltipComponent } from '../tooltip.component';

const HIDDEN_TEST_WAIT = 500;

@Directive({
  selector: '[glxyTooltip]',
  exportAs: 'glxyTooltip',
  standalone: false,
})
export class GalaxyTooltipDirective implements OnChanges, OnDestroy {
  private readonly tooltip: TooltipComponent;
  private readonly origin: CdkOverlayOrigin;
  show = false;

  private readonly overlayRef: OverlayRef;

  /** The text of the tooltip */
  @Input({ required: true }) glxyTooltip = '';

  /** An optional title that can be given to the tooltip */
  @Input() tooltipTitle?: string;

  /** Disables the tooltip when true */
  @Input({ transform: booleanAttribute }) glxyTooltipDisabled = false;

  /**
   * What positioning strategy to use for the tooltip. If empty, no positions are applied to the popover (it has a default position set)
   */
  @Input() tooltipPositions?: ConnectedPosition[] = [];

  /** Change the background color and text for high contrast with white backgrounds */
  @Input({ transform: booleanAttribute }) highContrast = true;

  private hiddenTestID = 0;

  @HostListener('mouseenter') onMouseEnter(): void {
    this.showTooltip();

    // Stop any previous tests, if any
    window.clearTimeout(this.hiddenTestID);
    // Kick off a new test loop
    this.testElementHidden();
  }

  @HostListener('mouseleave') onMouseLeave(): void {
    this.hideTooltip();

    // Stop current test from running
    window.clearTimeout(this.hiddenTestID);
  }

  constructor(
    private elementRef: ElementRef,
    private overlay: Overlay,
  ) {
    this.overlayRef = this.overlay.create({
      hasBackdrop: false,
    });
    this.tooltip = this.overlayRef?.attach(new ComponentPortal(TooltipComponent)).instance;
    this.origin = new CdkOverlayOrigin(this.elementRef);
    this.updateTooltipValues();
  }

  ngOnChanges(): void {
    this.updateTooltipValues();
  }

  ngOnDestroy(): void {
    this.overlayRef?.dispose();
  }

  updateTooltipValues(): void {
    if (this.tooltip) {
      this.tooltip.setOrigin(this.origin);
      this.tooltip.setTitle(this.tooltipTitle || '');
      this.tooltip.setText(this.glxyTooltip);
      this.tooltip.sethighContrast(this.highContrast);
      if (this.tooltipPositions?.length) {
        this.tooltip.setPositions(this.tooltipPositions);
      }
    }
  }

  showTooltip(): void {
    if (!this.glxyTooltipDisabled) {
      this.tooltip?.showTooltip();
    }
  }

  hideTooltip(): void {
    this.tooltip?.hideTooltip();
  }

  /**
   * Test to see if the parent element is in the DOM. If not, hide the tooltip
   */
  private testElementHidden(): void {
    if (!this.tooltip?.show) {
      return;
    }

    this.hiddenTestID = window.setTimeout(() => {
      if (!document.body.contains(this.elementRef.nativeElement)) {
        this.hideTooltip();
      }
      this.testElementHidden();
    }, HIDDEN_TEST_WAIT);
  }
}
