import { Directive, HostBinding } from '@angular/core';

@Directive({
  // target every <a target="_blank">...<a>
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'a[target=_blank]',
  standalone: false,
})
export class TargetBlankDirective {
  // will add <a ...ref='noopener noreferrer'>...</a>
  @HostBinding('attr.rel') rel = 'noopener noreferrer';
}
