import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '@vendasta/galaxy/snackbar-service';
import { CompetitionAnalysis, Grade } from '@vendasta/snapshot';
import { Observable, combineLatest } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { SectionTableCompetitorItemInterface, SectionTableItem } from '../../section-table/section-table';
import { Media, MediaToken } from '../../providers/providers';
import { BaseSectionComponent } from '../../section/base-section.component';
import { SectionServiceInterfaceToken } from '../../section/section.service';
import { SnapshotReportService } from '../../snapshot-report/snapshot-report.service';
import { SocialItem } from '../social-item';
import { SocialService } from '../social.service';
import { createCompetitorGradeTable, createCompetitorGradeTableItem } from '../util';

@Component({
  selector: 'snap-facebook-subsection',
  templateUrl: './facebook-subsection.component.html',
  styleUrls: ['../social-section.component.scss'],
  providers: [
    {
      provide: SectionServiceInterfaceToken,
      useExisting: SocialService,
    },
  ],
  standalone: false,
})
export class FacebookSubsectionComponent extends BaseSectionComponent implements OnInit {
  isFacebookWorking = false;
  facebookUrlControl: UntypedFormControl = new UntypedFormControl('');

  facebookTableItems$: Observable<SectionTableItem[]> = new Observable<SectionTableItem[]>();
  competitorFacebookTableItems$: Observable<SectionTableItem[]> = new Observable<SectionTableItem[]>();

  constructor(
    @Inject(SectionServiceInterfaceToken) public service: SocialService,
    public snapshotService: SnapshotReportService,
    @Inject(MediaToken) public readonly media: Media,
    public translateService: TranslateService,
    public snackbarService: SnackbarService,
  ) {
    super(service, snackbarService, translateService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.facebookTableItems$ = this.service.data$.pipe(
      map((sd) => {
        const defaultData = new SocialItem({
          grade: Grade.NO_GRADE,
          business: 0,
          industryAverage: 0,
          industryLeader: 0,
        });

        const fbLikes = { ...defaultData, ...sd.facebookData?.likes };
        const averagePostsPerMonthData = {
          ...defaultData,
          ...(sd.facebookData?.averagePostsPerMonth || sd.facebookData?.posts),
        };
        const averageLikesPerPost = { ...defaultData, ...sd.facebookData?.averageLikesPerPost };
        const averageSharesPerPost = { ...defaultData, ...sd.facebookData?.averageSharesPerPost };

        return !sd.facebookData
          ? []
          : [
              {
                title: 'SOCIAL.FACEBOOK.LIKES',
                control: this.controls.controls['hideFacebookLikes'],
                data: { ...fbLikes, hasColor: true },
              },
              {
                // One of averagePostsPerMonth or posts should always exist
                title: sd.facebookData.averagePostsPerMonth
                  ? 'SOCIAL.FACEBOOK.AVERAGE_POSTS_PER_MONTH'
                  : 'SOCIAL.FACEBOOK.POSTS',
                subtitle: 'SOCIAL.SIX_MONTHS',
                control: this.controls.controls['hideFacebookPosts'],
                data: {
                  ...averagePostsPerMonthData,
                  hasColor: true,
                },
              },
              {
                title: 'SOCIAL.FACEBOOK.AVERAGE_LIKES_PER_POST',
                control: this.controls.controls['hideFacebookAverageLikesPerPost'],
                data: { ...averageLikesPerPost, hasColor: true },
              },
              {
                title: 'SOCIAL.FACEBOOK.AVERAGE_SHARES_PER_POST',
                control: this.controls.controls['hideFacebookAverageSharesPerPost'],
                data: { ...averageSharesPerPost, hasColor: true },
              },
            ];
      }),
    );

    this.competitorFacebookTableItems$ = combineLatest([
      this.service.data$,
      this.snapshotService.competitionAnalysis$,
    ]).pipe(
      filter(([sc]) => !!sc.facebookData),
      map(([sc, ca]) => {
        if (ca === CompetitionAnalysis.INDUSTRY_DATA) {
          return [];
        }
        const competitorFacebookLikes: SectionTableCompetitorItemInterface[] = [];
        sc.competitors.forEach((c: any) => {
          competitorFacebookLikes.push(createCompetitorGradeTableItem(c.companyName, c.facebookData?.likes));
        });
        const facebookLikes = createCompetitorGradeTable(
          competitorFacebookLikes,
          'SOCIAL.FACEBOOK.LIKES',
          sc.facebookData?.likes,
          this.controls.controls['hideFacebookLikes'],
        );

        const competitorAveragePostPerMonth: SectionTableCompetitorItemInterface[] = [];
        sc.competitors.forEach((c: any) => {
          competitorAveragePostPerMonth.push(
            createCompetitorGradeTableItem(c.companyName, c.facebookData?.averagePostsPerMonth),
          );
        });
        const averagePostsPerMonth = createCompetitorGradeTable(
          competitorAveragePostPerMonth,
          'SOCIAL.FACEBOOK.AVERAGE_POSTS_PER_MONTH',
          sc.facebookData?.averagePostsPerMonth,
          this.controls.controls['hideFacebookPosts'],
          'SOCIAL.SIX_MONTHS',
        );

        const competitorAverageLikesPerPost: SectionTableCompetitorItemInterface[] = [];
        sc.competitors.forEach((c: any) => {
          competitorAverageLikesPerPost.push(
            createCompetitorGradeTableItem(c.companyName, c.facebookData?.averageLikesPerPost),
          );
        });
        const averageLikesPerPost = createCompetitorGradeTable(
          competitorAverageLikesPerPost,
          'SOCIAL.FACEBOOK.AVERAGE_LIKES_PER_POST',
          sc.facebookData?.averageLikesPerPost,
          this.controls.controls['hideFacebookAverageLikesPerPost'],
        );

        const competitorAverageSharesPerPost: SectionTableCompetitorItemInterface[] = [];
        sc.competitors.forEach((c: any) => {
          competitorAverageSharesPerPost.push(
            createCompetitorGradeTableItem(c.companyName, c.facebookData?.averageSharesPerPost),
          );
        });
        const averageSharesPerPost = createCompetitorGradeTable(
          competitorAverageSharesPerPost,
          'SOCIAL.FACEBOOK.AVERAGE_SHARES_PER_POST',
          sc.facebookData?.averageSharesPerPost,
          this.controls.controls['hideFacebookAverageSharesPerPost'],
        );

        return [facebookLikes, averagePostsPerMonth, averageLikesPerPost, averageSharesPerPost];
      }),
      startWith([]),
    );
  }

  addFacebookUrl(): void {
    const facebookUrl = this.facebookUrlControl.value;
    if (!facebookUrl) {
      return;
    }
    this.facebookUrlControl.markAsPending();
    this.service.updateFacebookUrl(this.snapshotService.businessId, this.facebookUrlControl.value).subscribe(
      () => {
        this.isFacebookWorking = true;
      },
      (err) => {
        this.facebookUrlControl.setErrors({ error: err.message });
        this.facebookUrlControl.setErrors({ message: 'COMMON.INVALID_URL' });
      },
    );
  }
}
