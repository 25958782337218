import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LanguageMapping } from '../../../../../core/src';

@Component({
  selector: 'atlas-language-list',
  templateUrl: './language-list.component.html',
  styleUrls: ['./language-list.component.scss'],
  standalone: false,
})
export class LanguageListComponent {
  @Input() languages: LanguageMapping[] = [];
  @Output() languageSelected: EventEmitter<string> = new EventEmitter<string>();

  selectLanguage(language: string): void {
    this.languageSelected.emit(language);
  }
}
