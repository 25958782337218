import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MapMarker, VicinitySearch } from '../local-seo';
import { Vicinity } from '@vendasta/snapshot';
import { GoogleMap } from '@angular/google-maps';

@Component({
  selector: 'snap-local-seo-map',
  templateUrl: 'local-seo-map.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class LocalSEOMapComponent implements AfterViewInit {
  @Input() markers: MapMarker[];
  @Input() search: VicinitySearch;
  @Output() markerClick: EventEmitter<number> = new EventEmitter<number>();
  bounds: google.maps.LatLngBounds = new google.maps.LatLngBounds();
  @ViewChild('map', { read: GoogleMap }) map: GoogleMap;

  ngAfterViewInit(): void {
    if (this.markers?.length > 1) {
      this.markers.forEach((marker: MapMarker) => {
        const point = new google.maps.LatLng(Number(marker.lat), Number(marker.lng));
        this.bounds.extend(point);
      });
      this.map.fitBounds(this.bounds, 1);
    }
  }

  protected onMarkerClick(index: number, vicinity: Vicinity): void {
    if (vicinity && vicinity !== Vicinity.VICINITY_CITY) {
      this.markerClick.emit(index);
    }
  }
  protected get Vicinity(): typeof Vicinity {
    return Vicinity;
  }
}
