import { Component, HostBinding, HostListener, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { GalaxyI18NModule } from '@vendasta/galaxy/i18n';
import { ConfirmationModalConfig } from '../confirmation-modal.service';
import {
  ConfirmationModalActionsDirective,
  ConfirmationModalSecondaryActionsDirective,
  ModalActionsComponent,
} from '../modal-actions/modal-actions.component';
import {
  ConfirmationModalIconDirective,
  ConfirmationModalMessageDirective,
  ConfirmationModalTitleDirective,
  ModalBodyComponent,
} from '../modal-body/modal-body.component';

@Component({
  selector: 'glxy-confirmation-modal',
  imports: [
    TranslateModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatCheckboxModule,
    GalaxyI18NModule,
    ModalBodyComponent,
    ModalActionsComponent,
    ConfirmationModalIconDirective,
    ConfirmationModalMessageDirective,
    ConfirmationModalTitleDirective,
    ConfirmationModalActionsDirective,
    ConfirmationModalSecondaryActionsDirective,
  ],
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent {
  @HostBinding('class') class = 'glxy-confirmation-modal';

  @HostListener('document:keydown.enter', ['$event']) onEnter(event: KeyboardEvent) {
    // fire the confirm action if the user presses enter
    // unless the user pressed enter on the cancel button
    const target = event?.target as HTMLButtonElement;
    const isCancelButton = target?.id === 'confirmation-modal-cancel-button';

    if (this.config.actionOnEnterKey !== false && !isCancelButton) {
      this.dialogActionClicked();
    }
  }

  public dialogRef = inject(MatDialogRef<ConfirmationModalComponent>);
  public config: ConfirmationModalConfig = inject(MAT_DIALOG_DATA);

  dialogActionClicked() {
    this.dialogRef.close(true);
  }
}
