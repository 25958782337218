import { Component, Inject } from '@angular/core';
import { SectionServiceInterfaceToken } from '../section/section.service';
import { BaseSectionComponent } from '../section/base-section.component';
import { SnapshotService } from '../snapshot.service';
import { SnackbarService } from '@vendasta/galaxy/snackbar-service';
import { TranslateService } from '@ngx-translate/core';
import { TechnologyService } from './technology.service';
import { analyticsCategory } from '../section-footer/section-footer.component';
import { TechnologySectionExistsToken } from '../providers/providers';
import { Observable, map } from 'rxjs';

@Component({
  selector: 'snap-technology-section',
  templateUrl: './technology-section.component.html',
  styleUrls: ['./technology-section.component.scss'],
  providers: [
    {
      provide: SectionServiceInterfaceToken,
      useExisting: TechnologyService,
    },
  ],
  standalone: false,
})
export class TechnologySectionComponent extends BaseSectionComponent {
  footerCategory = analyticsCategory.TECHNOLOGY;
  // Edit mode, only show the section if the snapshot has the section entity
  // View mode, always show the section because it has already handled by the enabledSections array
  showSection$ = this.technologySectionExists$.pipe(map((exists) => exists || !this.edit));

  constructor(
    @Inject(SectionServiceInterfaceToken) public service: TechnologyService,
    public snapshotService: SnapshotService,
    public snackbarService: SnackbarService,
    public translateService: TranslateService,
    @Inject(TechnologySectionExistsToken) private readonly technologySectionExists$: Observable<boolean>,
  ) {
    super(service, snackbarService, translateService);
  }
}
