import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { VaSearchInputDirective } from './search-box-input.directive';
import { SearchBoxService } from './search-box.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';

@Component({
  imports: [CommonModule, FormsModule, MatIconModule, VaSearchInputDirective],
  selector: 'va-search-box',
  template: `
    <div
      [ngClass]="{
        disabled: disabled,
        selected: isSelected && highlightWhenSelected,
        unselected: !(isSelected && highlightWhenSelected),
      }"
      class="search-box"
    >
      <input
        #input
        type="text"
        (keyup.enter)="submitSearch(input.value)"
        (input)="updateSearch(input.value)"
        (focus)="isSelected = true"
        (focusout)="isSelected = false"
        [disabled]="disabled"
        [value]="initial"
        [placeholder]="placeholder"
        vaSearchInput
      />
      <mat-icon>search</mat-icon>
    </div>
  `,
  styleUrls: ['search-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchBoxComponent implements OnInit {
  @ViewChild(VaSearchInputDirective, { static: true }) inputArea: VaSearchInputDirective;
  @Output() update = new EventEmitter<string>();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() submit = new EventEmitter<string>();
  @Input() highlightWhenSelected = true;
  @Input() disabled = false;
  @Input() initial = '';
  @Input() enabledSaveSearchTerm = false;
  @Input() contextKey = '';
  @Input() placeholder = '';
  isSelected = false;
  constructor(private searchBoxService: SearchBoxService) {}

  ngOnInit(): void {
    if (!this.initial && this.enabledSaveSearchTerm) {
      this.initial = this.searchBoxService.getSearchTerm(this.contextKey);
      if (this.initial) {
        this.update.emit(this.initial);
      }
    }
  }

  submitSearch(term: string): void {
    this.submit.emit(term);
    if (this.enabledSaveSearchTerm) {
      this.searchBoxService.saveSearchTerm(this.contextKey, term);
    }
    this.inputArea.selector();
  }

  updateSearch(term: string): void {
    this.update.emit(term);
    if (this.enabledSaveSearchTerm) {
      this.searchBoxService.saveSearchTerm(this.contextKey, term);
    }
  }

  focusOnSearchBox(): void {
    window.setTimeout(() => this.inputArea.focus(), 0);
  }
}
