import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormsModule, UntypedFormControl } from '@angular/forms';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { ToggledItemModule } from '../toggled-item/toggled-item.module';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TextFieldModule } from '@angular/cdk/text-field';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '../pipes/pipes.module';

@Component({
  selector: 'snap-content-banner',
  templateUrl: './content-banner.component.html',
  styleUrls: ['./content-banner.component.scss'],
  animations: [
    trigger('toggleSection', [
      state('on', style({ height: '*', overflow: 'hidden' })),
      state('off', style({ height: '0', overflow: 'hidden' })),
      transition('on <=> off', animate('600ms ease-in')),
    ]),
  ],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    ToggledItemModule,
    PipesModule,
    MatButtonModule,
    MatIconModule,
    MatProgressBarModule,
    MatFormFieldModule,
    MatInputModule,
    TextFieldModule,
  ],
})
export class ContentBannerComponent {
  @Input() edit: boolean;
  @Input() hideControl: UntypedFormControl;
  @Input() messageControl: UntypedFormControl;
  @Input() videoUrl: string;
  @Input() hideVideo: false;
  @Input() customizedMessage: string;
  @Input() contentId: string;

  @ViewChild('messageContent', { read: ElementRef }) messageElement: ElementRef;

  editingMessage = false;
  originalMessage = '';

  editMessage(): void {
    if (this.customizedMessage === '') {
      this.customizedMessage = this.messageElement?.nativeElement?.textContent?.trim();
    }
    this.originalMessage = this.customizedMessage;
    this.editingMessage = true;
  }

  saveMessage(m: string): void {
    this.editingMessage = false;
    this.messageControl.setValue(m);
    this.messageControl.markAsPending();
  }

  cancel(): void {
    this.editingMessage = false;
    this.customizedMessage = this.originalMessage;
  }

  resetToDefault(): void {
    this.customizedMessage = '';
    this.saveMessage('');
  }
}
