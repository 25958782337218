import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Input } from '@angular/core';
import { ScheduleMeetingService } from './schedule-meeting.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { ANALYTICS_CATEGORY, meetingsSettingsPath } from './schedule-meeting';
import { ProductAnalyticsService } from '@vendasta/product-analytics';
import { SnapshotReportService } from '@galaxy/snapshot';

@Component({
  selector: 'app-schedule-meeting',
  templateUrl: './schedule-meeting.component.html',
  styleUrls: ['./schedule-meeting.component.scss'],
  standalone: false,
})
export class ScheduleMeetingComponent implements OnInit {
  @Input() hideControl: UntypedFormControl;
  @Input() isPartnerConfig: boolean;

  isHostConfigured$: Observable<boolean>;

  constructor(
    private readonly router: Router,
    private readonly scheduleMeetingService: ScheduleMeetingService,
    private readonly analyticsService: ProductAnalyticsService,
    private readonly snapshotReportService: SnapshotReportService,
  ) {}

  ngOnInit(): void {
    this.isHostConfigured$ = this.scheduleMeetingService.isHostConfigured$;
  }

  redirectToMeetingsSettings(): void {
    const meetingsSettingsUrl = this.router.serializeUrl(this.router.createUrlTree([meetingsSettingsPath]));
    this.analyticsService.trackEvent('open-meetings-settings', ANALYTICS_CATEGORY, 'click', 0, {
      snapshotId: this.snapshotReportService.snapshotId,
    });
    window.open(meetingsSettingsUrl, '_blank');
  }
}
