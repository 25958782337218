import { Component } from '@angular/core';
import { BaseHeaderComponent } from '../section/base-header.component';
import { TechnologyService } from './technology.service';

@Component({
  selector: 'snap-technology-section-header',
  template: `
    <snap-grade-tagline
      [hideGrade]="true"
      heading="{{ 'TITLES.TECHNOLOGY' | translate }}"
      tagline="{{ 'TECHNOLOGY.TAGLINE' | translate }}"
    ></snap-grade-tagline>
  `,
  standalone: false,
})
export class TechnologySectionHeaderComponent extends BaseHeaderComponent {
  constructor(public service: TechnologyService) {
    super(service);
  }
}
