import { combineLatest as observableCombineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { Component, Inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SNAPSHOT_NAME_TOKEN, SnapshotReportService, WhitelabelService } from '@galaxy/snapshot';
import { SalesPersonService } from '../../services';

@Component({
  selector: 'app-cover-page',
  templateUrl: './cover-page.component.html',
  styleUrls: ['./cover-page.component.scss'],
  standalone: false,
})
export class CoverPageComponent implements OnInit {
  address$: Observable<string>;
  title$: Observable<string>;

  constructor(
    public readonly whitelabel: WhitelabelService,
    private readonly snapshotReportService: SnapshotReportService,
    public readonly salesPersonService: SalesPersonService,
    private readonly translate: TranslateService,
    @Inject(SNAPSHOT_NAME_TOKEN) public readonly snapshotName$: Observable<string>,
  ) {}

  ngOnInit(): void {
    this.address$ = this.snapshotReportService.snapshotData$.pipe(
      map((d) => {
        const parts: string[] = [];
        if (d.address) {
          parts.push(d.address);
        }
        if (d.city) {
          parts.push(d.city);
        }
        if (d.state) {
          parts.push(d.state);
        }
        if (d.zip) {
          parts.push(d.zip);
        }
        return parts.join(', ');
      }),
    );
    this.title$ = observableCombineLatest([
      this.snapshotReportService.snapshotData$,
      this.whitelabel.partnerName$,
      this.salesPersonService.salesPerson$,
      this.whitelabel.snapshotReportName$,
    ]).pipe(
      map(([data, partnerName, salesPerson, customizedName]) => {
        const businessName = data.companyName || '&lt;Name of SMB&gt;';
        const snapshotName = this.translate.instant('TITLES.SNAPSHOT_REPORT');
        let title = snapshotName + ' for ' + businessName;

        if (customizedName) {
          return customizedName + ' for ' + businessName;
        }

        if (salesPerson && salesPerson.coverPageTitle === 'partnerForSmb') {
          title = partnerName + ' for ' + businessName;
        }
        if (salesPerson && salesPerson.coverPageTitle === 'marketForSmb') {
          title = partnerName + ' for ' + businessName;
        }
        return title;
      }),
      distinctUntilChanged(),
    );
  }
}
