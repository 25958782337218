import { booleanAttribute, Component, HostBinding, Input } from '@angular/core';
import { ComponentWithSizesComponent } from '@vendasta/galaxy/utility/component-with-sizes';

@Component({
  selector: 'glxy-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss'],
  standalone: false,
})
export class LoadingSpinnerComponent extends ComponentWithSizesComponent {
  /**
   * ComponentWithSizes adds a `size` input that controls
   * the size of the component.
   * Options are 'large' | 'default' | 'small'
   */
  @HostBinding('class') class = 'glxy-loading-spinner';

  @HostBinding('class.full-width')
  @Input({ transform: booleanAttribute })
  fullWidth = true;

  @HostBinding('class.full-height')
  @Input({ transform: booleanAttribute })
  fullHeight = false;

  @HostBinding('class.inline')
  @Input({ transform: booleanAttribute })
  inline = false;

  @HostBinding('style.padding')
  get padding(): string | null {
    if (this.inline) {
      return null;
    } else {
      return this.diameterPreset / 2 + 'px';
    }
  }

  get diameterPreset(): number {
    switch (this.size) {
      case 'large':
        return 60;
      case 'small':
        return 24;
      default:
        return 40;
    }
  }
}
