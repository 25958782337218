import { Component, EventEmitter, HostBinding, Input, Output, booleanAttribute } from '@angular/core';

export type AlertTypes = 'tip' | 'info' | 'warning' | 'success' | 'error' | 'important';

export type AlertSizes = 'large' | 'small';

const alertTypeIcons: { [key: string]: string } = {
  tip: 'info_outline',
  info: 'info',
  warning: 'warning',
  success: 'check',
  error: 'error',
  important: 'info_outline',
};

@Component({
  selector: 'glxy-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss', './alert-type-colors.scss'],
  standalone: false,
})
export class AlertComponent {
  @HostBinding('class.glxy-alert') public true = 1;

  // Choose the alert appearance and icon (optional)
  @Input() type: AlertTypes = 'info';

  // solid color alert appearance for more impact (optional)
  @Input({ transform: booleanAttribute }) solidColor = false;

  // use a custom mat-icon (optional)
  @Input() icon: string | null = null;

  // use a custom svg icon (optional)
  @Input() svgIcon: string | null = null;

  // select the size of the alert
  @Input() size: AlertSizes = 'large';

  // show or hide close button (optional)
  @Input({ transform: booleanAttribute }) showClose = false;
  // show or hide icon (optional)
  @Input({ transform: booleanAttribute }) showIcon = true;
  // custom close button text (optional)
  @Input() closeTitle = 'Dismiss';
  // event emitted when clicking the close button
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() close: EventEmitter<void> = new EventEmitter();

  // show or hide action button (optional)
  @Input({ transform: booleanAttribute }) showAction = false;
  // action button title text
  @Input() actionTitle = 'Action Button';
  // event emmited when action button clicked
  @Output() actionClick: EventEmitter<void> = new EventEmitter();
  // router link to navigate to when button clicked
  @Input() actionRouterLink: string | undefined;
  // external url to open in new tab when button clicked
  @Input() actionExternalLink = '';
  // show or hide mobile view
  @Input({ transform: booleanAttribute }) stackedView = false;

  // Hide or show the border on regular alert appearance (optional)
  @HostBinding('class.has-border')
  @Input({ transform: booleanAttribute })
  border = true;

  // Enable or disable the border-radius on the alert (optional)
  @HostBinding('class.has-border-radius')
  @Input({ transform: booleanAttribute })
  borderRadius = true;

  // Hide or show the border on regular alert appearance (optional)
  @HostBinding('class.extended-has-border')
  @Input({ transform: booleanAttribute })
  extendedBorder = true;

  // add class for alert type and appearance
  @HostBinding('class') get alertType(): string {
    const classIsSolid = this.solidColor ? '-solid' : '';
    return 'alert-' + this.type + classIsSolid + ' alert-size-' + this.size;
  }

  onCloseAlert(): void {
    this.close.emit();
  }

  onActionClicked(): void {
    this.actionClick.emit();
  }

  // if user specifies custom mat-icon, use that.
  // If no custom icon, show icons that matches alert type.
  protected get currentIcon(): string | null {
    if (this.svgIcon) {
      return null;
    }
    if (this.icon) {
      return this.icon;
    } else {
      return alertTypeIcons[this.type];
    }
  }
}
