import { Component, Input } from '@angular/core';
import { BaseHeaderComponent } from '../section/base-header.component';
import { EcommerceService } from './ecommerce.service';

@Component({
  selector: 'snap-ecommerce-section-header',
  template: `
    <snap-grade-tagline
      [hideGrade]="hideGrade"
      [grade]="service.grade$ | async"
      heading="{{ 'TITLES.ECOMMERCE' | translate }}"
      tagline="{{ 'ECOMMERCE.TAGLINE' | translate }}"
    ></snap-grade-tagline>
  `,
  styleUrls: ['./ecommerce-section.component.scss'],
  standalone: false,
})
export class EcommerceSectionHeaderComponent extends BaseHeaderComponent {
  @Input() hideGrade: boolean;

  constructor(public service: EcommerceService) {
    super(service);
  }
}
