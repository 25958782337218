import { Pipe, PipeTransform } from '@angular/core';
import { Locale } from 'date-fns';
import { formatDate, DateFormat } from '@vendasta/galaxy/utility/date-utils';

@Pipe({
  name: 'glxyDate',
  standalone: false,
})
export class GalaxyDatePipe implements PipeTransform {
  transform(value: string | number | Date, dateFormat?: DateFormat, locale?: Locale): any {
    try {
      const date = new Date(value);
      return formatDate(date, dateFormat, locale);
    } catch (err) {
      return 'Not a valid date';
    }
  }
}
