import { Pipe, PipeTransform } from '@angular/core';
import { FormControl, UntypedFormArray } from '@angular/forms';
import { FieldBase, FieldData } from './fields/field-base';

export interface FormOptions {
  rightCustomButtons?: ButtonOptions[];
  leftCustomButtons?: ButtonOptions[];
  useFixedFooter?: boolean;
  cancelUrl?: string;
  successUrl?: string;
  context?: any;
  formUrl: string;
}

export type ButtonHandler = (formdata?: any) => void;

export interface ButtonOptions {
  label: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  action: ButtonAction | ButtonHandler;
  color?: string;
  disableWhenFormInvalidOrLoading?: boolean;
}

export enum ButtonAction {
  SUBMIT = 1,
  CLEAR,
  CANCEL,
}

export interface SectionData {
  name?: string;
  label?: string;
  tooltip?: string;
  fields?: FieldBase<any>[];
  description?: string;
}

export class Section {
  name?: string;
  label?: string;
  tooltip?: string;
  fields?: FieldBase<any>[];
  description?: string;
  constructor(initialData: SectionData) {
    this.name = initialData.name;
    this.label = initialData.label;
    this.tooltip = initialData.tooltip;
    this.fields = initialData.fields;
    this.description = initialData.description;
  }

  public hasVisibleFields(): boolean {
    return this.fields.length > 0 && this.fields.filter((f) => f.visible === true).length > 0;
  }
}

export interface FormDefinition {
  sections: Section[];
  loading: boolean;
  catalogue?: CatalogueData;
}

export interface FormDefinitionHttpResponse {
  formdata: FieldData[];
  fields: FieldData[];
  sections: SectionData[];
  catalogue: CatalogueData;
}

export interface CategoryData {
  id: string;
  name: string;
}

export interface CatalogueData {
  source?: {
    columns: number;
    sources: {
      'Directory Site'?: SourceData[];
      'Review Site'?: SourceData[];
      'Search Engine'?: SourceData[];
      'Social Site'?: SourceData[];
      'Internet Data Provider'?: SourceData[];
    };
  };
  taxonomy?: CategoryData[];
}

export interface SourceData {
  iconClass16px: string;
  name: string;
  sourceId: number;
  sourceTypeId: string;
  sourceTypeName: string;
  serviceProviders?: number[];
}

export enum ControlType {
  CHECKBOX = 1,
  DROPDOWN,
  TEXTAREA,
  TEXTBOX,
  TEXT,
  PASSWORD,
  MULTITEXT,
  MULTISELECT,
  TOGGLE,
  SWITCH,
  RADIO,
  PHONENUMBER,
  COUNTRYSTATE,
  ICONCHECKBOXES,
  IMAGE,
  HIDDEN,
  'TEXT-SEPARATOR',
  CHECKBOXES,
  VSOURCE,
  SEARCH,
  DATERANGEPICKER,
  TAG,
}

/**
 * Pipe to assert the control is a FormArray
 */
@Pipe({
  name: 'isFormArrayControl',
  standalone: false,
})
export class FormArrayTypePipe implements PipeTransform {
  transform(control: unknown): control is UntypedFormArray {
    const isFormArray = control instanceof UntypedFormArray;

    if (!isFormArray) {
      console.error('Error compiling form: Expected FormArray, got', control);
    }

    return isFormArray;
  }
}

/**
 * Pipe to assert the control is a FormControl
 */
@Pipe({
  name: 'isFormControl',
  standalone: false,
})
export class FormControlTypePipe implements PipeTransform {
  transform(control: unknown) {
    const isFormControl = control instanceof FormControl;

    if (!isFormControl) {
      console.error('Error compiling form: Expected FormControl, got', control);
    }

    return isFormControl;
  }
}

// Vapi/Vform responses come in this format.
export interface FormResult {
  // The specified version of the api, e.g. "1.0"
  version: string;
  // Data returned from the endpoint. Can be basically anything, but strings and form errors are common.
  data: any | string | FormErrorResponse;
  // Request id that was generated to serve this request. Can be used to find log messages.
  requestId: string;
  // How long it took the api to return.
  responseTime: number;
  // Status code of the response, e.g. 200, 400, 500, etc.
  statusCode: number;
}

export interface FieldError {
  // An error message about the field
  message: string;
  // The name of the field, e.g. "email"
  name: string;
}

export interface FormErrorResponse {
  // An overall message for the form's error state, e.g. "Failed to validate form."
  message: string;
  // An array of fields that have errors.
  fields: FieldError[];
}
