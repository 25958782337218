import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Observable, ReplaySubject, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith } from 'rxjs/operators';
import { FormatType, SectionTableItem, SectionTableItemExampleInterface } from '../section-table';
import { AbstractControl } from '@angular/forms';
import { Grade } from '@vendasta/snapshot';

interface RowData {
  label: string;
  yourBusiness: boolean | number | string;
  industryAverage: string | number;
  industryLeader?: number;
  competitors?: (number | boolean)[];
  format: FormatType;
  tooltip?: string;
  control?: AbstractControl;
  grade?: Grade;
  examples?: SectionTableItemExampleInterface[];
}

interface CompetitorColumn {
  name: string;
  id: string;
}

interface TableConfig {
  data: RowData[];
  columnNames: string[];
  competitorColumns: CompetitorColumn[];
  showIndustryAverage: boolean;
  showIndustryLeader: boolean;
  editMode: boolean;
  hideGrades: boolean;
  colorTextYourBusiness: boolean;
}

@Component({
  selector: 'snap-table',
  templateUrl: './snap-table.component.html',
  styleUrls: ['./snap-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SnapTableComponent implements OnInit {
  protected tableConfig$: Observable<TableConfig> = new Observable<TableConfig>();

  @Input() set items(val: SectionTableItem[]) {
    this.items$$.next(val);
  }
  private items$$: ReplaySubject<SectionTableItem[]> = new ReplaySubject<SectionTableItem[]>(1);
  private items$: Observable<SectionTableItem[]> = this.items$$.asObservable();
  @Input() set showIndustryAverage(val: boolean) {
    this.showIndustryAverage$$.next(val);
  }
  private showIndustryAverage$$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  protected showIndustryAverage$: Observable<boolean> = this.showIndustryAverage$$
    .asObservable()
    .pipe(startWith(false));

  @Input() set showIndustryLeader(val: boolean) {
    this.showIndustryLeader$$.next(val);
  }
  private showIndustryLeader$$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  protected showIndustryLeader$: Observable<boolean> = this.showIndustryLeader$$.asObservable().pipe(startWith(false));

  @Input() set editMode(val: boolean) {
    this.editMode$$.next(val);
  }
  private editMode$$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  protected editMode$: Observable<boolean> = this.editMode$$.asObservable().pipe(startWith(false));

  @Input() set linkCompetitors(val: boolean) {
    this.linkCompetitors$$.next(val);
  }
  private linkCompetitors$$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  protected linkCompetitors$: Observable<boolean> = this.linkCompetitors$$.asObservable().pipe(startWith(false));

  @Input() set hideGrades(val: boolean) {
    this.hideGrades$$.next(val);
  }
  private hideGrades$$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  protected hideGrades$: Observable<boolean> = this.hideGrades$$.asObservable().pipe(startWith(true));

  @Input() set colorTextYourBusiness(val: boolean) {
    this.colorTextYourBusiness$$.next(val);
  }
  private colorTextYourBusiness$$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  protected colorTextYourBusiness$: Observable<boolean> = this.colorTextYourBusiness$$
    .asObservable()
    .pipe(startWith(false));

  protected isUndefinedOrNull(valueToCheck: boolean | number | string) {
    return valueToCheck == null || valueToCheck == undefined;
  }

  protected getGradeClass(g: Grade): string {
    return Grade[g];
  }

  ngOnInit() {
    const competitorColumnInfo$ = this.items$.pipe(
      map((items: SectionTableItem[]) => {
        const competitorColumns: CompetitorColumn[] = [];
        const competitors = items?.[0]?.competitors || [];
        competitors.forEach((value, index) => {
          competitorColumns.push({ id: index.toString(), name: value.name });
        });
        return competitorColumns;
      }),
    );

    const displayColumns$ = combineLatest([
      this.showIndustryAverage$,
      this.showIndustryLeader$,
      competitorColumnInfo$,
      this.editMode$,
      this.hideGrades$,
    ]).pipe(
      map(([industryAverage, industryLeader, competitorColumns, editMode, hideGrades]) => {
        let rows = ['rowLabel', 'yourBusiness'];
        if (industryAverage) {
          rows = rows.concat(['industryAverage']);
        }
        if (industryLeader) {
          rows = rows.concat(['industryLeader']);
        }
        if (competitorColumns.length > 0) {
          rows = rows.concat(competitorColumns.map((value) => value.id));
        }
        if (editMode) {
          rows = rows.concat(['edit']);
        }
        if (!hideGrades) {
          rows = ['grade'].concat(rows);
        }
        return rows;
      }),
    );

    const displayRows$ = this.items$.pipe(
      map((items: SectionTableItem[]) => {
        const rowData: RowData[] = [];
        if (items) {
          items.forEach((item: SectionTableItem) => {
            const format = item.dataFormat || FormatType.Number;
            const competitorData = (item?.competitors || []).map((competitor) => competitor.value);
            const examples = (item?.data?.examples || []).map((example) => example as SectionTableItemExampleInterface);
            rowData.push({
              label: item.title,
              yourBusiness: item.data?.business,
              industryAverage: item.data?.industryAverage,
              industryLeader: item.data?.industryLeader,
              competitors: competitorData,
              format: format,
              tooltip: item.tooltip,
              control: item.control,
              grade: item.data?.grade,
              examples: examples,
            });
          });
        }
        return rowData;
      }),
    );

    this.tableConfig$ = combineLatest([
      competitorColumnInfo$,
      displayRows$,
      displayColumns$,
      this.showIndustryAverage$,
      this.showIndustryLeader$,
      this.editMode$,
      this.hideGrades$,
      this.colorTextYourBusiness$,
    ]).pipe(
      filter(([competitorInfo, rows, columns]) => !!competitorInfo && !!rows && !!columns),
      distinctUntilChanged(),
      map(([competitorInfo, rows, columns, industryAverage, industryLeader, editMode, hideGrades, colorText]) => {
        return {
          data: rows,
          columnNames: columns,
          competitorColumns: competitorInfo,
          showIndustryAverage: industryAverage,
          showIndustryLeader: industryLeader,
          editMode: editMode,
          hideGrades: hideGrades,
          colorTextYourBusiness: colorText,
        } as TableConfig;
      }),
    );
  }
}
