import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '@vendasta/galaxy/snackbar-service';
import {
  CORE_WEB_VITALS_CUMULATIVE_LAYOUT_SHIFT,
  CORE_WEB_VITALS_LARGEST_CONTENTFUL_PAINT,
  CORE_WEB_VITALS_MAX_POTENTIAL_FID,
  CORE_WEB_VITALS_SPEED_INDEX,
  Grade,
} from '@vendasta/snapshot';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseSectionComponent } from '../../section/base-section.component';
import { SectionServiceInterfaceToken } from '../../section/section.service';
import { SnapshotReportService } from '../../snapshot-report/snapshot-report.service';
import { Subsection } from '../../subsection/multi-subsection/multi-subsection';
import { Percentiles, SummaryThermometer } from '../../thermometers/summary-thermometer/summary-thermometer';
import { Thermometers } from '../performance-card/performance-card';
import { coreWebVitals, partitionAudits, realParseFloat } from '../website';
import { SectionVersions } from '../website-config';
import { Audit, WebsiteData } from '../website-data';
import { WebsiteService } from '../website.service';
import { PartitionedAudits } from './../website';

interface CoreWebVitals {
  thermometer: SummaryThermometer;
  percentiles: Percentiles;
}

@Component({
  selector: 'snap-website-performance-subsection',
  templateUrl: './website-performance.component.html',
  styleUrls: ['../website-section.component.scss'],
  animations: [
    trigger('expandRules', [
      state('on', style({ height: '*' })),
      state('off', style({ height: '0' })),
      transition('on <=> off', animate('500ms ease-in')),
    ]),
  ],
  providers: [
    {
      provide: SectionServiceInterfaceToken,
      useExisting: WebsiteService,
    },
  ],
  standalone: false,
})
export class WebsitePerformanceSubsectionComponent extends BaseSectionComponent implements OnInit {
  desktopInsightsAudits$: Observable<Audit[]>;
  mobileInsightsAudits$: Observable<Audit[]>;
  mobileInsightsPartitionedAudits$: Observable<PartitionedAudits>;
  mobileDesktopSubsections$: Observable<Subsection[]>;
  coreWebVitals$: Observable<Map<string, CoreWebVitals>>;
  hasMobileGrade$: Observable<boolean>;

  hasDesktopInsightsGrade$: Observable<boolean>;
  desktopInsightsPartitionedAudits$: Observable<PartitionedAudits>;

  showDesktop$: Observable<boolean>;
  showDesktopPreview$: Observable<boolean>;
  showMobile$: Observable<boolean>;
  showMobilePreview$: Observable<boolean>;

  showAllMobileSpeed = false;
  showAllDesktopSpeed = false;
  sectionVersions = SectionVersions;

  public SpeedIndex = {
    auditId: CORE_WEB_VITALS_SPEED_INDEX,
    learnMoreUrl: 'https://web.dev/speed-index/',
  };
  public LargestContentfulPaint = {
    auditId: CORE_WEB_VITALS_LARGEST_CONTENTFUL_PAINT,
    learnMoreUrl: 'https://web.dev/lighthouse-largest-contentful-paint/',
  };
  public MaxPotentialFirstInputDelay = {
    auditId: CORE_WEB_VITALS_MAX_POTENTIAL_FID,
    learnMoreUrl: 'https://web.dev/lighthouse-max-potential-fid/',
  };
  public CumulativeLayoutShift = {
    auditId: CORE_WEB_VITALS_CUMULATIVE_LAYOUT_SHIFT,
    learnMoreUrl: 'https://web.dev/cls/',
  };

  constructor(
    @Inject(SectionServiceInterfaceToken) public service: WebsiteService,
    public snapshotService: SnapshotReportService,
    public translate: TranslateService,
    public snackbar: SnackbarService,
  ) {
    super(service, snackbar, translate);

    this.mobileInsightsAudits$ = this.service.data$.pipe(
      map((data) => data.mobileInsights.audits.sort((s1, s2) => (s1.score > s2.score ? 1 : -1))),
    );

    this.mobileInsightsPartitionedAudits$ = this.mobileInsightsAudits$.pipe(map((audits) => partitionAudits(audits)));

    this.desktopInsightsAudits$ = this.service.data$.pipe(
      map((data) => data.desktopInsights.audits.sort((s1, s2) => (s1.score > s2.score ? 1 : -1))),
    );
    this.desktopInsightsPartitionedAudits$ = this.desktopInsightsAudits$.pipe(map((audits) => partitionAudits(audits)));

    this.coreWebVitals$ = this.service.data$.pipe(map((d) => this.buildCoreWebVitals(d)));

    this.hasMobileGrade$ = this.service.data$.pipe(map((d) => d.mobileGrade !== Grade.NO_GRADE));

    this.hasDesktopInsightsGrade$ = this.service.data$.pipe(map((d) => d.desktopInsights.grade !== Grade.NO_GRADE));

    this.mobileDesktopSubsections$ = this.service.data$.pipe(map((d) => this.buildMultiSubsection(d)));

    this.showMobile$ = this.service.config$.pipe(map((c) => !c.hideMobile));
    this.showMobilePreview$ = this.service.config$.pipe(map((c) => !(c.hideMobile || c.hideMobilePreview)));
    this.showDesktop$ = this.service.config$.pipe(map((c) => !c.hideDesktop));
    this.showDesktopPreview$ = this.service.config$.pipe(map((c) => !(c.hideDesktop || c.hideDesktopPreview)));
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  buildCoreWebVitals(data: WebsiteData): Map<string, CoreWebVitals> {
    const showMobile = !this.controls.controls['hideMobile'].value;
    const showDesktop = !this.controls.controls['hideDesktop'].value;

    const vitals = new Map<string, CoreWebVitals>();

    coreWebVitals.forEach((auditId) => {
      const mobileAudit = data.mobileInsights?.audits?.find((a) => a.id === auditId);
      const desktopAudit = data.desktopInsights?.audits?.find((a) => a.id === auditId);
      const isMobileAuditValid = this.isAuditValid(mobileAudit);
      const isDesktopAuditValid = this.isAuditValid(desktopAudit);

      const therm = Thermometers.get(auditId);

      if (isMobileAuditValid || isDesktopAuditValid) {
        vitals.set(auditId, <CoreWebVitals>{
          thermometer: {
            ...therm,
            markerTop:
              showMobile && isMobileAuditValid ? { label: 'Mobile', value: mobileAudit?.details[0]?.title } : null,
            markerBottom:
              showDesktop && isDesktopAuditValid ? { label: 'Desktop', value: desktopAudit?.details[0]?.title } : null,
          },
          percentiles: {
            top:
              showMobile && isMobileAuditValid
                ? this.getPercentile(
                    realParseFloat(mobileAudit?.details[0]?.title || '', data.mobileInsights.locale),
                    therm?.sections.limits.min ?? 0,
                    therm?.sections.limits.max ?? 0,
                  )
                : '',
            bottom:
              showDesktop && isDesktopAuditValid
                ? this.getPercentile(
                    realParseFloat(desktopAudit?.details[0]?.title || '', data.desktopInsights.locale),
                    therm?.sections.limits.min ?? 0,
                    therm?.sections.limits.max ?? 0,
                  )
                : '',
          },
        });
      }
    });

    return vitals;
  }

  isAuditValid(audit?: Audit): boolean {
    return audit?.details?.length > 0;
  }

  getPercentile(value: number, minValue: number, maxValue: number): string {
    if (value === null || value === undefined) {
      return '';
    }
    const percentile = ((value - minValue) / (maxValue - minValue)) * 100;
    return Math.round(this.clamp(percentile, 5, 95)).toString();
  }

  private clamp(value: number, min: number, max: number): number {
    // limits the value so that it will never fall outside of the min and max
    return value > max ? max : value < min ? min : value;
  }

  buildMultiSubsection(data: WebsiteData): Subsection[] {
    const mobile = {
      grade: data.mobileInsights.grade,
      heading: this.translate.instant('WEBSITE.MULTI_SUBSECTION.MOBILE_HEADING'),
      hideControl: this.controls.controls['hideMobile'],
    };
    const desktop = {
      grade: data.desktopInsights.grade,
      heading: this.translate.instant('WEBSITE.MULTI_SUBSECTION.DESKTOP_HEADING'),
      hideControl: this.controls.controls['hideDesktop'],
    };
    return [mobile, desktop];
  }

  hasValidWebsiteData(wd: WebsiteData): boolean {
    return (
      wd.homepageData?.grade !== Grade.NO_GRADE &&
      wd.desktopInsights?.grade !== Grade.NO_GRADE &&
      wd.mobileInsights?.grade !== Grade.NO_GRADE
    );
  }
}
