import { Component, Input, Inject } from '@angular/core';
import { KeywordMetrics } from '../seo-data';
import { SectionTableItem } from '../../section-table/section-table';
import { Media, MediaToken } from '../../providers/providers';

@Component({
  selector: 'snap-organic-keyword-ranking',
  templateUrl: './organic-keyword-ranking.component.html',
  styleUrls: ['./organic-keyword-ranking.component.scss'],
  standalone: false,
})
export class OrganicKeywordRankingComponent {
  @Input() organicKeywordMetricsData: KeywordMetrics;
  @Input() showMonthlyClicks: boolean;
  @Input() mobileRankingItems: SectionTableItem[];

  constructor(@Inject(MediaToken) public readonly media: Media) {}
}
