import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { SnapshotConfig, UserEvent } from '../snapshot-report/snapshot-report';
import { take } from 'rxjs/operators';
import { COMPETITOR_TYPE } from './competitor-type';
import { TranslateService } from '@ngx-translate/core';
import { SnapshotService } from '../snapshot.service';
import { Observable } from 'rxjs';
import { SnapshotReportService } from '../snapshot-report/snapshot-report.service';
import { ActivatedRoute } from '@angular/router';
import { CompetitionAnalysis } from '@vendasta/snapshot';
import { UntypedFormControl } from '@angular/forms';
import { SNAPSHOT_NAME_TOKEN } from '../providers/providers';
import { RefreshReportService } from '../refresh-report/refresh-report.service';

@Component({
  selector: 'snap-competitors',
  templateUrl: './competitors.component.html',
  styleUrls: ['./competitors.component.scss'],
  standalone: false,
})
export class CompetitorsComponent implements OnInit {
  readonly COMPETITOR_TYPE = COMPETITOR_TYPE;
  @Output() userEvent: EventEmitter<UserEvent> = new EventEmitter<UserEvent>();
  @Input() selectControl: UntypedFormControl;

  public competitorsAnalysisOptions: string[] = [
    'COMPETITORS.KEYWORD_COMPETITORS.RADIO_BUTTON',
    'COMPETITORS.DIRECT_COMPETITORS.RADIO_BUTTON',
    'COMPETITORS.INDUSTRY_AVERAGE_ONLY',
  ];

  public selectedCompetitorType: number;
  public previousSelectedCompetitorType = COMPETITOR_TYPE.INDUSTRY_AVERAGE_ONLY;
  public defaultCompetitorType = COMPETITOR_TYPE.KEYWORD_COMPETITORS;
  public isExpired$: Observable<boolean>;
  public allowEditCompetitors: boolean;

  constructor(
    @Inject(SNAPSHOT_NAME_TOKEN) readonly snapshotName$: Observable<string>,
    public snapshotService: SnapshotService,
    public snapshotReportService: SnapshotReportService,
    public translate: TranslateService,
    public refreshService: RefreshReportService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    // query param check to enable editing of competitors regardless of the snapshot being expired or not
    this.allowEditCompetitors = this.route.snapshot.queryParams['edit_competitors'] === 'true';

    this.isExpired$ = this.snapshotReportService.isNonPartnerConfigExpired$;

    this.snapshotService.snapshotConfig$.pipe(take(1)).subscribe((c: SnapshotConfig) => {
      this.selectedCompetitorType = this.setSelectedCompetitorType(c);
    });
  }

  handleUserEventEmitted(data: UserEvent): void {
    this.userEvent.emit(data);
  }

  onCompetitiveAnalysisChange(i: number): void {
    const m: Map<number, number> = new Map([
      [COMPETITOR_TYPE.KEYWORD_COMPETITORS, CompetitionAnalysis.COMPETITOR_DATA],
      [COMPETITOR_TYPE.DIRECT_COMPETITORS, CompetitionAnalysis.DIRECT_COMPETITOR_DATA],
      [COMPETITOR_TYPE.INDUSTRY_AVERAGE_ONLY, CompetitionAnalysis.INDUSTRY_DATA],
    ]);
    let value = m.get(i);
    if (value === undefined) {
      value = CompetitionAnalysis.COMPETITOR_DATA;
    }

    this.selectControl.setValue(value);
  }

  setSelectedCompetitorType(c: SnapshotConfig): CompetitionAnalysis {
    // TODO: remove the check that there's a snapshotID on the  first competitor once migration completed SNAP-1335,
    // gscoular 20201213
    if (
      (c.competitionAnalysis === CompetitionAnalysis.COMPETITOR_DATA ||
        c.competitionAnalysis === CompetitionAnalysis.UNSET) &&
      c.competitors[0] &&
      c.competitors[0].snapshotId
    ) {
      return COMPETITOR_TYPE.DIRECT_COMPETITORS;
    }
    if (c.competitionAnalysis === CompetitionAnalysis.INDUSTRY_DATA) {
      return COMPETITOR_TYPE.INDUSTRY_AVERAGE_ONLY;
    }
    if (c.competitionAnalysis === CompetitionAnalysis.DIRECT_COMPETITOR_DATA) {
      return COMPETITOR_TYPE.DIRECT_COMPETITORS;
    }
    return this.defaultCompetitorType;
  }

  openRefreshDialog(): void {
    this.refreshService.openRefreshDialog();
  }
}
