import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { GalaxyCoreInputDirective } from '../core/core-input.directive';
import { GalaxyInputInterface, InputType } from '../input.interface';

/** @deprecated - use Galaxy Field with matInput */
@Component({
  selector: 'glxy-input',
  templateUrl: './input.component.html',
  styleUrls: ['./../core/core.material-override.scss', './input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
  ],
  standalone: false,
})
export class InputComponent extends GalaxyCoreInputDirective<string> implements OnInit {
  @HostBinding('class') class = 'glxy-input';

  /** Material Icon name to display inside of the field */
  @Input() trailingIcon?: string;
  /** Whether the material icon should be clickable */
  @Input({ transform: booleanAttribute }) iconClickable?: boolean;
  /** Additional message to display below the text box */
  @Input() hint?: string;

  @Input() config?: GalaxyInputInterface;
  /** Type of input allowed for the input element */
  @Input() type?: InputType = InputType.Text;

  /** Emit an event when the icon is clicked */
  @Output() iconClicked: EventEmitter<void> = new EventEmitter();

  ngOnInit(): void {
    this.setupControl();
  }

  setupControl(): void {
    super.setupControl();
  }

  /**
   * If the icon is clickable, emit an event an stop the propogation to the input field
   * @param event - Click Event
   */
  emitIconClick(event: MouseEvent): void {
    if (this.iconClickable) {
      event.stopPropagation();
      this.iconClicked.emit();
    }
  }
}
