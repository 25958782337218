import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackbarService } from '@vendasta/galaxy/snackbar-service';
import { ListingListingStatus } from '@vendasta/snapshot';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subscription,
  combineLatest as observableCombineLatest,
} from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { LiteQuestionDialogComponent } from '../lite-question-dialog/lite-question-dialog.component';
import { ROUTE_GENERATOR_TOKEN, RouteType, RoutingServiceInterface } from '../providers/providers';
import { WhitelabelService } from '../whitelabel/whitelabel.service';
import { OverrideBusinessDataDialogComponent } from './override-business-data-dialog/override-business-data-dialog.component';
import { SnapshotLiteService } from './snapshot-lite.service';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare let window: any;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare let deployment: any;

@Component({
  selector: 'snap-snapshot-lite',
  templateUrl: './snapshot-lite.component.html',
  styleUrls: ['./snapshot-lite.component.scss'],
  standalone: false,
})
export class SnapshotLiteComponent implements OnInit, OnDestroy {
  windowHeight: BehaviorSubject<number> = new BehaviorSubject<number>(800);
  private businessId$$: ReplaySubject<string> = new ReplaySubject<string>(1);
  businessId$: Observable<string> = this.businessId$$.asObservable();

  accurateCount$: Observable<number>;
  sourceCount$: Observable<number>;
  scanScore$: Observable<number>;
  subscriptions: Subscription[] = [];
  loaded$: Observable<boolean>;

  constructor(
    private _route: ActivatedRoute,
    private router: Router,
    public snapshotLiteService: SnapshotLiteService,
    private _titleService: Title,
    public whitelabel: WhitelabelService,
    private _dialog: MatDialog,
    private readonly snackbarService: SnackbarService,
    @Inject(DOCUMENT) private _document: HTMLDocument,
    @Inject(ROUTE_GENERATOR_TOKEN) private routeService: RoutingServiceInterface,
  ) {
    this.accurateCount$ = this.snapshotLiteService.listingScan$.pipe(
      filter((x) => x !== undefined),
      map((scan) => scan.listings.filter((l) => l.status === ListingListingStatus.ACCURATE).length),
    );

    this.sourceCount$ = this.snapshotLiteService.listingScan$.pipe(
      filter((x) => x !== undefined),
      map((scan) => scan.listings.length),
    );

    this.scanScore$ = observableCombineLatest([this.accurateCount$, this.sourceCount$]).pipe(
      map(([accurate, found]) => {
        if (found === 0) {
          return 0;
        }
        return Math.round((accurate / found) * 100);
      }),
    );

    this.loaded$ = observableCombineLatest([
      this.snapshotLiteService.business$,
      this.snapshotLiteService.salesperson$,
      this.snapshotLiteService.listingScan$,
    ]).pipe(
      map(([b, s, l]) => {
        if (b === undefined || s === undefined || l === undefined) {
          return false;
        }
        return true;
      }),
    );
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this._route.params.subscribe((p) => {
        if ('businessId' in p) {
          this.businessId$$.next(p['businessId']);
        }
      }),
    );

    // subscribe to snapshotLite data to set the page title
    this.subscriptions.push(
      this.snapshotLiteService.business$.pipe(filter((x) => x !== undefined)).subscribe((nd) => {
        this._titleService.setTitle(nd.companyName);
      }),
    );

    // subscribe to whitelabel data to set the favicon
    this.subscriptions.push(
      this.whitelabel.faviconUrl$.subscribe((url) => {
        const faviconElement = this._document.getElementById('appFavicon');
        if (faviconElement) {
          faviconElement.setAttribute('href', url);
        }
      }),
    );

    this.businessId$.subscribe((bid) =>
      this.snapshotLiteService.init(bid, <HTMLDivElement>this._document.getElementById('google-attribution')),
    );

    this.businessId$.pipe(switchMap((bid) => this.snapshotLiteService.startFetching(bid))).subscribe({
      error: (e: HttpErrorResponse) => {
        if (e.status === 400) {
          this.snackbarService.openErrorSnack('ERRORS.MISSING_SCAN_DATA', { duration: 3000 });
          this.openEditDialog();
          return;
        }
        let error = 'ERRORS.ERROR_LOADING_PAGE';
        if (e.status === 429) {
          error = 'ERRORS.OVER_QUOTA';
        }

        const route = this.routeService.getRoute({ routeType: RouteType.Error });
        if (route) {
          this.router.navigate([route], { skipLocationChange: true, queryParams: { error: error } });
        }
      },
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.windowHeight.next(event.target.innerWidth);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  openAskQuestionDialog(location: string): void {
    this._dialog.open(LiteQuestionDialogComponent, {
      data: {
        accountGroupId: this.snapshotLiteService.businessId,
      },
    });
  }

  openEditDialog(): void {
    this.snapshotLiteService.business$.pipe(take(1)).subscribe((business) => {
      const ref = this._dialog.open(OverrideBusinessDataDialogComponent, {
        data: {
          businessId: this.snapshotLiteService.businessId,
          business: business,
        },
        minWidth: 400,
      });

      ref
        .afterClosed()
        .pipe(take(1))
        .subscribe((result) => {
          if (result) {
            this.snapshotLiteService.refresh();
          }
        });
    });
  }
}
