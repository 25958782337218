import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'httpCondense',
  standalone: false,
})
export class HttpCondensePipe implements PipeTransform {
  transform(url: string): string {
    const match = url.match(/^(?:https?:\/\/)?(?:[^@/\n]+@)?(?:www\.)?([^:/?\n]+)/);
    return match ? match[1] : url;
  }
}
