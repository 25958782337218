import { Pipe, PipeTransform } from '@angular/core';
import { CountryCode, formatNumber } from 'libphonenumber-js';

export function formatPhoneNumber(
  phoneNumber: string | number,
  formatForInternational: boolean,
  countryCode?: string,
): string {
  const numberString = phoneNumber.toString();
  if (numberString === '') {
    return '';
  }
  const formatting = formatForInternational ? 'INTERNATIONAL' : 'NATIONAL';
  if (countryCode as CountryCode) {
    const newCountryCode = <CountryCode>countryCode;
    try {
      return formatNumber(numberString, newCountryCode, formatting);
    } catch (e) {
      return numberString;
    }
  }
  return numberString;
}

@Pipe({
  name: 'phone',
  standalone: false,
})
export class PhoneNumberFormatPipe implements PipeTransform {
  transform(phoneNumber: string | number, countryCode?: string): string {
    return formatPhoneNumber(phoneNumber, false, countryCode);
  }
}

@Pipe({
  name: 'phone_intl',
  standalone: false,
})
export class InternationalPhoneNumberFormatPipe implements PipeTransform {
  transform(phoneNumber: string | number, countryCode?: string): string {
    return formatPhoneNumber(phoneNumber, true, countryCode);
  }
}
