import { Component, Inject, Input, OnChanges } from '@angular/core';
import { FormatType, SectionTableItem } from '../../section-table/section-table';
import { Media, MediaToken } from '../../providers/providers';
import { DomainMetrics } from '../advertising-data';

@Component({
  selector: 'snap-campaign-performance',
  templateUrl: 'campaign-performance.component.html',
  styleUrls: ['../general.scss'],
  standalone: false,
})
export class CampaignPerformanceComponent implements OnChanges {
  @Input() businessPPCDomainMetrics: DomainMetrics = null;
  @Input() competitorsPPCDomainMetrics: DomainMetrics[] = [];
  @Input() edit = false;

  competitorItems: SectionTableItem[] = [];

  showDomainMetrics = true;

  constructor(@Inject(MediaToken) public readonly media: Media) {}

  ngOnChanges(): void {
    if (!this.businessPPCDomainMetrics) {
      return;
    }
    this.showDomainMetrics = !this.hasDefaultDomainMetrics(this.businessPPCDomainMetrics);

    this.competitorItems = [];
    this.competitorItems.push({
      title: 'ADVERTISING.PAY_PER_CLICK.PAID_KEYWORDS',
      tooltip: 'ADVERTISING.PAY_PER_CLICK.TOOLTIPS.KEYWORDS',
      labelFormat: FormatType.Link,
      data: {
        link: this.businessPPCDomainMetrics.domainName,
        business: this.businessPPCDomainMetrics.numberOfPaidKeywords,
      },
      competitors: this.competitorsPPCDomainMetrics.map((c) => {
        return {
          name: c.domainName,
          value: this.hasDefaultDomainMetrics(c) ? null : c.numberOfPaidKeywords,
        };
      }),
    } as SectionTableItem);

    this.competitorItems.push({
      title: 'ADVERTISING.PAY_PER_CLICK.PAID_CLICKS',
      tooltip: 'ADVERTISING.PAY_PER_CLICK.TOOLTIPS.MONTHLY_PAID_CLICKS',
      labelFormat: FormatType.Link,
      data: {
        link: this.businessPPCDomainMetrics.domainName,
        business: this.businessPPCDomainMetrics.paidClicksPerMonth,
      },
      competitors: this.competitorsPPCDomainMetrics.map((c) => {
        return {
          name: c.domainName,
          value: this.hasDefaultDomainMetrics(c) ? null : c.paidClicksPerMonth,
        };
      }),
    } as SectionTableItem);
    this.competitorItems.push({
      title: 'ADVERTISING.PAY_PER_CLICK.PAID_COST',
      tooltip: 'ADVERTISING.PAY_PER_CLICK.TOOLTIPS.MONTHLY_BUDGET',
      dataFormat: FormatType.Currency,
      labelFormat: FormatType.Link,
      data: {
        link: this.businessPPCDomainMetrics.domainName,
        business: this.businessPPCDomainMetrics.monthlyAdwordsBudget,
      },
      competitors: this.competitorsPPCDomainMetrics.map((c) => {
        return {
          name: c.domainName,
          value: this.hasDefaultDomainMetrics(c) ? null : c.monthlyAdwordsBudget,
        };
      }),
    } as SectionTableItem);
  }

  hasDefaultDomainMetrics(ppcDomainMetrics: DomainMetrics): boolean {
    return (
      ppcDomainMetrics.numberOfPaidKeywords === 0 &&
      ppcDomainMetrics.paidClicksPerMonth === 0 &&
      ppcDomainMetrics.monthlyAdwordsBudget === 0
    );
  }
}
