import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { combineLatest, Observable } from 'rxjs';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { take, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '@vendasta/galaxy/snackbar-service';
import { ScheduleMeetingService } from '../schedule-meeting/schedule-meeting.service';
import { SalesPersonService } from '../../services';
import { ClaimAccountService, SnapshotReportService, WhitelabelService } from '@galaxy/snapshot';

@Component({
  selector: 'app-ask-question-dialog',
  templateUrl: './ask-question-dialog.component.html',
  styleUrls: ['./ask-question-dialog.component.scss'],
  standalone: false,
})
export class AskQuestionDialogComponent implements OnInit {
  accountGroupId: string;
  contactForm: UntypedFormGroup;
  showScheduleMeetingButton$: Observable<boolean>;
  phoneNumber$: Observable<string | undefined>;
  bookMeetingUrl$: Observable<string>;
  showMessageForm = false;

  maxLengthValidators = [
    {
      validatorFn: Validators.maxLength(500),
      errorMessage: this.translateService.instant('ERRORS.MESSAGE_TOO_LONG'),
    },
  ];

  constructor(
    public readonly scheduleMeetingService: ScheduleMeetingService,
    private claimAccountService: ClaimAccountService,
    public readonly salesPersonService: SalesPersonService,
    public whitelabel: WhitelabelService,
    private snackbarService: SnackbarService,
    private translateService: TranslateService,
    public snapshotService: SnapshotReportService,
    public dialogRef: MatDialogRef<AskQuestionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.accountGroupId = data.accountGroupId;
  }

  ngOnInit(): void {
    this.showScheduleMeetingButton$ = combineLatest([
      this.snapshotService.hideScheduleMeetingOption$,
      this.scheduleMeetingService.isHostConfigured$,
    ]).pipe(map(([hideOption, isConfigured]) => !hideOption && isConfigured));
    this.bookMeetingUrl$ = this.scheduleMeetingService.meetingTypeList$.pipe(
      take(1),
      map((meetings) => (meetings[0]?.bookingUrl ? meetings[0]?.bookingUrl : '')),
    );

    this.contactForm = new UntypedFormGroup({
      firstName: new UntypedFormControl(),
      lastName: new UntypedFormControl(),
      email: new UntypedFormControl(),
      message: new UntypedFormControl(),
    });

    this.phoneNumber$ = this.salesPersonService.salesPerson$.pipe(
      take(1),
      map((sp: any) => {
        const parts = sp.phoneNumbers[0].split(',');
        return parts[0];
      }),
    );
  }

  close(): void {
    this.dialogRef.close();
  }

  submit(): void {
    const config = {
      errorMessage: this.translateService.instant('ERRORS.CONTACT_SALES_REP'),
      successMessage: this.translateService.instant('CONTACT_US.MESSAGE_SUCCESS'),
      duration: 3000,
    };

    if (this.contactForm.valid) {
      this.claimAccountService
        .askQuestion(
          this.contactForm.controls.email.value,
          this.accountGroupId,
          this.contactForm.controls.firstName.value,
          this.contactForm.controls.lastName.value,
          this.contactForm.controls.message.value,
          '',
        )
        .subscribe(
          () => {
            this.close();
            this.snackbarService.openSuccessSnack(config.successMessage, {
              duration: config.duration,
            });
          },
          () => {
            this.snackbarService.openErrorSnack(config.errorMessage, {
              duration: config.duration,
            });
          },
        );
    }
  }

  openSendMessage(): void {
    this.showMessageForm = true;
  }

  get firstName(): UntypedFormControl {
    return <UntypedFormControl>this.contactForm.get('firstName');
  }
  get lastName(): UntypedFormControl {
    return <UntypedFormControl>this.contactForm.get('lastName');
  }
  get email(): UntypedFormControl {
    return <UntypedFormControl>this.contactForm.get('email');
  }
  get message(): UntypedFormControl {
    return <UntypedFormControl>this.contactForm.get('message');
  }
}
