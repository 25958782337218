import { map, take, withLatestFrom } from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import { SnackbarService } from '@vendasta/galaxy/snackbar-service';
import { SnapshotReportService, UserEvent } from '@galaxy/snapshot';
import { FeatureFlagService, MediaQueryService } from '../../services';
import { MarketplaceService } from '../../services/marketplace.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ToolbarComponent {
  @Input() edit = false;
  @Input() logoUrl = '';
  @Input() reportName = '';
  @Input() ctaLabel?: string;
  @Input() ctaTargetUrl?: string;
  @Input() ctaTargetProduct?: string;
  @Output() askQuestionClick = new EventEmitter<string>();
  @Output() toggleSidenavClick = new EventEmitter<void>();
  @Output() trackUserEvents = new EventEmitter<UserEvent>();
  @Output() shareReportClick = new EventEmitter<void>();

  readonly generatingPdf$: Observable<boolean>;
  readonly printReportFeature$ = this.featureFlagService.activeFeatures$.pipe(map((ff) => ff.printReport));
  protected readonly defaultCtaLabel = 'COMMON.GET_IN_TOUCH';

  constructor(
    private readonly snackbarService: SnackbarService,
    private readonly snapshotService: SnapshotReportService,
    private readonly media: MediaQueryService,
    private readonly featureFlagService: FeatureFlagService,
    private readonly marketplaceService: MarketplaceService,
  ) {
    this.generatingPdf$ = this.snapshotService.generatePdfWorker.isLoading$;
  }

  async handleContactClick(): Promise<void> {
    if (this.ctaTargetUrl) {
      window.open(this.ctaTargetUrl, '_blank', 'noopener');
      return;
    } else if (this.ctaTargetProduct) {
      const result = await this.validatePackageAndOpenPublicStore(this.ctaTargetProduct);
      if (result) {
        return;
      }
    }
    this.askQuestionClick.emit('mobile-header');
  }

  handleDownloadClick(): void {
    this.trackUserEvents.emit({ category: 'cta', action: 'click', label: 'download-report' });
    this.downloadReport();
  }

  handlePrintClick(): void {
    this.trackUserEvents.emit({ category: 'cta', action: 'click', label: 'print-snapshot' });
    this.printReport();
  }

  handleToggleSidenavClick(): void {
    this.toggleSidenavClick.emit();
  }

  handleShareReportClick(): void {
    this.shareReportClick.emit();
  }

  private downloadReport(): void {
    this.snapshotService
      .generatePdf()
      .pipe(take(1), withLatestFrom(this.snapshotService.snapshotData$))
      .subscribe({
        next: ([pdf, snapshotData]) => this.downloadPdf(pdf, this.reportName, snapshotData.companyName),
        error: () => this.snackbarService.openErrorSnack('ERRORS.ERROR_GENERATING_PDF'),
      });
  }

  private printReport(): void {
    this.media.simulatePrintMedia(true);
    window.print();
    this.media.simulatePrintMedia(false);
  }

  private downloadPdf(base64String: string, whitelabelName: string, businessName: string): void {
    const source = `data:application/pdf;base64,${base64String}`;
    const link = document.createElement('a');
    link.href = source;
    const fileName = this.removeIllegalFilenameCharacters(`${businessName} - ${whitelabelName}.pdf`);
    link.download = fileName;
    link.click();
  }

  private removeIllegalFilenameCharacters(filename: string): string {
    return filename.replace(/[\\/:*?"<>|#{}]/g, '');
  }

  private async validatePackageAndOpenPublicStore(productId: string): Promise<boolean> {
    const packages = await firstValueFrom(this.marketplaceService.activatablePackages$);
    if (packages.find((s) => s.packageId === productId)) {
      const url = await firstValueFrom(this.marketplaceService.packageUrl$(productId));
      window.open(url);
      return true;
    }
    return false;
  }
}
