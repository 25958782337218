import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'atlas-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  standalone: false,
})
export class ButtonComponent {
  @Input() loading: boolean;
  @Input() disabled: boolean;
  @Input() raised: boolean;
  @Input() color: 'primary' | 'secondary' = 'secondary';

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onClick: EventEmitter<void> = new EventEmitter<void>();

  onButtonClick(event: Event): void {
    this.onClick.emit();
    event.stopPropagation();
    event.stopImmediatePropagation();
  }
}
