import { Directive } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  NG_VALIDATORS,
  UntypedFormControl,
  ValidationErrors,
  ValidatorFn,
  Validator as ValidatorType,
} from '@angular/forms';

const PRICE_REGEX = /^\d{0,8}(\.\d{1,2})?$/;
const URL_REGEX = /^((https?:\/\/)?([\w-]+(\.[\w-]+)+|localhost)\.?(:\d+)?((\/)?\S*)?)$/i;
const SUBDOMAIN_REGEX = /(^[a-z0-9][a-z0-9-]{0,62}[a-z0-9]$)/;
const MARKET_ID_REGEX = /(^[a-z0-9-]*$)/;
const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// Following regex matches North American, South African, UK, and Australian phone numbers, with some support for extensions
const PHONE_NUMBER_REGEX =
  /(^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$)|(^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?#(\d{4}|\d{3}))?$)|(^(\+\d{2}[ -]{0,1}){0,1}(((\({0,1}[ -]{0,1})0{0,1}\){0,1}[2|3|7|8]{1}\){0,1}[ -]*(\d{4}[ -]{0,1}\d{4}))|(1[ -]{0,1}(300|800|900|902)[ -]{0,1}((\d{6})|(\d{3}[ -]{0,1}\d{3})))|(13[ -]{0,1}([\d -]{5})|((\({0,1}[ -]{0,1})0{0,1}\){0,1}4{1}[\d -]{8,10})))$)|((\(0\d\d\)\s\d{3}[\s-]+\d{4})|(0\d\d[\s-]+\d{3}[\s-]+\d{4})|(0\d{9})|(\+\d\d\s?[(\s]\d\d[)\s]\s?\d{3}[\s-]?\d{4}))/;

interface PostalCodeRegexMap {
  [key: string]: RegExp;
}

const DYNAMIC_CONTENT_ERROR = { invalidDynamicComponent: true };

const POSTAL_CODE_REGEXES: PostalCodeRegexMap = {
  CA: /^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][\s-]?\d[ABCEGHJ-NPRSTV-Z]\d$/,
  US: /^[0-9]{5}(?:-[0-9]{4})?$/,
};

/**
 * import from https://github.com/ReactiveX/rxjs/blob/5.5.8/src/util/isNumeric.ts
 */
const isArray = Array.isArray || (<T>(x: any): x is T[] => x && typeof x.length === 'number');

function isNumeric(val: any): val is number {
  return !isArray(val) && val - parseFloat(val) + 1 >= 0;
}

export class Validator {
  static URL_MAILTO_OR_DYNAMIC_CONTENT_REGEX = /(\{\{.*?\}\})|(\b((?:https?:\/\/|mailto:)[^\s]+)\b)/i;
  private static DYNAMIC_CONTENT_REGEX = /^\{\{\s*([a-zA-Z]+)(?:\s*\['([^']+)'(?:\s*,\s*'([^']+)')*\])?\s*}}$/i;
  static HEX_COLOR_REGEX = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;

  static isPrice(control: UntypedFormControl): any {
    if (control.value && typeof control.value === 'string' && control.value.match(PRICE_REGEX)) {
      return null;
    } else if (
      control.value &&
      typeof control.value === 'number' &&
      parseFloat((control.value * 100).toFixed(2)) % 1 === 0
    ) {
      // modulus with floating point bases is broken
      return null;
    } else if (!control.value) {
      return null;
    } else {
      return { invalidPrice: true };
    }
  }

  static isUrl(control: UntypedFormControl): any {
    if (control.value && control.value.match(URL_REGEX)) {
      return null;
    } else if (control.value) {
      return { invalidUrl: true };
    }
  }

  static isEmailList(control: FormControl<string>): any {
    if (control.value) {
      const emails = control.value
        .toString()
        .split(/[,;]+/)
        .map((email) => email.trim());
      const invalidEmails = emails.filter((email) => !email.match(EMAIL_REGEX));
      if (invalidEmails.length) {
        return { invalidEmail: true };
      }
    }
  }

  static isEmail(control: AbstractControl): ValidationErrors | null {
    if (control.value && typeof control.value == 'string' && control.value.match(EMAIL_REGEX)) {
      return null;
    } else if (control.value) {
      return { invalidEmail: true };
    }
    return null;
  }

  static isPhoneNumber(control: UntypedFormControl): any {
    if (control.value && control.value.match(PHONE_NUMBER_REGEX)) {
      return null;
    } else if (control.value) {
      return { invalidPhoneNumber: true };
    }
  }

  // Validation is currently limited to US and Canada. Add more countries to regex map as needed.
  static isValidPostalCode(countryCode: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      const countryRegex = POSTAL_CODE_REGEXES[countryCode.toUpperCase()];
      // If the country code is not in our regex map, return valid
      if (!countryRegex) {
        return null;
      }
      // Validate the postal code against the regex for the given country
      if (value && !countryRegex.test(value)) {
        return { invalidPostalCode: true };
      }
      return null;
    };
  }

  static isInRange(lower: number, upper: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!isNumeric(value) || (lower !== null && Number(value) < lower) || (upper !== null && Number(value) > upper)) {
        return { outOfRange: true };
      }
      return null;
    };
  }

  static isValidMarketId(control: UntypedFormControl): ValidationErrors | null {
    if (control.value && control.value.match(MARKET_ID_REGEX)) {
      return null;
    } else if (control.value) {
      return { invalidMarketId: true };
    }
    return null;
  }

  static isMinimumLength(minimumLength: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value && control.value.length >= minimumLength) {
        return null;
      } else if (control.value) {
        return { minlength: true };
      }
      return null;
    };
  }

  static isValidSubdomain(control: UntypedFormControl): any {
    if (control.value && control.value.match(SUBDOMAIN_REGEX)) {
      return null;
    } else if (control.value) {
      return { invalidSubdomain: true };
    }
  }

  static containsValidDynamicContent(control: FormControl<string>): ValidationErrors | null {
    if (!control.value) return null; // No dynamic content to validate

    let dynamicContent = '';
    for (const p of control.value) {
      if (dynamicContent === '' || dynamicContent === '{') {
        if (p === '{') {
          dynamicContent += p;
        } else {
          dynamicContent = '';
        }
      } else if (p === '}' && dynamicContent[dynamicContent.length - 1] === '}') {
        dynamicContent += p;
        if (dynamicContent.search(Validator.DYNAMIC_CONTENT_REGEX) === -1) {
          return DYNAMIC_CONTENT_ERROR;
        }
        dynamicContent = '';
      } else {
        dynamicContent += p;
      }
    }
    if (dynamicContent !== '') {
      return DYNAMIC_CONTENT_ERROR;
    }
    return null;
  }

  static errorMessage(validatorName: string, validatorValue?: any): string {
    const config: Record<string, string> = {
      required: 'Required',
      minlength: `Minimum length ${validatorValue.requiredLength}`,
      invalidPrice: `Invalid price`,
      outOfRange: `Out of range`,
      invalidEmail: 'Invalid Email',
      invalidPhoneNumber: 'Invalid Phone Number',
      invalidZipCode: 'Invalid Zip/Postal Code',
      invalidSubdomain: 'Invalid Subdomain',
    };

    return config[validatorName];
  }

  static isGreaterThanZero = () => {
    return (control: UntypedFormControl) => {
      if (control.value <= 0) {
        return { isLessThanOrEqualToZero: true };
      }
      return null;
    };
  };

  static isValidDate(control: UntypedFormControl): any {
    if (!control.value || isNaN(Date.parse(control.value))) {
      return { invalidDate: true };
    }
    return null;
  }
}

@Directive({
  selector: '[sharedContainsValidDynamicContent]',
  providers: [{ provide: NG_VALIDATORS, useExisting: ContainsValidDynamicContentDirective, multi: true }],
  standalone: false,
})
export class ContainsValidDynamicContentDirective implements ValidatorType {
  validate(control: AbstractControl): ValidationErrors | null {
    return Validator.containsValidDynamicContent(control as UntypedFormControl);
  }
}
