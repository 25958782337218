import { Pipe, PipeTransform } from '@angular/core';
import { ordinal } from '@vendasta/galaxy/utility/ordinal-utils';

@Pipe({
  name: 'glxyOrdinal',
  standalone: false,
})
export class GalaxyOrdinalPipe implements PipeTransform {
  transform(value: string | number, excludeValueInResponse?: boolean): string {
    return excludeValueInResponse ? ordinal(value) : `${value}${ordinal(value)}`;
  }
}
