import { Component, Inject, OnInit, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { SectionServiceInterfaceToken } from '../../section/section.service';
import { map } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import { EcommerceService } from '../ecommerce.service';
import { TranslateService } from '@ngx-translate/core';
import { BaseSectionComponent } from '../../section/base-section.component';
import { Application } from '../ecommerce.application';
import { SnapshotService } from '../../snapshot.service';
import { FormatType, SectionTableItem } from '../../section-table/section-table';
import { CompetitionAnalysis } from '@vendasta/snapshot';
import { SnackbarService } from '@vendasta/galaxy/snackbar-service';
import { EcommerceData, APPLICATIONS_AVAILABILITY } from '../ecommerce.data';
import { Media, MediaToken } from '../../providers/providers';

export interface EcommerceSectionItems {
  attribute?: string;
  numberOfSolutions?: number;
  name?: string;
  version?: string;
  recommendation?: string;
  control?: AbstractControl;
  available: boolean;
  datatestid?: string;
}

@Component({
  selector: 'snap-ecommerce-subsection',
  templateUrl: './ecommerce-subsection.component.html',
  styleUrls: ['../ecommerce-section.component.scss'],
  providers: [
    {
      provide: SectionServiceInterfaceToken,
      useExisting: EcommerceService,
    },
  ],
  standalone: false,
})
export class EcommerceSubsectionComponent extends BaseSectionComponent implements OnInit {
  @Input() isPartnerConfig: boolean;

  noCompetitorsItems$: Observable<EcommerceSectionItems[]> = new Observable<EcommerceSectionItems[]>();
  hasCompetitorsItems$: Observable<SectionTableItem[]> = new Observable<SectionTableItem[]>();
  hasWebsite$: Observable<boolean> = new Observable<boolean>();

  constructor(
    @Inject(SectionServiceInterfaceToken) public service: EcommerceService,
    public translate: TranslateService,
    public snapshotService: SnapshotService,
    @Inject(MediaToken) public readonly media: Media,
    public snackbarService: SnackbarService,
  ) {
    super(service, snackbarService, translate);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.noCompetitorsItems$ = this.service.data$.pipe(
      map((e) => {
        const dataItems: EcommerceSectionItems[] = [];

        const ecomerceSolution: Application = this.getNameAndVersion(e.ecommerceSolution);
        const onlinePayment: Application = this.getNameAndVersion(e.onlinePayments);
        const adRetargeting: Application = this.getNameAndVersion(e.adRetargeting);
        const appointmentScheduling: Application = this.getNameAndVersion(e.appointmentScheduling);
        const available = e.applicationsAvailability;

        dataItems.push(
          {
            attribute: this.translate.instant('ECOMMERCE.ECOMMERCE_SOLUTION'),
            numberOfSolutions: this.getNumberOfSolutions(e.ecommerceSolution),
            name: ecomerceSolution.name,
            version: ecomerceSolution.version,
            recommendation: this.translate.instant('ECOMMERCE.ECOMMERCE_SOLUTION_RECOMMENDATION'),
            control: this.controls.controls['hideEcommerceSolution'],
            available: available?.[APPLICATIONS_AVAILABILITY.ecommerceSolution],
          },
          {
            attribute: this.translate.instant('ECOMMERCE.ONLINE_PAYMENTS'),
            numberOfSolutions: this.getNumberOfSolutions(e.onlinePayments),
            name: onlinePayment.name,
            version: onlinePayment.version,
            recommendation: this.translate.instant('ECOMMERCE.ONLINE_PAYMENTS_RECOMMENDATION'),
            control: this.controls.controls['hideOnlinePayments'],
            available: available?.[APPLICATIONS_AVAILABILITY.onlinePayments],
          },
          {
            attribute: this.translate.instant('ECOMMERCE.AD_RETARGETING'),
            numberOfSolutions: this.getNumberOfSolutions(e.adRetargeting),
            name: adRetargeting.name,
            version: adRetargeting.version,
            recommendation: this.translate.instant('ECOMMERCE.AD_RETARGETING_RECOMMENDATION'),
            control: this.controls.controls['hideAdRetargeting'],
            available: available?.[APPLICATIONS_AVAILABILITY.adRetargeting],
          },
          {
            attribute: this.translate.instant('ECOMMERCE.APPOINTMENT_SCHEDULING'),
            numberOfSolutions: this.getNumberOfSolutions(e.appointmentScheduling),
            name: appointmentScheduling.name,
            version: appointmentScheduling.version,
            recommendation: this.translate.instant('ECOMMERCE.APPOINTMENT_SCHEDULING_RECOMMENDATION'),
            control: this.controls.controls['hideAppointmentScheduling'],
            available: available?.[APPLICATIONS_AVAILABILITY.appointmentScheduling],
            datatestid: 'appointment-scheduling',
          },
        );
        return dataItems.filter((item) => item.available || this.isPartnerConfig);
      }),
    );

    this.hasCompetitorsItems$ = combineLatest([this.service.data$, this.snapshotService.competitionAnalysis$]).pipe(
      map(([e, ca]) => {
        const dataItems: SectionTableItem[] = [];
        const available = e.applicationsAvailability;
        if (ca === CompetitionAnalysis.INDUSTRY_DATA || e.competitors.length <= 0) {
          return dataItems;
        }
        dataItems.push(
          {
            title: 'ECOMMERCE.ECOMMERCE_SOLUTION',
            numberOfSolutions: this.getNumberOfSolutions(e.ecommerceSolution),
            dataFormat: FormatType.Check,
            control: this.controls.controls['hideEcommerceSolution'],
            available: available?.[APPLICATIONS_AVAILABILITY.ecommerceSolution],
            data: {
              business: e.ecommerceSolution.length > 0,
              examples: e.ecommerceSolution.map((a) => {
                return {
                  name: a.name,
                  value: null,
                };
              }),
            },
            competitors: e.competitors.map((c) => {
              return {
                name: c.name,
                value: c.ecommerceSolution.length > 0,
              };
            }),
          },
          {
            title: 'ECOMMERCE.ONLINE_PAYMENTS',
            numberOfSolutions: this.getNumberOfSolutions(e.onlinePayments),
            dataFormat: FormatType.Check,
            control: this.controls.controls['hideOnlinePayments'],
            available: available?.[APPLICATIONS_AVAILABILITY.onlinePayments],
            data: {
              business: e.onlinePayments.length > 0,
              examples: e.onlinePayments.map((a) => {
                return {
                  name: a.name,
                  value: null,
                };
              }),
            },
            competitors: e.competitors.map((c) => {
              return {
                name: c.name,
                value: c.onlinePayments.length > 0,
              };
            }),
          },
          {
            title: 'ECOMMERCE.AD_RETARGETING',
            numberOfSolutions: this.getNumberOfSolutions(e.adRetargeting),
            dataFormat: FormatType.Check,
            control: this.controls.controls['hideAdRetargeting'],
            available: available?.[APPLICATIONS_AVAILABILITY.adRetargeting],
            data: {
              business: e.adRetargeting.length > 0,
              examples: e.adRetargeting.map((a) => {
                return {
                  name: a.name,
                  value: null,
                };
              }),
            },
            competitors: e.competitors.map((c) => {
              return {
                name: c.name,
                value: c.adRetargeting.length > 0,
              };
            }),
          },
          {
            title: 'ECOMMERCE.APPOINTMENT_SCHEDULING',
            numberOfSolutions: this.getNumberOfSolutions(e.appointmentScheduling),
            dataFormat: FormatType.Check,
            control: this.controls.controls['hideAppointmentScheduling'],
            available: available?.[APPLICATIONS_AVAILABILITY.appointmentScheduling],
            datatestid: 'appointment-scheduling',
            data: {
              business: e.appointmentScheduling.length > 0,
              examples: e.appointmentScheduling.map((a) => {
                return {
                  name: a.name,
                  value: null,
                };
              }),
            },
            competitors: e.competitors.map((c) => {
              return {
                name: c.name,
                value: c.appointmentScheduling.length > 0,
              };
            }),
          },
        );
        return dataItems.filter((item) => item.available);
      }),
    );

    this.hasWebsite$ = combineLatest([this.service.data$, this.snapshotService.snapshotData$]).pipe(
      map(([ed, sd]) => {
        if (sd.website || ((this.hasValidEcommerceData(ed) || ed.website) && sd.inferredFields?.website)) {
          return true;
        }
        return false;
      }),
    );
  }

  getNumberOfSolutions(element: any): number {
    if (element === null || element.length === 0) {
      return 0;
    }
    return element.length;
  }

  getNameAndVersion(eco: Application[]): Application {
    if (eco.length > 0 && eco[0].name) {
      return <Application>{
        name: eco[0].name,
        version: eco[0].version,
      };
    } else {
      return <Application>{
        name: '',
        version: '',
      };
    }
  }

  hasValidEcommerceData(ed: EcommerceData): boolean {
    return (
      ed.adRetargeting.length > 0 ||
      ed.competitors.length > 0 ||
      ed.ecommerceSolution.length > 0 ||
      ed.onlinePayments.length > 0 ||
      ed.appointmentScheduling.length > 0
    );
  }
}
