import { of as observableOf } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { IAMService, PersonaType } from '@vendasta/iam';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { SessionService } from '@galaxy/core';
import { SnackbarService } from '@vendasta/galaxy/snackbar-service';

@Component({
  selector: 'app-iam-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  standalone: false,
})
export class LoginComponent implements OnInit {
  email: string;
  password: string;

  constructor(
    private iamService: IAMService,
    private readonly snackbarService: SnackbarService,
    private sessionService: SessionService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.route.queryParamMap.pipe(take(1)).subscribe((paramMap: ParamMap) => {
      if (paramMap.get('session_id')) {
        this.signIn(paramMap.get('session_id') || '');
      } else if (paramMap.get('error')) {
        const status: string | null = paramMap.get('status');
        if (status === '404') {
          this.snackbarService.openErrorSnack('User not found.');
        } else if (status === '401') {
          this.snackbarService.openErrorSnack('Incorrect email/password.');
        } else {
          this.snackbarService.openErrorSnack('An error occurred');
        }

        this.router.navigate(['/snapshot/login']);
      }
    });
  }

  private signIn(sessionId: string): void {
    this.sessionService.setSessionId(sessionId);
    // Demo Snapshot
    this.router.navigateByUrl('snapshot/721c5c14-2a3d-4306-827b-66fcc10e447c/edit');

    // Uncomment this to hit a Prod Snapshot by default
    // this.router.navigateByUrl('snapshot/18b03a02-a936-4711-93d2-bc136591ccdd/edit');
  }

  login(f: NgForm): void {
    if (!f.valid) {
      return;
    }
    this.iamService
      .login(this.email, this.password, PersonaType.sales_person)
      .pipe(
        take(1),
        catchError((e: HttpErrorResponse) => {
          if (e.status === 404) {
            this.snackbarService.openErrorSnack('User not found.');
          } else if (e.status === 401) {
            this.snackbarService.openErrorSnack('Incorrect email/password.');
          } else {
            this.snackbarService.openErrorSnack('An error occurred');
          }
          return observableOf('');
        }),
      )
      .subscribe((sessionId: string) => {
        this.signIn(sessionId);
      });
  }

  loginWithGoogle(): void {
    this.iamService.ssoLogin(window.location.href);
  }
}
