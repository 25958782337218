import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cityState',
  standalone: false,
})
export class CityStatePipe implements PipeTransform {
  static process(city: string, state: string): string {
    return !!city && !!state ? city + ', ' + state : city ? city : state ? state : '';
  }

  transform(city: string, state: string): string {
    return CityStatePipe.process(city, state);
  }
}
