import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormGroup } from '@angular/forms';
import { FooterCtaConfigInterface } from '@galaxy/snapshot';

@Component({
  selector: 'app-edit-button-dialog',
  templateUrl: './edit-button-dialog.component.html',
  styleUrls: ['./edit-button-dialog.component.scss'],
  standalone: false,
})
export class EditButtonDialogComponent {
  label: string;
  targetUrl: string;
  targetProductId: string;
  validationMessage?: string;

  formGroup: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<EditButtonDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FooterCtaConfigInterface,
  ) {
    this.label = data.label;
    this.targetUrl = data.targetUrl;
    this.targetProductId = data.targetProductId;
    this.formGroup = data.formGroup;
  }

  cancel(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    let targetUrl = this.targetUrl ?? '';
    if (targetUrl !== '' && !targetUrl.startsWith('https://') && !targetUrl.startsWith('http://')) {
      targetUrl = 'https://' + targetUrl;
    }
    this.formGroup.setValue({
      customizedFooterCtaLabel: this.label ?? '',
      customizedFooterCtaTargetUrl: targetUrl,
      customizedFooterCtaTargetProduct: this.targetProductId ?? '',
    });
    this.dialogRef.close();
  }
}
