import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SnapshotService } from '@vendasta/snapshot';
import {
  Observable,
  ReplaySubject,
  Subscription,
  combineLatest as observableCombineLatest,
  throwError as observableThrowError,
} from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { PartnerService } from '../partner/partner.service';
import { ROUTE_GENERATOR_TOKEN, RouteType, RoutingServiceInterface } from '../providers/providers';

@Component({
  selector: 'snap-snapshot-lite-salesforce',
  templateUrl: './snapshot-lite-salesforce.component.html',
  styleUrls: ['./snapshot-lite.component.scss'],
  standalone: false,
})
export class SnapshotLiteSalesforceComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  partnerId$: Observable<string>;
  businessId$: Observable<string>;
  private salesforceId$$: ReplaySubject<string> = new ReplaySubject<string>(1);
  salesforceId$: Observable<string> = this.salesforceId$$.asObservable();

  constructor(
    private _route: ActivatedRoute,
    private router: Router,
    private snapshotService: SnapshotService,
    private partnerService: PartnerService,
    @Inject(ROUTE_GENERATOR_TOKEN) public routeService: RoutingServiceInterface,
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this._route.params.subscribe((p) => {
        if ('salesforceId' in p) {
          this.salesforceId$$.next(p['salesforceId']);
        }
      }),
    );

    this.partnerId$ = this.partnerService.getPartnerId();

    this.businessId$ = observableCombineLatest([this.salesforceId$, this.partnerId$]).pipe(
      switchMap(([salesforceId, partnerId]) =>
        this.snapshotService.getBusinessIdFromSalesforceId(salesforceId, partnerId),
      ),
      catchError(() => {
        const route = this.routeService.getRoute({ routeType: RouteType.Error });
        if (route) {
          this.router.navigate([route], {
            skipLocationChange: true,
            queryParams: { error: 'NOT_FOUND.TITLE' },
          });
        }
        return observableThrowError(new Error('Not Found'));
      }),
    );

    this.subscriptions.push(
      this.businessId$.subscribe((businessId) => {
        const route = this.routeService.getRoute({ routeType: RouteType.Lite, businessId });
        this.router.navigate([route]);
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
