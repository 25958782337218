import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProductAnalyticsService } from '@vendasta/product-analytics';
import { GlobalConfigEnabledSection as EnabledSection } from '@vendasta/snapshot';
import { BehaviorSubject, Observable, Subscription, combineLatest } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { AdvertisingService } from '../advertising-section/advertising.service';
import { EcommerceService } from '../ecommerce-section/ecommerce.service';
import { GradeExplainerDialogComponent, GradeExplanationData } from '../grade/grade-explainer-dialog.component';
import { ListingService } from '../listing-section/listing.service';
import { Media, MediaToken, ROUTE_GENERATOR_TOKEN, RouteType, RoutingServiceInterface } from '../providers/providers';
import { ReviewService } from '../review-section/review.service';
import { SectionViewLayout } from '../section/section';
import { SEOService } from '../seo-section/seo.service';
import { SnapshotReportService } from '../snapshot-report/snapshot-report.service';
import { WebsiteService } from '../website-section/website.service';
import { SocialService } from './../social-section/social.service';

@Component({
  selector: 'snap-overall-score',
  templateUrl: './overall-score.component.html',
  styleUrls: ['./overall-score.component.scss'],
  standalone: false,
})
export class OverallScoreComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription[] = [];
  @Input() edit = false;
  @Input() hideHelp? = false;
  @Input()
  set layout(value: SectionViewLayout) {
    this.layout$$.next(value);
  }
  get layout(): SectionViewLayout {
    return this.layout$$.getValue();
  }
  private layout$$ = new BehaviorSubject<SectionViewLayout>('standard');

  businessName$: Observable<string>;
  enabledSections$: Observable<EnabledSection[]>;
  hideGrades$: Observable<boolean>;

  sectionsEnum = EnabledSection;
  readonly gradeSize$: Observable<string>;

  constructor(
    readonly snapshotService: SnapshotReportService,
    readonly listingService: ListingService,
    readonly seoService: SEOService,
    readonly websiteService: WebsiteService,
    readonly ecommerceService: EcommerceService,
    readonly socialService: SocialService,
    readonly advertisingService: AdvertisingService,
    readonly reviewService: ReviewService,
    private readonly analyticsService: ProductAnalyticsService,
    private readonly _dialog: MatDialog,
    @Inject(MediaToken) public readonly media: Media,
    @Inject(ROUTE_GENERATOR_TOKEN) private routeService: RoutingServiceInterface,
  ) {
    this.businessName$ = snapshotService.snapshotData$.pipe(map((d) => d.companyName));

    this.enabledSections$ = snapshotService.snapshotConfig$.pipe(map((c) => c.enabledSections));

    this.hideGrades$ = snapshotService.hideGrades$;

    this.gradeSize$ = combineLatest([this.media.mobile$, this.layout$$]).pipe(
      map(([isMobile, layout]) => (layout === 'compact' || isMobile ? 'small' : 'medium')),
      shareReplay(1),
    );
  }

  ngOnInit(): void {
    // overall-score is a web component and may be used in isolation; therefore we need to manually initialize these section services
    const enabledSectionsSubscription = this.enabledSections$.subscribe((sections) =>
      sections.forEach((s) => this.initSectionService(s)),
    );

    this._subscriptions = [enabledSectionsSubscription];
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }

  initSectionService(section: EnabledSection): void {
    switch (section) {
      case EnabledSection.LISTINGS:
        return this.listingService.init();
      case EnabledSection.SEO:
        return this.seoService.init();
      case EnabledSection.WEBSITE:
        return this.websiteService.init();
      case EnabledSection.ECOMMERCE:
        return this.ecommerceService.init();
      case EnabledSection.SOCIAL:
        return this.socialService.init();
      case EnabledSection.ADVERTISING:
        return this.advertisingService.init();
      case EnabledSection.REVIEWS:
        return this.reviewService.init();
    }
  }

  openExplainer(): void {
    this.analyticsService.trackEvent('overall', 'grade-explanation', 'click');

    const data: GradeExplanationData = {
      titleTextID: 'OVERALL.GRADE_EXPLANATION.TITLE',
      showThermometer: false,
      secondaryPreScaleTextID: 'OVERALL.GRADE_EXPLANATION.SECONDARY_PRE_SCALE',
      showGradeScale: true,
      secondaryPostScaleTextID: 'OVERALL.GRADE_EXPLANATION.SECONDARY_POST_SCALE',
    };
    this._dialog.open(GradeExplainerDialogComponent, {
      data: data,
      maxWidth: '960px',
    });
  }

  buildRouterLink(): string {
    const routeType = this.edit ? RouteType.Edit : RouteType.View;
    return this.routeService.getRoute({ routeType: routeType, snapshotId: this.snapshotService.snapshotId });
  }
}
