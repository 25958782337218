import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { NavigationEnd, Router } from '@angular/router';
import { Environment, EnvironmentService, SessionService } from '@galaxy/core';
import { TranslateService } from '@ngx-translate/core';
import { IAMService } from '@vendasta/iam';

declare let initializeHotJar: any;
declare let window: any;

@Component({
  viewProviders: [MatIconRegistry],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: false,
})
export class AppComponent implements OnInit {
  title = 'app';

  constructor(
    private environmentService: EnvironmentService,
    private _router: Router,
    private sessionService: SessionService,
    private iamService: IAMService,
    private translate: TranslateService,
    @Inject(DOCUMENT) private document: any,
  ) {
    translate.setDefaultLang('en');
  }

  ngOnInit(): void {
    if (this.environmentService.getEnvironment() === Environment.PROD) {
      initializeHotJar();
    }

    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const tree = this._router.parseUrl(this._router.url);
        if (tree.fragment) {
          const element = this.document.querySelector('#' + tree.fragment);
          if (element) {
            const body = this.document.body;
            const docEl = this.document.documentElement;
            const windowPageYOffset: number = (this.document.defaultView && this.document.defaultView.pageYOffset) || 0;
            const scrollTop = windowPageYOffset || docEl.scrollTop || body.scrollTop;
            const clientTop = docEl.clientTop || body.clientTop || 0;
            const box = element.getBoundingClientRect();
            const top = box.top + scrollTop - clientTop - 88;

            window.scrollTo({ left: 0, top: top, behavior: 'smooth' });
          }
        }
      }
    });
  }
}
