import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { Component, Inject, Input, ChangeDetectorRef, AfterViewChecked, OnInit } from '@angular/core';
import { AdvertisingService } from './advertising.service';
import { ChartData } from './adwords-bar-chart';
import { SectionServiceInterfaceToken } from '../section/section.service';
import { DomainMetrics } from './advertising-data';
import { BaseSectionComponent } from '../section/base-section.component';
import { analyticsCategory } from '../section-footer/section-footer.component';
import { CompetitionAnalysis } from '@vendasta/snapshot';
import { SnapshotService } from '../snapshot.service';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '@vendasta/galaxy/snackbar-service';

@Component({
  selector: 'snap-advertising-section',
  templateUrl: './advertising-section.component.html',
  styleUrls: ['./advertising-section.component.scss', './general.scss'],
  providers: [
    {
      provide: SectionServiceInterfaceToken,
      useExisting: AdvertisingService,
    },
  ],
  standalone: false,
})
export class AdvertisingSectionComponent extends BaseSectionComponent implements OnInit, AfterViewChecked {
  @Input() showPPC = false;
  @Input() showRetargeting = false;

  impressions$: Observable<ChartData> = new Observable<ChartData>();
  clicks$: Observable<ChartData> = new Observable<ChartData>();
  possibleImpressions$: Observable<number> = new Observable<number>();
  possibleClicks$: Observable<number> = new Observable<number>();

  businessPPCDomainMetrics$: Observable<DomainMetrics> = new Observable<DomainMetrics>();
  competitorsPPCDomainMetrics$: Observable<DomainMetrics[]> = new Observable<DomainMetrics[]>();

  isRetargeting$: Observable<boolean> = new Observable<boolean>();

  footerCategory = analyticsCategory.ADVERTISING;

  constructor(
    @Inject(SectionServiceInterfaceToken) public service: AdvertisingService,
    public snapshotService: SnapshotService,
    private cdRef: ChangeDetectorRef,
    public translateService: TranslateService,
    public snackbarService: SnackbarService,
  ) {
    super(service, snackbarService, translateService);
  }

  adwordsNotVisibleMessage(country: string, website: string): string {
    if (country !== 'US') {
      return 'ADVERTISING.ADWORDS.NOT_US';
    }
    if (website === '') {
      return 'ADVERTISING.ADWORDS.NO_WEBSITE';
    }
    return 'ADVERTISING.ADWORDS.DEFAULT_MESSAGE';
  }

  public ready(data: ChartData): void {
    const chartContainer = document.getElementById('adwords-clicks-chart');
    if (chartContainer) {
      let labelIndex = 1;
      [].forEach.call(chartContainer.getElementsByTagName('text'), (label: any) => {
        if (label.getAttribute('text-anchor') === 'end') {
          // Create a new span to hold the primary text
          label.textContent = '';
          const x = label.getAttribute('x') - 5;
          const labelTspan = document.createElementNS('http://www.w3.org/2000/svg', 'tspan');
          labelTspan.textContent = data.dataTable[labelIndex][0];
          labelTspan.setAttribute('x', x.toString());
          labelTspan.setAttribute('dy', '-0.6em');

          // get the average cost from the data table
          const temp = document.createElement('div');
          temp.innerHTML = data.dataTable[labelIndex][4];
          const average = temp.getElementsByTagName('b')[0];

          // create a new span to hold the average cost per click
          const cpcTspan = document.createElementNS('http://www.w3.org/2000/svg', 'tspan');
          cpcTspan.textContent = 'Average CPC: ' + average.innerText;
          cpcTspan.setAttribute('x', x.toString());
          cpcTspan.setAttribute('dy', '1.3em');
          cpcTspan.setAttribute('font-size', '10px');
          cpcTspan.setAttribute('font-weight', '100');

          // append back to the google chart label
          label.appendChild(labelTspan);
          label.appendChild(cpcTspan);
          labelIndex += 1;
        }
      });
    }
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.impressions$ = this.service.impressionsChartData$;
    this.possibleImpressions$ = this.service.possibleImpressions$;
    this.clicks$ = this.service.clicks$;
    this.possibleClicks$ = this.service.possibleClicks$;

    this.businessPPCDomainMetrics$ = this.service.data$.pipe(map((ls) => ls.payPerClickData.business));

    this.competitorsPPCDomainMetrics$ = combineLatest([
      this.snapshotService.competitionAnalysis$,
      this.service.data$,
    ]).pipe(
      map(([ca, ls]) => {
        const dataItems: DomainMetrics[] = [];
        if (ca === CompetitionAnalysis.INDUSTRY_DATA) {
          return dataItems;
        }
        ls.payPerClickData.competitors.forEach((c) => {
          dataItems.push(c);
        });
        return dataItems;
      }),
    );

    this.isRetargeting$ = this.service.data$.pipe(map((d) => d.isRetargeting));
  }
}
