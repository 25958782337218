import { booleanAttribute, Component, input } from '@angular/core';
import { CustomUserDropdownItem } from '../user-dropdown/user-dropdown.interface';

@Component({
  selector: 'atlas-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss', '../common.scss'],
  host: { '[class.hide-bottom-border]': 'hideBottomBorder()' },
  standalone: false,
})
export class AtlasTopBarComponent {
  dropdownItems = input<CustomUserDropdownItem[]>([]);
  hideCenters = input(false, { transform: booleanAttribute });
  hideNotifications = input(false, { transform: booleanAttribute });
  hideLanguageSelector = input(false, { transform: booleanAttribute });
  hideUserDropdown = input(false, { transform: booleanAttribute });
  hideBottomBorder = input(false, { transform: booleanAttribute });
}
