import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ToggleType } from '../toggled-item';

@Component({
  selector: 'snap-item-toggle',
  templateUrl: './item-toggle.component.html',
  styleUrls: ['./item-toggle.component.scss'],
  standalone: false,
})
export class ItemToggleComponent {
  @Input() label: string;
  @Input() hideControl: UntypedFormControl;
  @Input() isChild: boolean;
  @Input() disabled: boolean;
  @Input() type: ToggleType = 'checkbox';
  onChange(event: MatSlideToggleChange | MatCheckboxChange): void {
    this.hideControl.setValue(!event.checked);
    this.hideControl.markAsPending();
  }
}
