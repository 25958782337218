import { Component, Input, OnInit } from '@angular/core';
import { Audit } from '../../website-data';
import { MarkedParserService } from '../../../marked-parser.service';

//Learn more for all the languages we support
const learnMoreTranslations = [
  'Learn more',
  'En savoir plus',
  'Další informace',
  'Weitere Informationen',
  'Más información',
  'Meer informatie',
  'Saiba mais',
  'Подробнее…',
];

@Component({
  selector: 'snap-audit-report-details',
  templateUrl: './audit-report-details.component.html',
  styleUrls: ['./audit-report-details.component.scss'],
  standalone: false,
})
export class AuditReportDetailsComponent implements OnInit {
  @Input() audit: Audit;

  parsedAuditDescription: string;
  learnMoreUrl: string;

  constructor(private markedParserService: MarkedParserService) {}

  ngOnInit(): void {
    const parsedAuditDescription = this.markedParserService.removeLinks(this.audit.description, learnMoreTranslations);
    this.parsedAuditDescription = parsedAuditDescription.html.replace(/\.\s+\./, '.');
    this.learnMoreUrl = '';
    for (const link of parsedAuditDescription?.links ?? []) {
      if (learnMoreTranslations.filter((a) => a.toLowerCase() === link?.text.toLowerCase()).length > 0) {
        this.learnMoreUrl = link.href;
      }
    }
  }

  get icon(): string {
    if (this.audit.score < 0.5) {
      return 'cancel';
    } else if (this.audit.score >= 0.5 && this.audit.score < 0.9) {
      return 'error';
    }
    return 'check_circle';
  }
}
