import { Component, OnInit } from '@angular/core';
import { ConfigInterface, ContentInterface } from './section';
import { AbstractSectionService } from './section.service';

@Component({
  // abstract class
  template: ``,
  standalone: false,
})
export abstract class BaseHeaderComponent implements OnInit {
  constructor(public service: AbstractSectionService<ContentInterface, unknown, ConfigInterface>) {}

  ngOnInit(): void {
    this.service.init();
  }
}
