import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Business } from '../business';
import { SnapshotLiteService } from '@vendasta/snapshot';
import { SnackbarService } from '@vendasta/galaxy/snackbar-service';

@Component({
  selector: 'snap-override-business-data-dialog',
  templateUrl: './override-business-data-dialog.component.html',
  styleUrls: ['./override-business-data-dialog.component.scss'],
  standalone: false,
})
export class OverrideBusinessDataDialogComponent implements OnInit {
  businessId: string;
  editForm: UntypedFormGroup;
  business: Business;

  constructor(
    private snapshotLiteService: SnapshotLiteService,
    private readonly snackbarService: SnackbarService,
    public dialogRef: MatDialogRef<OverrideBusinessDataDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { businessId: string; business: Business },
  ) {
    this.businessId = data.businessId;
    this.business = data.business;
  }

  close(isSaved = false): void {
    this.dialogRef.close(isSaved);
  }

  submit(): void {
    const data: { [key: string]: string } = {};
    if (this.editForm.controls.companyName.dirty) {
      data['companyName'] = this.editForm.controls.companyName.value;
    }
    if (this.editForm.controls.address.dirty) {
      data['address'] = this.editForm.controls.address.value;
    }
    if (this.editForm.controls.city.dirty) {
      data['city'] = this.editForm.controls.city.value;
    }
    if (this.editForm.controls.state.dirty) {
      data['state'] = this.editForm.controls.state.value;
    }
    if (this.editForm.controls.zip.dirty) {
      data['zip'] = this.editForm.controls.zip.value;
    }
    if (this.editForm.controls.phoneNumber.dirty) {
      data['phoneNumber'] = this.editForm.controls.phoneNumber.value;
    }

    this.snapshotLiteService.updateSnapshotLiteBusinessData(this.businessId, data).subscribe(
      () => {
        this.close(true);
      },
      () => {
        this.snackbarService.openErrorSnack('ERRORS.GENERIC_ERROR', { duration: 3000 });
      },
    );
  }

  ngOnInit(): void {
    this.editForm = new UntypedFormGroup({
      companyName: new UntypedFormControl(this.business.companyName, [Validators.required]),
      address: new UntypedFormControl(this.business.address, [Validators.required]),
      city: new UntypedFormControl(this.business.city, []),
      state: new UntypedFormControl(this.business.state, []),
      zip: new UntypedFormControl(this.business.zip, []),
      phoneNumber: new UntypedFormControl(this.business.phoneNumber, [Validators.required]),
    });
  }
}
