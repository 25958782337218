import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

const DEFAULT_DIAMATER = 117;

@Component({
  selector: 'snap-score-donut',
  templateUrl: './score-donut.component.html',
  styleUrls: ['./score-donut.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MatProgressSpinnerModule],
})
export class ScoreDonutComponent {
  @Input() score: number | null = null;
  @Input() strokeWidth = 3;

  private _diameter: number = DEFAULT_DIAMATER;
  // allows diameter to be conditionally provided with a safe fallback when it is not properly provided
  @Input() set diameter(value: unknown) {
    const isValid = typeof value === 'number' && value > 0;
    this._diameter = isValid ? value : DEFAULT_DIAMATER;
  }
  get diameter(): number {
    return this._diameter;
  }
}
