import {
  AfterContentInit,
  Component,
  ContentChild,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { PanelContentComponent } from './panel-content.component';
import { PanelHeaderComponent } from './panel-header.component';

@Component({
  selector: 'atlas-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss'],
  standalone: false,
})
export class PanelComponent implements AfterContentInit, OnChanges, OnDestroy {
  @ContentChild(PanelHeaderComponent, { static: true }) header: PanelHeaderComponent;
  @ContentChild(PanelContentComponent, { static: true }) content: PanelContentComponent;

  private collapsed$$ = new BehaviorSubject<boolean>(null);

  // Publicly observable state
  public collapsed$: Observable<boolean> = this.collapsed$$;

  @Input() collapsible = false;
  get collapsed(): boolean {
    return this.collapsed$$.getValue();
  }

  @Input() set collapsed(collapsed: boolean) {
    this.collapsed$$.next(collapsed);
  }

  @HostBinding('class.atlas-panel__collapsed')
  get collapsedClass(): boolean {
    return this.collapsed$$.getValue();
  }

  private _destroyed$$: Subject<void> = new Subject<void>();

  updateChildrenCollapsed(collapsed: boolean): void {
    if (this.header) {
      this.header.collapsed = collapsed;
    }

    if (this.content) {
      this.content.collapsed = collapsed;
    }
  }

  ngAfterContentInit(): void {
    // Update header and content on collapse change
    this.collapsed$$.pipe(distinctUntilChanged(), takeUntil(this._destroyed$$)).subscribe((isCollapsed: boolean) => {
      this.updateChildrenCollapsed(isCollapsed);
    });

    this.updateChildrenCollapsed(this.collapsed);
    if (this.header) {
      this.header.collapsible = this.collapsible;
      // Allow header to update collapsed status
      this.header.collapsedChange
        .pipe(takeUntil(this._destroyed$$))
        .subscribe((isCollapsed) => this.collapsed$$.next(isCollapsed));
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.collapsible) {
      if (this.header) {
        this.header.collapsible = this.collapsible;
      }
    }
  }

  ngOnDestroy(): void {
    this._destroyed$$.next();
    this._destroyed$$.complete();
  }
}
