import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { SnackbarService } from '@vendasta/galaxy/snackbar-service';
import { ProductAnalyticsService } from '@vendasta/product-analytics';
import { BehaviorSubject, Observable, ReplaySubject, combineLatest, firstValueFrom } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { ListingV2DetailItem, ListingV2ListingStatusEdit } from '../listing-data';
import { ListingService } from '../listing.service';
import { Media, MediaToken, SNAPSHOT_ID_TOKEN } from './../../providers/providers';

@Component({
  selector: 'snap-details-table',
  templateUrl: './details-table.component.html',
  styleUrls: ['./details-table.component.scss'],
  standalone: false,
})
export class DetailsTableComponent implements OnInit {
  @Input() isStillGettingData: boolean;
  @Input() edit = false;
  @Input()
  set listings(val) {
    this.listings$$.next(val);
  }
  @Input()
  set isExpired(val) {
    this.isExpired$$.next(val);
  }

  private readonly isExpired$$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  protected isExpired$ = this.isExpired$$.asObservable();
  private readonly listings$$: ReplaySubject<ListingV2DetailItem[]> = new ReplaySubject<ListingV2DetailItem[]>(1);
  protected listings$: Observable<ListingV2DetailItem[]>;
  protected reducedListings$: Observable<ListingV2DetailItem[]>;
  protected totalRows$: Observable<number>;
  private readonly toggleAllRows$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  protected showAllRows$: Observable<boolean>;

  private _desktopColumns: string[] = ['listingSite', 'businessName', 'address', 'website', 'phone'];
  private _mobileColumns: string[] = ['listingSite', 'simplifiedListing'];

  defaultRows = 4;
  ListingStatus = ListingV2ListingStatusEdit;
  controls = new Map<number, FormControl>();
  isListingLoading = new Map<number, boolean>();
  displayedColumns$: Observable<string[]>;

  constructor(
    @Inject(MediaToken) public readonly media: Media,
    private readonly service: ListingService,
    private readonly snackbarService: SnackbarService,
    private readonly analyticsService: ProductAnalyticsService,
    @Inject(SNAPSHOT_ID_TOKEN) private snapshotId$: Observable<string | undefined>,
  ) {}

  getLoadingStatus(id: number): boolean {
    return this.isListingLoading.get(id) ?? false;
  }

  setLoadingStatus(id: number, status: boolean): void {
    this.isListingLoading.set(id, status);
  }

  ngOnInit(): void {
    this.showAllRows$ = combineLatest([this.toggleAllRows$$, this.media.print$]).pipe(
      map(([toggleAllRows, isPrint]) => toggleAllRows || isPrint),
    );
    this.listings$ = combineLatest([this.listings$$, this.isExpired$]).pipe(
      map(([listings, isExpired]) => {
        listings
          ?.filter((listing) => !this.controls.has(listing?.source?.sourceId))
          ?.forEach((listing) => {
            const name = listing?.source?.sourceId;
            if (name) {
              const control = new FormControl<boolean>(!listing.ignore);
              if (isExpired) {
                control.disable();
              }
              this.controls.set(name, control);
            }
          });
        return listings;
      }),
    );

    this.reducedListings$ = this.listings$.pipe(map((allListings) => allListings?.slice(0, this.defaultRows)));
    this.totalRows$ = this.listings$.pipe(map((listings) => listings?.length));

    this.displayedColumns$ = combineLatest([this.media.mobile$, this.media.print$]).pipe(
      distinctUntilChanged(),
      map(([isMobile, isPrint]) => (isMobile && !isPrint ? this._mobileColumns : this._desktopColumns)),
      map((columns) => {
        if (this.edit) {
          return [...columns, 'edit'];
        }
        return columns;
      }),
      shareReplay(1),
    );
  }

  toggleRows(showingAll: boolean): void {
    this.toggleAllRows$$.next(!showingAll);
  }

  async toggleListing(event: MatCheckboxChange, listing: ListingV2DetailItem): Promise<void> {
    const sourceId = listing?.source?.sourceId;
    const ctrl = this.controls.get(sourceId);
    this.setLoadingStatus(sourceId, true);

    const updateListing$ = this.snapshotId$.pipe(
      filter((snapshotId) => !!snapshotId),
      tap((snapshotId) => {
        this.analyticsService.trackEvent('snapshot-toggle-listing', 'snapshot', 'checkbox-toggled', 0, {
          enabled: event.checked,
          snapshotId: snapshotId,
          listingId: sourceId,
          listingName: listing?.source?.sourceName,
          listingStatus: listing?.status,
        });
      }),
      switchMap((snapshotId) => this.service.updateListing(snapshotId ?? '', sourceId.toString(), event.checked)),
    );
    await firstValueFrom(updateListing$)
      .then(
        () => {
          //success
          this.snackbarService.openSuccessSnack('LISTINGS.DETAILS.LISTING_MODIFIED');
          return;
        },
        () => {
          //error
          this.snackbarService.openErrorSnack('LISTINGS.DETAILS.UNABLE_TO_MODIFY_LISTING');
          ctrl.reset(!event.checked);
        },
      )
      .finally(() => {
        this.setLoadingStatus(sourceId, false);
      });
  }
}
