import { Pipe, PipeTransform } from '@angular/core';
import { formatNumber, CountryCode, NumberFormat } from 'libphonenumber-js';

@Pipe({
  name: 'phone',
  standalone: false,
})
export class PhonePipe implements PipeTransform {
  transform(number: string, country: string): string {
    return this.formatPhoneNumber(number, country);
  }

  formatPhoneNumber(n: string, c: string): string {
    const countryCode = c as CountryCode;
    const parts = n.split(',');
    let fmt: NumberFormat = 'NATIONAL';
    if (c === 'AU') {
      fmt = 'INTERNATIONAL';
    }
    let number = formatNumber(parts[0], countryCode, fmt);
    if (parts.length > 1) {
      number = number + ' ext. ' + parts[1];
    }
    return number;
  }
}
