import { Component, Input } from '@angular/core';
import { BaseHeaderComponent } from '../section/base-header.component';
import { SocialService } from './social.service';

@Component({
  selector: 'snap-social-section-header',
  template: `
    <snap-grade-tagline
      [hideGrade]="hideGrade"
      [grade]="service.grade$ | async"
      heading="{{ 'TITLES.SOCIAL' | translate }}"
      tagline="{{ 'SOCIAL.TAGLINE' | translate }}"
    ></snap-grade-tagline>
  `,
  styleUrls: ['./social-section.component.scss'],
  standalone: false,
})
export class SocialSectionHeaderComponent extends BaseHeaderComponent {
  @Input() hideGrade: boolean;

  constructor(public service: SocialService) {
    super(service);
  }
}
