import { Pipe, PipeTransform } from '@angular/core';
import { Audit } from '../../sections/website-section/website-data';
import { coreWebVitals } from '../../sections/website-section/website';

@Pipe({
  name: 'filterWebVitals',
  standalone: false,
})
export class FilterWebVitalsPipe implements PipeTransform {
  static process(audits: Audit[]): Audit[] {
    // filter out audits specified in the coreWebVitals array
    return audits.filter((a) => !coreWebVitals.includes(a.id));
  }

  transform(audits: Audit[]): Audit[] {
    return FilterWebVitalsPipe.process(audits);
  }
}
