import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '@vendasta/galaxy/snackbar-service';
import { Status } from '@vendasta/snapshot';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseSectionComponent } from '../../section/base-section.component';
import { SectionServiceInterfaceToken } from '../../section/section.service';
import { SnapshotReportService } from '../../snapshot-report/snapshot-report.service';
import { SnapshotService } from '../../snapshot.service';
import { LocalSEOData } from '../seo-data';
import { SEOService } from '../seo.service';

@Component({
  selector: 'snap-local-seo-subsection',
  templateUrl: './local-seo-subsection.component.html',
  providers: [
    {
      provide: SectionServiceInterfaceToken,
      useExisting: SEOService,
    },
  ],
  standalone: false,
})
export class LocalSeoSubsectionComponent extends BaseSectionComponent implements OnInit {
  @Output() customKeywordControl = new EventEmitter<AbstractControl>();
  localSeoDataMetrics$ = new Observable<LocalSEOData>();

  public get Status(): typeof Status {
    return Status;
  }

  constructor(
    @Inject(SectionServiceInterfaceToken) public service: SEOService,
    public snapshotService: SnapshotService,
    public snackbarService: SnackbarService,
    public translateService: TranslateService,
    public snapshotReportService: SnapshotReportService,
  ) {
    super(service, snackbarService, translateService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.customKeywordControl.emit(this.controls.controls['customKeyword']);

    this.localSeoDataMetrics$ = this.service.data$.pipe(map((d) => d.localSEOData));
  }
}
