import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { Component, Inject, Input, ChangeDetectorRef, AfterViewChecked, OnInit } from '@angular/core';
import { AdvertisingService } from '../advertising.service';
import { SectionServiceInterfaceToken } from '../../section/section.service';
import { DomainMetrics } from '../advertising-data';
import { BaseSectionComponent } from '../../section/base-section.component';
import { analyticsCategory } from '../../section-footer/section-footer.component';
import { CompetitionAnalysis } from '@vendasta/snapshot';
import { SnapshotService } from '../../snapshot.service';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '@vendasta/galaxy/snackbar-service';

@Component({
  selector: 'snap-campaign-performance-subsection',
  templateUrl: './campaign-performance-subsection.component.html',
  styleUrls: ['../advertising-section.component.scss', '../general.scss'],
  providers: [
    {
      provide: SectionServiceInterfaceToken,
      useExisting: AdvertisingService,
    },
  ],
  standalone: false,
})
export class CampaignPerformanceSubsectionComponent extends BaseSectionComponent implements OnInit, AfterViewChecked {
  @Input() showPPC = false;

  businessPPCDomainMetrics$: Observable<DomainMetrics> = new Observable<DomainMetrics>();
  competitorsPPCDomainMetrics$: Observable<DomainMetrics[]> = new Observable<DomainMetrics[]>();

  isRetargeting$: Observable<boolean> = new Observable<boolean>();

  footerCategory = analyticsCategory.ADVERTISING;

  constructor(
    @Inject(SectionServiceInterfaceToken) public service: AdvertisingService,
    public snapshotService: SnapshotService,
    private cdRef: ChangeDetectorRef,
    public translateService: TranslateService,
    public snackbarService: SnackbarService,
  ) {
    super(service, snackbarService, translateService);
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.businessPPCDomainMetrics$ = this.service.data$.pipe(map((ls) => ls.payPerClickData.business));

    this.competitorsPPCDomainMetrics$ = combineLatest([
      this.snapshotService.competitionAnalysis$,
      this.service.data$,
    ]).pipe(
      map(([ca, ls]) => {
        const dataItems: DomainMetrics[] = [];
        if (ca === CompetitionAnalysis.INDUSTRY_DATA) {
          return dataItems;
        }
        ls.payPerClickData.competitors.forEach((c) => {
          dataItems.push(c);
        });
        return dataItems;
      }),
    );

    this.isRetargeting$ = this.service.data$.pipe(map((d) => d.isRetargeting));
  }

  hasDefaultDomainMetrics(ppcDomainMetrics: DomainMetrics): boolean {
    return (
      ppcDomainMetrics.numberOfPaidKeywords === 0 &&
      ppcDomainMetrics.paidClicksPerMonth === 0 &&
      ppcDomainMetrics.monthlyAdwordsBudget === 0
    );
  }
}
