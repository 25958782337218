import { Component, Inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '@vendasta/galaxy/snackbar-service';
import { combineLatest, Observable } from 'rxjs';
import { SectionTableCompetitorItemInterface, SectionTableItem } from '../../section-table/section-table';
import { map } from 'rxjs/operators';
import { CompetitionAnalysis } from '@vendasta/snapshot';
import { BaseSectionComponent } from '../../section/base-section.component';
import { ReviewService } from '../review.service';
import { SnapshotReportService } from '../../snapshot-report/snapshot-report.service';
import { SectionServiceInterfaceToken } from '../../section/section.service';
import { Media, MediaToken } from '../../providers/providers';

@Component({
  selector: 'snap-review-subsection',
  templateUrl: './review-subsection.component.html',
  styleUrls: ['../review-section.component.scss'],
  providers: [
    {
      provide: SectionServiceInterfaceToken,
      useExisting: ReviewService,
    },
  ],
  standalone: false,
})
export class ReviewSubsectionComponent extends BaseSectionComponent implements OnInit {
  reviewTableItems$: Observable<SectionTableItem[]> = new Observable<SectionTableItem[]>();
  reviewCompetitorTableItems$: Observable<SectionTableItem[]> = new Observable<SectionTableItem[]>();

  constructor(
    @Inject(SectionServiceInterfaceToken) public service: ReviewService,
    public snapshotService: SnapshotReportService,
    @Inject(MediaToken) public readonly media: Media,
    public snackbarService: SnackbarService,
    public translateService: TranslateService,
  ) {
    super(service, snackbarService, translateService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.reviewTableItems$ = this.service.data$.pipe(
      map((rd) => [
        {
          title: 'REVIEWS.REVIEWS_FOUND',
          control: this.controls.controls['hideReviewsFound'],
          data: { ...rd.reviewsFound, hasColor: true },
        },
        {
          title: 'REVIEWS.REVIEWS_FOUND_PER_MONTH',
          subtitle: 'SOCIAL.SIX_MONTHS',
          control: this.controls.controls['hideReviewsFoundPerMonth'],
          data: { ...rd.reviewsFoundPerMonth, hasColor: true },
        },
        {
          title: 'REVIEWS.AVERAGE_REVIEW_SCORE',
          control: this.controls.controls['hideAverageReviewScore'],
          data: { ...rd.averageReviewScore, hasColor: true },
        },
        {
          title: 'REVIEWS.NUMBER_OF_REVIEW_SOURCE',
          control: this.controls.controls['hideNumberOfReviewSources'],
          data: { ...rd.numberOfReviewSources, hasColor: true },
        },
      ]),
    );
    this.reviewCompetitorTableItems$ = combineLatest([
      this.service.data$,
      this.snapshotService.competitionAnalysis$,
    ]).pipe(
      map(([rc, ca]) => {
        if (ca === CompetitionAnalysis.INDUSTRY_DATA) {
          return [];
        }
        const competitorDataReviewsFound: SectionTableCompetitorItemInterface[] = [];
        rc.competitors.forEach((c) => {
          competitorDataReviewsFound.push({
            name: c.companyName,
            value: c.reviewsFound,
          });
        });
        const reviewsFound = <SectionTableItem>{
          title: 'REVIEWS.REVIEWS_FOUND',
          control: this.controls.controls['hideReviewsFound'],
          data: { ...rc.reviewsFound, hasColor: true },
          competitors: competitorDataReviewsFound,
        };

        const competitorDataReviewsFoundPerMonth: SectionTableCompetitorItemInterface[] = [];
        rc.competitors.forEach((c) => {
          competitorDataReviewsFoundPerMonth.push({
            name: c.companyName,
            value: c.reviewsFoundPerMonth,
          });
        });
        const reviewsFoundPerMonth = <SectionTableItem>{
          title: 'REVIEWS.REVIEWS_FOUND_PER_MONTH',
          control: this.controls.controls['hideReviewsFoundPerMonth'],
          data: { ...rc.reviewsFoundPerMonth, hasColor: true },
          competitors: competitorDataReviewsFoundPerMonth,
        };

        const competitorDataAverageScore: SectionTableCompetitorItemInterface[] = [];
        rc.competitors.forEach((c) => {
          competitorDataAverageScore.push({
            name: c.companyName,
            value: c.averageReviewScore,
          });
        });
        const averageScore = <SectionTableItem>{
          title: 'REVIEWS.AVERAGE_REVIEW_SCORE',
          control: this.controls.controls['hideAverageReviewScore'],
          data: { ...rc.averageReviewScore, hasColor: true },
          competitors: competitorDataAverageScore,
        };

        const competitorDataSourcesFound: SectionTableCompetitorItemInterface[] = [];
        rc.competitors.forEach((c) => {
          competitorDataSourcesFound.push({
            name: c.companyName,
            value: c.numberOfReviewSources,
          });
        });

        const sourcesFound = <SectionTableItem>{
          title: 'REVIEWS.NUMBER_OF_REVIEW_SOURCE',
          control: this.controls.controls['hideNumberOfReviewSources'],
          data: { ...rc.numberOfReviewSources, hasColor: true },
          competitors: competitorDataSourcesFound,
        };

        return [reviewsFound, reviewsFoundPerMonth, averageScore, sourcesFound];
      }),
    );
  }
}
