import { Component, HostBinding, Input } from '@angular/core';

export type sizes = 'large' | 'medium' | 'default' | 'small';

@Component({
  // Inherited components ignore tempate property
  template: '',
  standalone: false,
})
export class ComponentWithSizesComponent {
  /**
   * Control the size of the component.
   *
   * Options are 'large' | 'default' | 'small'
   */
  @Input() size: sizes = 'default';
  @HostBinding('class.size-large') get isLarge() {
    return this.size === 'large';
  }
  @HostBinding('class.size-default') get isDefault() {
    return this.size === 'default' || this.size === 'medium';
  }
  @HostBinding('class.size-small') get isSmall() {
    return this.size === 'small';
  }
}
