import { Component, Inject, OnInit } from '@angular/core';
import { formatNumber } from '../util';
import { combineLatest as observableCombineLatest, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CompetitionAnalysis } from '@vendasta/snapshot';
import { ListingChartHeadingText, ListingChartReferenceData, ListingCompetitorsData } from '../chart/chart';
import { SectionServiceInterfaceToken } from '../../section/section.service';
import { ListingService } from '../listing.service';
import { SnapshotReportService } from '../../snapshot-report/snapshot-report.service';
import { ListingAccuracyData } from '../accuracy-table/accuracy-table-item';
import { ListingAccuracyInterface } from '../listing-data';
import { SnapshotData } from '../../snapshot-report/snapshot-report';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '@vendasta/galaxy/snackbar-service';
import { BaseSectionComponent } from '../../section/base-section.component';

@Component({
  selector: 'snap-listing-accuracy-subsection',
  templateUrl: './listing-accuracy-subsection.component.html',
  styleUrls: ['../listing-section.component.scss'],
  providers: [
    {
      provide: SectionServiceInterfaceToken,
      useExisting: ListingService,
    },
  ],
  standalone: false,
})
export class ListingAccuracySubsectionComponent extends BaseSectionComponent implements OnInit {
  listingAccuracyChartTitles: ListingChartHeadingText = {
    title: 'LISTINGS.ACCURACY.ACCURATE_COMPETITORS',
    subtitle: 'LISTINGS.ACCURACY.INDUSTRY_ACCURATE_COMPETITORS',
  };

  accuracyTableItems$: Observable<ListingAccuracyData[]> = new Observable<ListingAccuracyData[]>();
  listingPresenceCompetitors$: Observable<ListingCompetitorsData[]> = new Observable<ListingCompetitorsData[]>();
  listingAccuracyAccurateListings$: Observable<number> = new Observable<number>();
  listingAccuracyCompetitors$: Observable<ListingCompetitorsData[]> = new Observable<ListingCompetitorsData[]>();
  listingAccuracyReferenceData$: Observable<ListingChartReferenceData> = new Observable<ListingChartReferenceData>();
  listingAccuracyIndustryAverageAccurateListings$: Observable<number> = new Observable<number>();

  constructor(
    @Inject(SectionServiceInterfaceToken) public service: ListingService,
    public snapshotService: SnapshotReportService,
    public translateService: TranslateService,
    public snackbarService: SnackbarService,
  ) {
    super(service, snackbarService, translateService);
  }

  formatNumber = formatNumber;

  computeAccuracyFillPercentage(n: number): string {
    return n + '%';
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.listingPresenceCompetitors$ = combineLatest([
      this.service.data$,
      this.snapshotService.competitionAnalysis$,
    ]).pipe(
      map(([ld, ca]) => {
        if (ld.listingPresence == null || ca === CompetitionAnalysis.INDUSTRY_DATA) {
          return [];
        }
        return ld.listingPresence.competitors.map((c) => {
          return <ListingCompetitorsData>{
            name: c.companyName,
            updated: c.updated,
            value: c.foundCount,
          };
        });
      }),
    );

    this.listingAccuracyAccurateListings$ = this.service.data$.pipe(
      map((ld) => {
        if (ld.listingAccuracy != null && ld.listingAccuracy.accurateListings != null) {
          return ld.listingAccuracy.accurateListings * 100;
        }
        return 0;
      }),
    );

    this.listingAccuracyCompetitors$ = combineLatest([
      this.service.data$,
      this.snapshotService.competitionAnalysis$,
    ]).pipe(
      map(([ld, ca]) => {
        if (ld.listingAccuracy == null || ca === CompetitionAnalysis.INDUSTRY_DATA) {
          return [];
        }
        return ld.listingAccuracy.competitors.map((c) => {
          return <ListingCompetitorsData>{
            name: c.companyName,
            updated: c.updated,
            value: this.formatNumber(c.accurateListings * 100),
          };
        });
      }),
    );

    this.listingAccuracyIndustryAverageAccurateListings$ = this.service.data$.pipe(
      map((ld) => {
        if (ld.listingAccuracy != null && ld.listingAccuracy.industryAverageAccurateListings != null) {
          return this.formatNumber(ld.listingAccuracy.industryAverageAccurateListings * 100);
        }
        return 0;
      }),
    );

    this.listingAccuracyReferenceData$ = this.listingAccuracyIndustryAverageAccurateListings$.pipe(
      map((l) => {
        return <ListingChartReferenceData>{
          value: l,
          label: 'COMMON.INDUSTRY_AVERAGE',
        };
      }),
    );

    this.accuracyTableItems$ = observableCombineLatest([this.service.data$, this.snapshotService.snapshotData$]).pipe(
      map(([ld, sd]) => this.buildAccuracyTableItems(ld.listingAccuracy, sd)),
    );
  }

  buildAccuracyTableItems(accuracyItems: ListingAccuracyInterface, sd: SnapshotData): any {
    const result = [
      {
        attribute: 'LISTINGS.ACCURACY.INCORRECT_PHONE',
        icon: 'phone',
        inaccurateCount: accuracyItems.incorrectPhoneNumber.inaccurateCount,
        exampleData: accuracyItems.incorrectPhoneNumber.exampleData,
        sourceName: accuracyItems.incorrectPhoneNumber.sourceName,
        exampleListingUrl: accuracyItems.incorrectPhoneNumber.exampleListingUrl,
        control: this.controls.controls['hideListingAccuracyIncorrectPhone'],
      },
      {
        attribute: 'LISTINGS.ACCURACY.MISSING_PHONE',
        icon: 'phone',
        inaccurateCount: accuracyItems.missingPhoneNumber.inaccurateCount,
        exampleData: accuracyItems.missingPhoneNumber.exampleData,
        sourceName: accuracyItems.missingPhoneNumber.sourceName,
        exampleListingUrl: accuracyItems.missingPhoneNumber.exampleListingUrl,
        control: this.controls.controls['hideListingAccuracyMissingPhone'],
      },
      {
        attribute: 'LISTINGS.ACCURACY.INCORRECT_WEBSITE',
        icon: 'web',
        inaccurateCount: accuracyItems.incorrectWebsite.inaccurateCount,
        exampleData: accuracyItems.incorrectWebsite.exampleData,
        sourceName: accuracyItems.incorrectWebsite.sourceName,
        exampleListingUrl: accuracyItems.incorrectWebsite.exampleListingUrl,
        control: this.controls.controls['hideListingAccuracyIncorrectWebsite'],
      },
      {
        attribute: 'LISTINGS.ACCURACY.MISSING_WEBSITE',
        icon: 'web',
        inaccurateCount: accuracyItems.missingWebsite.inaccurateCount,
        exampleData: accuracyItems.missingWebsite.exampleData,
        sourceName: accuracyItems.missingWebsite.sourceName,
        exampleListingUrl: accuracyItems.missingWebsite.exampleListingUrl,
        control: this.controls.controls['hideListingAccuracyMissingWebsite'],
      },
    ];

    // if this is not a service area business, add the address matching lines
    if (!sd.serviceAreaBusiness) {
      result.splice(2, 0, {
        attribute: 'LISTINGS.ACCURACY.INCORRECT_ADDRESS',
        icon: 'location_on',
        inaccurateCount: accuracyItems.incorrectAddress.inaccurateCount,
        exampleData: accuracyItems.incorrectAddress.exampleData,
        sourceName: accuracyItems.incorrectAddress.sourceName,
        exampleListingUrl: accuracyItems.incorrectAddress.exampleListingUrl,
        control: this.controls.controls['hideListingAccuracyIncorrectAddress'],
      });
      result.splice(3, 0, {
        attribute: 'LISTINGS.ACCURACY.MISSING_ADDRESS',
        icon: 'location_on',
        inaccurateCount: accuracyItems.missingAddress.inaccurateCount,
        exampleData: accuracyItems.missingAddress.exampleData,
        sourceName: accuracyItems.missingAddress.sourceName,
        exampleListingUrl: accuracyItems.missingAddress.exampleListingUrl,
        control: this.controls.controls['hideListingAccuracyMissingAddress'],
      });
    }
    return result;
  }
}
