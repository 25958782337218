import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'atlas-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: CheckboxComponent, multi: true }],
  standalone: false,
})
export class CheckboxComponent implements ControlValueAccessor {
  @Input() disabled: boolean;
  @Input() color: 'default' | 'primary' | 'secondary' = 'default';
  private innerValue: boolean;

  private changed = new Array<(value: boolean) => void>();
  private touched = new Array<() => void>();

  get value(): boolean {
    return this.innerValue;
  }

  set value(value: boolean) {
    if (this.innerValue !== value) {
      this.innerValue = value;
      this.changed.forEach((f) => f(value));
    }
  }

  touch(): void {
    this.touched.forEach((f) => f());
  }

  writeValue(value: boolean): void {
    this.innerValue = value;
  }

  registerOnChange(fn: (value: boolean) => void): void {
    this.changed.push(fn);
  }

  registerOnTouched(fn: () => void): void {
    this.touched.push(fn);
  }
}
