import { Component, Inject } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { ListingV2DetailItem } from '../listing-data';
import { catchError, map } from 'rxjs/operators';
import { SectionServiceInterfaceToken } from '../../section/section.service';
import { ListingService } from '../listing.service';
import { StatusSummaryInterface, SummaryItem } from '../../status-summary/status-summary';
import { ListingV2ListingStatus } from '@vendasta/snapshot';
import { TranslateService } from '@ngx-translate/core';
import { SnapshotReportService } from '../../snapshot-report/snapshot-report.service';
import { SnackbarService } from '@vendasta/galaxy/snackbar-service';
import { BaseSectionComponent } from '../../section/base-section.component';
import { RefreshReportService } from '../../refresh-report/refresh-report.service';
import { SNAPSHOT_NAME_TOKEN } from '../../providers/providers';
import { GradeColor } from '../../../core';

@Component({
  selector: 'snap-listing-details-subsection',
  templateUrl: './listing-details-subsection.component.html',
  styleUrls: ['../listing-section.component.scss'],
  providers: [
    {
      provide: SectionServiceInterfaceToken,
      useExisting: ListingService,
    },
  ],
  standalone: false,
})
export class ListingDetailsSubsectionComponent extends BaseSectionComponent {
  snapshotEnabledListings$: Observable<ListingV2DetailItem[]>;
  statusSummary$: Observable<StatusSummaryInterface> = new Observable<StatusSummaryInterface>();
  listings$: Observable<ListingV2DetailItem[]>;

  constructor(
    @Inject(SectionServiceInterfaceToken) public listingSectionService: ListingService,
    @Inject(SNAPSHOT_NAME_TOKEN) readonly snapshotName$: Observable<string>,
    public snapshotService: SnapshotReportService,
    public translateService: TranslateService,
    public snackbarService: SnackbarService,
    private readonly refreshService: RefreshReportService,
  ) {
    super(listingSectionService, snackbarService, translateService);

    this.constructListingData();
  }

  private constructListingData(): void {
    this.snapshotEnabledListings$ = this.listingSectionService.snapshotEnabledListings$;
    this.listings$ = this.listingSectionService.allListings().pipe(
      catchError((error) => {
        this.snackbarService.openErrorSnack('ERRORS.ERROR_LOADING_LISTINGS');
        return throwError(() => error);
      }),
    );
    this.statusSummary$ = this.snapshotEnabledListings$.pipe(map((listings) => this.buildStatusSummaryItems(listings)));
  }

  private buildStatusSummaryItems(listings: ListingV2DetailItem[]): StatusSummaryInterface {
    const statusSummary = <StatusSummaryInterface>{
      SummaryItems: [],
    };

    statusSummary.SummaryItems.push(<SummaryItem>{
      value: listings.filter((listing) => listing.status === ListingV2ListingStatus.ACCURATE).length,
      description: this.translateService.instant('STATUS_SUMMARY.DETAILS.ACCURATE'),
      icon: 'check_circle',
      iconColor: GradeColor.positive,
    });
    statusSummary.SummaryItems.push(<SummaryItem>{
      value: listings.filter((listing) => listing.status === ListingV2ListingStatus.NOT_FOUND).length,
      description: this.translateService.instant('STATUS_SUMMARY.NOT_FOUND'),
      icon: 'cancel',
      iconColor: GradeColor.negative,
    });
    statusSummary.SummaryItems.push(<SummaryItem>{
      value: listings.filter((listing) => listing.status === ListingV2ListingStatus.INACCURATE).length,
      description: this.translateService.instant('STATUS_SUMMARY.POSSIBLE_ERRORS'),
      icon: 'error',
      iconColor: GradeColor.neutral,
    });

    return statusSummary;
  }

  openRefreshDialog(): void {
    this.refreshService.openRefreshDialog();
  }
}
