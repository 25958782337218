import { Component, OnInit, Input, ChangeDetectionStrategy, Inject, Optional } from '@angular/core';
import { catchError, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';
import { EMPTY, iif, Observable, of, ReplaySubject } from 'rxjs';
import { CategoriesApiService, Category, CategoryRequest, CategoryType, CategoryUtils } from '@vendasta/category';
import { LANGUAGE_TOKEN } from '../constants';
import { AtlasLanguageService } from '@galaxy/atlas/core';

@Component({
  selector: 'category-overview',
  templateUrl: './category-overview.component.html',
  styleUrls: ['./category-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class CategoryOverviewComponent implements OnInit {
  //#region Input/Output parameters
  @Input() placeholder: string;

  @Input()
  get categoryType(): CategoryType {
    return this._categoryType;
  }
  set categoryType(value: CategoryType) {
    this._categoryType = value;
    this.refreshCategories();
  }
  private _categoryType: CategoryType = CategoryType.CATEGORY_TYPE_V_CATEGORY;

  @Input()
  get categoryIds(): string[] {
    return this._categoryIds;
  }
  set categoryIds(value: string[]) {
    this._categoryIds = value;
    this.refreshCategories();
  }
  private _categoryIds: string[] = [];
  //#endregion

  categories$: Observable<Category[]>;
  private readonly request$$: ReplaySubject<CategoryRequest[]> = new ReplaySubject<CategoryRequest[]>(1);
  private language$: Observable<string>;

  constructor(
    private readonly categoryService: CategoriesApiService,
    @Optional() @Inject(LANGUAGE_TOKEN) private readonly languageToken$: Observable<string>,
    @Optional() private readonly atlasLanguageService: AtlasLanguageService,
  ) {
    this.language$ = this.languageToken$ ?? this.atlasLanguageService?.language$ ?? EMPTY;
  }

  ngOnInit(): void {
    this.initObservables();
  }

  private refreshCategories(): void {
    const categoryType = this.categoryType ?? CategoryType.CATEGORY_TYPE_V_CATEGORY;
    const ids = this.categoryIds ?? [];
    const req = ids.map((externalId) => <CategoryRequest>{ externalId, categoryType });
    this.request$$.next(req);
  }

  private compareRequests(a?: CategoryRequest[], b?: CategoryRequest[]): boolean {
    if (a?.length !== b?.length) {
      return false;
    }
    return !a?.find((elA) => !b?.find((elB) => elA.externalId === elB.externalId));
  }

  private initObservables(): void {
    // since the language is not required, we need to start with null to make sure that a value is emitted
    const language$ = this.language$.pipe(startWith(null), distinctUntilChanged());

    this.categories$ = this.request$$.pipe(
      distinctUntilChanged(this.compareRequests),
      switchMap((categories) =>
        iif(
          // only send the request if there are at least one category
          () => categories?.length > 0,
          language$.pipe(switchMap((language) => this.getCategories(categories, language))),
          of([]),
        ),
      ),
      catchError(() => []),
    );
  }

  private getCategories(categories: CategoryRequest[], language?: string): Observable<Category[]> {
    return this.categoryService
      .getCategoryByExternalIDsAndType({
        categories,
        languageAndLocale: CategoryUtils.localeFromLanguage(language),
      })
      .pipe(map((res) => res?.category));
  }
}
