import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AtlasConfigService, AtlasDataService } from '../../../../core/src';
import { CenterNavigationItem } from '@vendasta/atlas';
import { combineLatest, Observable, of } from 'rxjs';
import { map, shareReplay, startWith, switchMap, take } from 'rxjs/operators';
import { AtlasAccountNotificationService } from '../../account-notification.service';
import { SectionContainer } from '../../vbc_sdk/_internal/objects/notifications';
import { AccountsFormComponent } from './accounts-form/accounts-form.component';
import { SettingsFormComponent } from './settings-form/settings-form.component';
import { FeatureFlagService } from '@galaxy/partner';

enum Tab {
  PERSONAL = 'Personal',
  ACCOUNT = 'Account',
}

const SHOW_IN_BUSINESS_APP_FLAG = 'notifications_in_business_app';

@Component({
  selector: 'atlas-notification-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  standalone: false,
})
export class SettingsComponent implements OnInit {
  constructor(
    private config: AtlasConfigService,
    private data: AtlasDataService,
    private accountNotificationService: AtlasAccountNotificationService,
    private featureFlagService: FeatureFlagService,
  ) {}

  static SETTINGS_DIALOG_PARAM_ID = 'open_notification_settings';
  Tab = Tab;
  @ViewChild('settingsForm') settingsForm: SettingsFormComponent;
  @ViewChild('accountsForm') accountsForm: AccountsFormComponent;
  public partnerId$: Observable<string>;
  public userId$: Observable<string>;
  public accountGroupId$: Observable<string>;
  public redirectUrl$: Observable<string>;

  public accountsErrored = false;
  private selected: Tab;

  showInBizAppFlag$: Observable<boolean> = this.config.partnerId$.pipe(
    switchMap((partnerId) => this.featureFlagService.batchGetStatus(partnerId, '', [SHOW_IN_BUSINESS_APP_FLAG])),
    map((features) => {
      return features[SHOW_IN_BUSINESS_APP_FLAG];
    }),
  );
  showNotifications$: Observable<boolean> = combineLatest([this.data.centerData$, this.showInBizAppFlag$]).pipe(
    map(([centerData, showInBizAppFlag]) => {
      if (centerData.some((center: CenterNavigationItem) => center.centerId !== 'VBC')) {
        // we're not in business app
        return true;
      }
      return showInBizAppFlag;
    }),
  );

  public showAccountsTab$: Observable<boolean> = combineLatest([
    this.config.accountId$,
    this.showNotifications$,
    this.accountNotificationService.notificationSections$,
    this.accountNotificationService.notificationSectionsErrors$,
  ]).pipe(
    map(
      ([accountGroupId, showNotifications, notificationSections, notificationSectionsError]: [
        string,
        boolean,
        SectionContainer[],
        HttpErrorResponse | null,
      ]) => {
        const showNotificationSection =
          !!notificationSections || (notificationSectionsError && notificationSectionsError.status !== 404);
        return !!accountGroupId && showNotificationSection && showNotifications;
      },
    ),
  );

  public showPersonalTab$: Observable<boolean> = combineLatest([this.config.partnerId$, this.showNotifications$]).pipe(
    map(([partnerId, showNotifications]: [string, boolean]) => !!partnerId && showNotifications),
  );

  public showEmptyState$: Observable<boolean> = combineLatest([this.showAccountsTab$, this.showPersonalTab$]).pipe(
    map(([account, personal]: [boolean, boolean]) => !account && !personal),
    startWith(false),
  );

  ngOnInit(): void {
    this.partnerId$ = this.config.partnerId$;
    this.userId$ = this.data.userId$;
    this.accountGroupId$ = this.config.accountId$;
    this.redirectUrl$ = combineLatest([this.accountGroupId$, this.userId$, this.data.centerData$]).pipe(
      map(([agid, uid, centerData]) => {
        const businessAppData = centerData.find((center: CenterNavigationItem) => center.name === 'Business App');
        if (businessAppData) {
          return (
            businessAppData.entryUrl + '&nextUrl=' + '/account/location/' + agid + '/settings/notifications/' + uid
          );
        } else {
          return null;
        }
      }),
      shareReplay(1),
    );

    combineLatest([this.showAccountsTab$, this.showPersonalTab$])
      .pipe(
        take(1),
        switchMap(([account, personal]) => {
          if (account && !personal) {
            return this.redirectUrl$;
          } else {
            return of(null);
          }
        }),
      )
      .subscribe((url) => {
        if (url) {
          window.location.href = url;
        }
      });
  }

  save(): void {
    if (this.selected === Tab.PERSONAL) {
      this.settingsForm.save();
      return;
    }
    this.accountsForm.save();
  }

  reset(): void {
    if (this.selected === Tab.PERSONAL) {
      this.settingsForm.reset();
      return;
    }
    this.accountsForm.reset();
  }

  disableActions(): boolean {
    if (this.selected === Tab.PERSONAL) {
      return this.settingsForm ? !this.settingsForm.dirty() : true;
    }
    return this.accountsForm ? !this.accountsForm.dirty() : true;
  }

  isSaving(): boolean {
    if (this.selected === Tab.PERSONAL) {
      return this.settingsForm ? this.settingsForm.saving : false;
    }
    return this.accountsForm ? this.accountsForm.saving : false;
  }

  setSelected(selected: string): void {
    this.selected = selected as Tab;
  }
}
