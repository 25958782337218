import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ClaimAccountService } from '../claim-account/claim-account.service';
import { SnapshotLiteService } from '../snapshot-lite/snapshot-lite.service';
import { WhitelabelService } from '../whitelabel/whitelabel.service';
import { SalesPerson } from '@vendasta/snapshot';
import { SnackbarService } from '@vendasta/galaxy/snackbar-service';

@Component({
  selector: 'snap-lite-question-dialog',
  templateUrl: './lite-question-dialog.component.html',
  styleUrls: ['./lite-question-dialog.component.scss'],
  standalone: false,
})
export class LiteQuestionDialogComponent implements OnInit {
  accountGroupId: string;
  contactForm: UntypedFormGroup;
  salesPerson$: Observable<SalesPerson | null>;

  constructor(
    private claimAccountService: ClaimAccountService,
    public snapshotLiteService: SnapshotLiteService,
    public whitelabelService: WhitelabelService,
    private readonly snackbarService: SnackbarService,
    public dialogRef: MatDialogRef<LiteQuestionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.accountGroupId = data.accountGroupId;
    this.salesPerson$ = this.snapshotLiteService.salesperson$;
  }

  close(): void {
    this.dialogRef.close();
  }

  submit(): void {
    if (this.contactForm.valid) {
      this.claimAccountService
        .askQuestion(
          '',
          this.accountGroupId,
          this.contactForm.controls.firstName.value,
          this.contactForm.controls.lastName.value,
          this.contactForm.controls.message.value,
          this.contactForm.controls.phoneNumber.value,
        )
        .subscribe(
          () => {
            this.close();
          },
          () => {
            const announcementMessage = 'There was an error contacting your sales representative, please try again.';
            this.snackbarService.openErrorSnack(announcementMessage, { duration: 3000 });
          },
        );
    }
  }

  ngOnInit(): void {
    // TODO: Translation for default message
    this.contactForm = new UntypedFormGroup({
      phoneNumber: new UntypedFormControl('', [Validators.required]),
      firstName: new UntypedFormControl('', []),
      lastName: new UntypedFormControl('', []),
      message: new UntypedFormControl(
        "Hi, I've just viewed my report and I'm interested in fixing my online business " +
          'listings. Please contact me to get my business set up.',
        [Validators.required],
      ),
    });
  }
}
