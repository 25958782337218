import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotificationSetting, NotificationStatus } from '@vendasta/notifications-sdk';
import { formatDistanceToNow } from 'date-fns';
import { Notification } from '../notification';
import { SubscriptionState } from '../subscription-state';

@Component({
  selector: 'atlas-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss', '../../common.scss'],
  standalone: false,
})
export class NotificationComponent implements OnInit {
  @Input() notification: Notification;
  @Input() setting: NotificationSetting;
  @Input() visible: boolean;
  @Input() showContext: boolean;
  @Input() rollback: SubscriptionState = null;
  @Input() last: boolean;

  @Output() unsubscribe: EventEmitter<Notification> = new EventEmitter();
  @Output() subscribe: EventEmitter<Notification> = new EventEmitter();

  @Output() onclick: EventEmitter<void> = new EventEmitter();
  @Output() read: EventEmitter<string> = new EventEmitter();
  @Output() unread: EventEmitter<string> = new EventEmitter();
  @Output() hover: EventEmitter<void> = new EventEmitter();
  @Output() undo: EventEmitter<Notification> = new EventEmitter();

  SubscriptionStatus = NotificationStatus;

  isHovering = false;
  menuOpen = false;
  relativeTime: string;
  body: string;
  target = '_self';

  ngOnInit(): void {
    this.relativeTime = formatDistanceToNow(this.notification.eventTime, { addSuffix: true });
    this.body = this.notification.body.trim();

    const host = window.location.hostname;
    const parser = document.createElement('a');
    parser.href = this.notification.link;
    this.target = host === parser.host ? '_self' : '_blank';
  }

  getDisplayContext(): string {
    return this.notification.partnerId;
  }

  getColorForValue(value: string): string {
    // determine an icon color based on a value
    const COLOR_CODES = [
      '#EF5350',
      '#42A5F5',
      '#66BB6A',
      '#FFA726',
      '#AB47BC',
      '#FFCA28',
      '#EC407A',
      '#26C6DA',
      '#FF7B57',
    ];
    let nameSum = 0;
    if (!value) {
      return '#808080';
    }
    for (let i = 0; i < value.length; i++) {
      nameSum += value[i].charCodeAt(0);
    }
    const index = nameSum % COLOR_CODES.length;

    return COLOR_CODES[index];
  }

  onHover(): void {
    this.hover.emit();
    this.isHovering = true;
  }

  onLeave(): void {
    this.isHovering = false;
  }

  markAsRead(): void {
    this.read.emit(this.notification.id);
  }

  markAsUnread(): void {
    this.unread.emit(this.notification.id);
  }

  clickNotification(): void {
    this.onclick.emit();
  }

  emitUndo(): void {
    this.undo.emit(this.notification);
  }

  unsubscribeFrom(): void {
    this.unsubscribe.emit(this.notification);
  }

  subscribeTo(): void {
    this.subscribe.emit(this.notification);
  }
}
