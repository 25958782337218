import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TechnologyCategoryInterface } from './technology';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'snap-technology-category',
  imports: [CommonModule, MatIconModule],
  templateUrl: './technology-category.component.html',
  styleUrls: ['./technology-category.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TechnologyCategoryComponent {
  @Input() technologyCategory: TechnologyCategoryInterface;
}
