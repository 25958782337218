import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { MeetingType } from '@vendasta/meetings';
import { distinctUntilChanged, map, tap, take } from 'rxjs/operators';
import { ScheduleMeetingService } from '../schedule-meeting.service';
import { SnapshotReportService } from '@galaxy/snapshot';

@Component({
  selector: 'app-schedule-meeting-button',
  templateUrl: './schedule-meeting-button.component.html',
  styleUrls: ['./schedule-meeting-button.component.scss'],
  standalone: false,
})
export class ScheduleMeetingButtonComponent implements OnInit {
  meetingTypeList$: Observable<MeetingType[]>;
  showScheduleMeetingMenu$: Observable<boolean>;

  loading$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    public readonly snapshotReportService: SnapshotReportService,
    public readonly scheduleMeetingService: ScheduleMeetingService,
  ) {}

  ngOnInit(): void {
    this.meetingTypeList$ = this.scheduleMeetingService.meetingTypeList$;

    this.scheduleMeetingService.isHostConfigured$
      .pipe(take(1))
      .subscribe((configured) => this.loading$$.next(configured));

    this.showScheduleMeetingMenu$ = combineLatest([
      this.meetingTypeList$,
      this.snapshotReportService.snapshotConfig$,
      this.scheduleMeetingService.isHostConfigured$,
    ]).pipe(
      distinctUntilChanged(),
      map(([m, config, isHostConfig]) => {
        return m?.length > 0 && !config.hideScheduleMeetingOption && !!isHostConfig;
      }),
      tap(() => this.loading$$.next(false)),
    );
  }
}
