import { Component, Input } from '@angular/core';

@Component({
  selector: 'atlas-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  standalone: false,
})
export class LoaderComponent {
  @Input() size: number;
  @Input() thickness = 3;
}
