import { Component, Inject, Optional } from '@angular/core';
import { AtlasDataService, AtlasLanguageService } from '../../../../core/src';
import { LEXICON_TRANSLATE_PORTAL_URL } from '@galaxy/lexicon';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'atlas-language-selector-modal',
  templateUrl: './language-selector-modal.component.html',
  styleUrls: ['./language-selector-modal.component.scss'],
  standalone: false,
})
export class AtlasLanguageSelectorModalComponent {
  public readonly languages$ = this.languageService.languageList$.pipe(
    map((langs) => {
      return langs.sort((l1, l2) => l1.label.localeCompare(l2.label));
    }),
  );

  impersonating$ = this.dataService.impersonateeUsername$.pipe(map(Boolean));

  constructor(
    public languageService: AtlasLanguageService,
    private dataService: AtlasDataService,
    @Optional() @Inject(LEXICON_TRANSLATE_PORTAL_URL) public translatePortalUrl$: Observable<string>,
  ) {}

  public languageSelected(language: string): void {
    this.languageService.setLanguage(language);
  }

  public urlIsExternal(url: string): boolean {
    return url.startsWith('http');
  }
}
