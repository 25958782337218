import { Component, Input } from '@angular/core';
import { Grade } from '@vendasta/snapshot';
import { SectionTableItem } from '../section-table';

@Component({
  selector: 'snap-mobile-view-data',
  templateUrl: './mobile-view-data.component.html',
  styleUrls: ['./mobile-view-data.component.scss'],
  standalone: false,
})
export class MobileViewDataComponent {
  @Input() subsections: SectionTableItem[];
  @Input() edit: boolean;
  @Input() hideGrades: boolean;

  getGradeClass(g: Grade): string {
    return Grade[g];
  }

  isDataAvailable(industryData: number | string): boolean {
    return industryData !== null && industryData !== undefined;
  }
}
