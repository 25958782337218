import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Grade } from '@vendasta/snapshot';

type GradeSize = 'extra-small' | 'small' | 'medium' | 'large';

@Component({
  selector: 'snap-grade',
  templateUrl: './grade.component.html',
  styleUrls: ['./grade.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule],
})
export class GradeComponent {
  @Input() grade: Grade;
  @Input() size: GradeSize;
  @Input() showGrade = true;

  get gradeLetter(): string {
    return this.grade && this.grade > 0 ? Grade[this.grade] : '-';
  }

  get radius(): number {
    switch (this.size) {
      case 'large':
        return 33;
      case 'medium':
        return 25;
      case 'small':
        return 17;
      default:
        return 13;
    }
  }
}
