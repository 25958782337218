import { Component, input, Input, OnChanges, OnInit } from '@angular/core';
import { CenterNavigationItem } from '@vendasta/atlas';

@Component({
  selector: 'atlas-center-selector',
  templateUrl: './center-selector.component.html',
  styleUrls: ['./center-selector.component.scss'],
  standalone: false,
})
export class CenterSelectorComponent implements OnInit, OnChanges {
  @Input() centerData: CenterNavigationItem[] = [];
  @Input() serviceProviderId: string;
  readonly showNewMenu = input<boolean>(false);

  showSelector: boolean;

  ngOnInit(): void {
    this.calculateShowSelector();
  }

  ngOnChanges(): void {
    this.calculateShowSelector();
  }

  private calculateShowSelector(): void {
    if (!this.centerData) {
      return;
    }
    this.showSelector =
      this.centerData.filter((item: CenterNavigationItem) => {
        return item.centerId !== this.serviceProviderId;
      }).length > 0;
  }
}
