import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { GooglePlacesService as GoogleMapsService, GooglePlace } from '@vendasta/businesses/easy-account-create';
import { SnackbarService } from '@vendasta/galaxy/snackbar-service';
import { Observable, Subject } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { ConfirmationData, ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';
import { Competitor, SnapshotConfig, UserEvent } from '../../snapshot-report/snapshot-report';
import { SnapshotService } from '../../snapshot.service';

@Component({
  selector: 'snap-keyword-competitors',
  templateUrl: './keyword-competitors.component.html',
  styleUrls: ['./keyword-competitors.component.scss'],
  standalone: false,
})
export class KeywordCompetitorsComponent implements OnInit, OnDestroy {
  @ViewChildren('search') searchElementRef: QueryList<ElementRef>;

  @Input() expanded: boolean;
  @Input() isExpired: boolean;

  @Output() userEvent: EventEmitter<UserEvent> = new EventEmitter<UserEvent>();

  googleMap: google.maps.Map;
  businesses$: Observable<GooglePlace[]>;

  private _destroyed$$: Subject<void> = new Subject<void>();
  private _googleDestroyed$$: Subject<void> = new Subject<void>();

  competitorsForm: UntypedFormGroup = new UntypedFormGroup({
    competitors: new UntypedFormArray([
      this.competitorFormGroup(),
      this.competitorFormGroup(),
      this.competitorFormGroup(),
    ]),
  });

  isUpdatingCompetitors = false;

  get formCompetitors(): UntypedFormArray {
    return <UntypedFormArray>this.competitorsForm.get('competitors');
  }

  constructor(
    public snapshotService: SnapshotService,
    public formBuilder: UntypedFormBuilder,
    public translate: TranslateService,
    public snackbarService: SnackbarService,
    private googleMapsService: GoogleMapsService,
    private readonly dialog: MatDialog,
  ) {
    this.snapshotService.snapshotConfig$.pipe(takeUntil(this._destroyed$$)).subscribe((c: SnapshotConfig) => {
      this.competitorsForm.reset(); // reset the form to clear it, then patch in the values
      c.competitors
        .slice(0, 3)
        .filter((competitor) => competitor?.name && !competitor?.snapshotId)
        .forEach((competitor, index) => {
          this.formCompetitors.at(index).patchValue({
            name: competitor.name,
            website: competitor.website,
          });
        });
    });
  }

  ngOnInit(): void {
    if (this.isExpired) {
      this.competitorsForm.disable();
    }

    this.businesses$ = this.googleMapsService.googlePlaces$;
  }

  competitorFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      name: '',
      website: '',
    });
  }

  onMapReady(googleMap: google.maps.Map): void {
    this.googleMap = googleMap;
  }

  onFocusInputSearch(index: number): void {
    const searchElements = this.searchElementRef.toArray();
    this.googleMapsService.init(this.googleMap, searchElements[index].nativeElement, 'simple');
    this.setWebsite(index);
  }

  setWebsite(index: number): void {
    this._googleDestroyed$$.next(); // unsubscribe from previous subscription before creating new subscription

    this.businesses$
      .pipe(
        map((businesses: GooglePlace[]) => businesses[0]),
        filter((b: GooglePlace) => !!b.name),
        takeUntil(this._googleDestroyed$$),
      )
      .subscribe((b: GooglePlace) => {
        // set website to empty if the GooglePlace does not have a website
        const website = b.website ? b.website : '';

        this.formCompetitors.at(index).setValue({ name: b.name, website: website });
      });
  }

  onSubmit(data: { competitors: Competitor[] }): void {
    const snackbarDuration = 3000;
    const options: ConfirmationData = {
      title: this.translate.instant('COMPETITORS.CONFIRMATION_DATA.TITLE'),
      text: this.translate.instant('COMPETITORS.CONFIRMATION_DATA.TEXT'),
      confirmText: this.translate.instant('COMMON.PROCEED'),
      closeText: this.translate.instant('COMMON.CANCEL'),
    };

    const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      maxWidth: '550px',
      data: options,
    });
    confirmationDialog.afterClosed().subscribe((clicked: boolean) => {
      if (clicked) {
        this.userEvent.emit(<UserEvent>{ category: 'competitor-editor', action: 'click', label: 'update' });

        this.isUpdatingCompetitors = true;

        const competitorItems: Competitor[] = [];
        data.competitors.map((c) => {
          if (c?.name) {
            competitorItems.push({ name: c.name, website: c.website });
          }
        });
        this.snapshotService
          .updateConfig({ competitors: competitorItems })
          .pipe(take(1))
          .subscribe({
            error: (error) => {
              this.snackbarService.openErrorSnack('ERRORS.' + error.error.message, {
                duration: snackbarDuration,
              });
              this.isUpdatingCompetitors = false;
            },
            complete: () => {
              this.snackbarService.openSuccessSnack('EDIT_REPORT.UPDATE_COMPETITORS_SUCCESS', {
                duration: snackbarDuration,
              });
              this.isUpdatingCompetitors = false;
            },
          });
      }
    });
  }

  clearName(i: number): void {
    this.formCompetitors.at(i).patchValue({
      name: '',
    });
  }

  clearWebsite(i: number): void {
    this.formCompetitors.at(i).patchValue({
      website: '',
    });
  }

  ngOnDestroy(): void {
    this._destroyed$$.next();
    this._googleDestroyed$$.next();

    this._destroyed$$.complete();
    this._googleDestroyed$$.complete();
  }
}
