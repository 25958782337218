import { Component, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { VideoStyle } from '@vendasta/snapshot';
import { SelectInputOption } from '@vendasta/galaxy/input';

interface Locale {
  value: string;
  label: string;
}

@Component({
  selector: 'app-customize-report',
  templateUrl: './customize-report.component.html',
  styleUrls: ['./customize-report.component.scss'],
  standalone: false,
})
export class CustomizeReportComponent {
  @Input() hideControls: UntypedFormGroup;
  @Input() localeOptions: Locale[];
  @Input() expired: boolean;

  videoStyleOptions = [
    {
      value: VideoStyle.NO_VIDEOS,
      label: 'Hide Videos',
    },
    {
      value: VideoStyle.NORTH_AMERICAN,
      label: 'North American Voice-over',
    },
    {
      value: VideoStyle.AUSTRALIAN,
      label: 'Australian Voice-over',
    },
    {
      value: VideoStyle.UNITED_KINGDOM,
      label: 'British Voice-over',
    },
    {
      value: VideoStyle.SOUTH_AFRICA,
      label: 'South African Voice-over',
    },
    {
      value: VideoStyle.FRENCH,
      label: 'French Voice-over and Text',
    },
    {
      value: VideoStyle.NORTH_AMERICAN_LEGACY,
      label: 'Legacy - North American Voice-over',
    },
  ] as SelectInputOption[];

  get hideGrades(): UntypedFormControl {
    return <UntypedFormControl>this.hideControls.get('hideGrades');
  }
  get hideSubsectionGrades(): UntypedFormControl {
    return <UntypedFormControl>this.hideControls.get('hideSubsectionGrades');
  }
  get hideImages(): UntypedFormControl {
    return <UntypedFormControl>this.hideControls.get('hideImages');
  }
  get videoStyle(): UntypedFormControl {
    return <UntypedFormControl>this.hideControls.get('videoStyle');
  }
  get locale(): UntypedFormControl {
    return <UntypedFormControl>this.hideControls.get('locale');
  }
}
