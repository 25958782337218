<div>
  <mat-divider></mat-divider>
  <div class="label">{{ 'SIDEBAR.NEED_ASSISTANCE' | translate }}</div>
  <div class="actions">
    <div class="salesperson-info" data-testid="sidebar-contact-dialog" (click)="handleAskQuestionClick('sidebar')">
      @if (salesPerson) {
        @if (salesPerson.photoUrl) {
          <img class="salesperson-image" [src]="salesPerson.photoUrl" />
        } @else {
          <img
            class="salesperson-image"
            src="https://vstatic-prod.apigateway.co/snapshot-client.9223372035117973010.prod/assets/images/default_sales_person.jpg"
          />
        }
        <div class="salesperson-details-container">
          <span class="salesperson-name">{{ salesPerson.firstName }} {{ salesPerson.lastName }}</span>
          <span class="job-title">{{ salesPerson.jobTitle }}</span>
          <div *ngIf="salesPerson.phoneNumbers?.length > 0">
            <span>{{ salesPerson.phoneNumbers[0] | phone: salesPerson?.country }}</span>
          </div>
          <a
            class="salesperson-email"
            [glxyTooltip]="salesPerson.email"
            [glxyTooltipDisabled]="(media.mobile$ | async) || email.offsetWidth >= email.scrollWidth"
            href="mailto:{{ salesPerson.email }}"
            onclick="return false"
            #email
            >{{ salesPerson.email }}</a
          >
        </div>
      } @else {
        <img
          class="salesperson-image"
          src="https://vstatic-prod.apigateway.co/snapshot-client.9223372035117973010.prod/assets/images/default_sales_person.jpg"
        />
        <div class="salesperson-details-container">
          <span class="salesperson-name">{{ partnerName }}</span>
          <span class="salesperson-email">
            {{ 'COMMON.CONTACT_US' | translate }}
          </span>
        </div>
      }
    </div>
    <app-language-selector (languageSelected)="handleLanguageSelected($event)"></app-language-selector>
  </div>
</div>
