import { CdkOverlayOrigin, ConnectedPosition } from '@angular/cdk/overlay';
import { booleanAttribute, Component, ElementRef, HostBinding, Input } from '@angular/core';
import { PopoverPositions } from '@vendasta/galaxy/popover';

@Component({
  selector: 'glxy-tooltip,',
  templateUrl: './tooltip.component.html',
  standalone: false,
})
export class TooltipComponent {
  @HostBinding('class') class = 'glxy-tooltip';

  positions = [PopoverPositions.Top, PopoverPositions.Bottom];
  /** Origin that references the component the tooltip should be created relative to. Comes from CdkOverlayOrigin directive */
  @Input() origin!: CdkOverlayOrigin;

  /** Optional title for the tooltip */
  @Input() title?: string;

  /** text that can be added to the tooltip */
  @Input() text?: string;

  /** Change the background color and text for high contrast with page background */
  @Input({ transform: booleanAttribute }) highContrast = false;

  show = false;

  constructor(public elementRef: ElementRef) {}

  setOrigin(origin: CdkOverlayOrigin): void {
    this.origin = origin;
  }

  setText(text: string): void {
    this.text = text;
  }

  setTitle(title: string): void {
    this.title = title;
  }

  sethighContrast(mode: boolean): void {
    this.highContrast = mode;
  }

  setPositions(positions: ConnectedPosition[]): void {
    this.positions = positions;
  }

  showTooltip(): void {
    this.show = true;
  }

  hideTooltip(): void {
    this.show = false;
  }
}
