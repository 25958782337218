import { Component, Directive, HostBinding } from '@angular/core';

@Component({
  selector: 'glxy-confirmation-body',
  imports: [],
  templateUrl: './modal-body.component.html',
  styleUrls: ['./modal-body.component.scss'],
})
export class ModalBodyComponent {}

// Directives

@Directive({
  standalone: true,
  selector: 'glxy-confirmation-icon, [glxyConfirmationIcon]',
})
export class ConfirmationModalIconDirective {
  @HostBinding('class') class = 'glxy-confirmation-icon';
}

@Directive({
  standalone: true,
  selector: 'glxy-confirmation-title, [glxyConfirmationTitle]',
})
export class ConfirmationModalTitleDirective {
  @HostBinding('class') class = 'glxy-confirmation-title';
}

@Directive({
  standalone: true,
  selector: 'glxy-confirmation-message, [glxyConfirmationMessage]',
})
export class ConfirmationModalMessageDirective {
  @HostBinding('class') class = 'glxy-confirmation-message';
}

@Directive({
  standalone: true,
  selector: 'glxy-confirmation-custom-content, [glxyConfirmationCustomContent]',
})
export class ConfirmationModalCustomContentDirective {
  @HostBinding('class') class = 'glxy-confirmation-custom-content';
}
