import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '@vendasta/galaxy/snackbar-service';
import { analyticsCategory } from '../section-footer/section-footer.component';
import { BaseSectionComponent } from '../section/base-section.component';
import { SectionServiceInterfaceToken } from '../section/section.service';
import { SnapshotReportService } from '../snapshot-report/snapshot-report.service';
import { SocialService } from './social.service';

@Component({
  selector: 'snap-social-section',
  templateUrl: './social-section.component.html',
  styleUrls: ['./social-section.component.scss'],
  providers: [
    {
      provide: SectionServiceInterfaceToken,
      useExisting: SocialService,
    },
  ],
  standalone: false,
})
export class SocialSectionComponent extends BaseSectionComponent implements OnInit, OnDestroy {
  footerCategory = analyticsCategory.SOCIAL;

  constructor(
    @Inject(SectionServiceInterfaceToken) public service: SocialService,
    public snapshotService: SnapshotReportService,
    public translateService: TranslateService,
    public snackbarService: SnackbarService,
  ) {
    super(service, snackbarService, translateService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.subscriptions.push(
      this.snapshotService.competitors$.subscribe(() => {
        this.service.reload();
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
