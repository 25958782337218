import { Component, Input } from '@angular/core';
import { BaseHeaderComponent } from '../section/base-header.component';
import { SEOService } from './seo.service';

@Component({
  selector: 'snap-seo-section-header',
  template: `
    <snap-grade-tagline
      [hideGrade]="hideGrade"
      [grade]="service.grade$ | async"
      heading="{{ 'TITLES.SEO' | translate }}"
      tagline="{{ 'LOCAL_SEO.TAGLINE' | translate }}"
    ></snap-grade-tagline>
  `,
  standalone: false,
})
export class SeoSectionHeaderComponent extends BaseHeaderComponent {
  @Input() hideGrade: boolean;

  constructor(public service: SEOService) {
    super(service);
  }
}
