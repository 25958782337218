import { animate, state, style, transition, trigger } from '@angular/animations';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { PopoverPositions } from '@vendasta/galaxy/popover';
import { Grade } from '@vendasta/snapshot';
import { StatusSummaryInterface } from '../status-summary/status-summary';

@Component({
  selector: 'snap-subsection',
  templateUrl: './subsection.component.html',
  styleUrls: ['./subsection.component.scss'],
  animations: [
    trigger('toggleSection', [
      state('on', style({ height: '*' })),
      state('off', style({ height: '0' })),
      transition('on <=> off', animate('600ms ease-in')),
    ]),
  ],
  standalone: false,
})
export class SubsectionComponent {
  @Input() heading: string;
  @Input() subheading: string;
  @Input() hideControl: UntypedFormControl;
  @Input() edit: boolean;
  @Input() grade: Grade;
  @Input() hideGrade: boolean;
  @Input() statusSummary: StatusSummaryInterface;

  left: ConnectedPosition[] = [{ ...PopoverPositions.Left }];
}
