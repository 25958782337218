import { Component, Input, OnInit } from '@angular/core';
import { Audit } from '../website-data';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MarkedParserService } from '../../marked-parser.service';

@Component({
  selector: 'snap-website-audit-details',
  templateUrl: './website-audit-details.component.html',
  styleUrls: ['./website-audit-details.component.scss'],
  animations: [
    trigger('expandRules', [
      state('on', style({ height: '*' })),
      state('off', style({ height: '0' })),
      transition('on <=> off', animate('200ms ease-in')),
    ]),
  ],
  standalone: false,
})
export class WebsiteAuditDetailsComponent implements OnInit {
  @Input() audit: Audit;

  showDetails = false;
  parsedAuditDescription: string;
  learnMoreUrl: string;

  get icon(): string {
    if (this.audit.score < 0.5) {
      return 'error';
    } else if (this.audit.score >= 0.5 && this.audit.score < 0.9) {
      return 'warning';
    }
    return 'check_circle';
  }

  constructor(private markedParserService: MarkedParserService) {}

  ngOnInit(): void {
    this.parsedAuditDescription = this.markedParserService.convert(this.audit.description);
  }
}
