@if (isMobile()) {
  <button mat-icon-button (click)="openNotifications()">
    <mat-icon>notifications</mat-icon>
  </button>
} @else if (useNewTopBar()) {
  <button mat-icon-button cdkOverlayOrigin #trigger="cdkOverlayOrigin" (click)="open()">
    <mat-icon>notifications</mat-icon>
  </button>
  <ng-template
    #notificationsOverlay
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
    [cdkConnectedOverlayHasBackdrop]="true"
    [cdkConnectedOverlayOpen]="(open$$ | async) === true"
    [cdkConnectedOverlayGrowAfterOpen]="true"
    [cdkConnectedOverlayFlexibleDimensions]="true"
    (backdropClick)="close()"
  >
    <ng-container *ngTemplateOutlet="flyout"></ng-container>
  </ng-template>
} @else {
  <atlas-item icon="notifications" [badgeContent]="unviewed$ | async" interactable>
    <atlas-menu id="atlas-navbar__notifications" (onOpen)="open()" (onClose)="close()">
      <ng-container *ngTemplateOutlet="flyout"></ng-container>
    </atlas-menu>
  </atlas-item>
}

<ng-template #flyout>
  <atlas-flyout (markAllAsFollowed)="markAllAsFollowed()" (closed)="isMobile() ? closeNotificationsModal() : close()">
    <ng-container *ngIf="notificationsBuffer$$ | async as notifications; else loading">
      <div class="atlas-navbar__loading__empty" *ngIf="notifications?.length === 0">
        {{ 'NOTIFICATIONS.NO_NOTIFICATIONS' | translate }}
      </div>
      <ng-container *ngIf="notifications?.length > 0">
        <ng-container *ngIf="settingsByNotification$ | async as settings; else loading">
          <ng-container *ngFor="let notification of notifications; let last = last">
            <atlas-notification
              class="atlas-navbar__notification__item"
              [notification]="notification"
              [setting]="settings[notification.id]"
              [rollback]="getRollbackState(notification) | async"
              [showContext]="showContext"
              [last]="last"
              (onclick)="follow(notification)"
              (read)="setFollowed($event, true)"
              (unread)="setFollowed($event, false)"
              (subscribe)="updateSubscription(notification.id, settings[notification.id], SubscriptionState.Subscribe)"
              (unsubscribe)="
                updateSubscription(notification.id, settings[notification.id], SubscriptionState.Unsubscribe)
              "
              (undo)="undo(notification.id, settings[notification.id])"
            ></atlas-notification>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </atlas-flyout>
</ng-template>

<ng-template #loading>
  <div class="atlas-navbar__loading__empty" *ngIf="(error$ | async) === true">
    {{ 'NOTIFICATIONS.LOAD_FAILURE' | translate }}
  </div>
  <div class="atlas-navbar__loading" *ngIf="(error$ | async) === false">
    <atlas-loader [size]="24"></atlas-loader>
  </div>
</ng-template>
