import { Component } from '@angular/core';

@Component({
  selector: 'snap-section-stencil',
  template: `
    <div class="card-stencil-container">
      <mat-card appearance="outlined" class="card-stencil">
        <div class="card-stencil-section">
          <div class="card-stencil-box stencil-shimmer"></div>
          <div class="card-stencil-area stencil-shimmer"></div>
        </div>
        <div class="card-stencil-section">
          <div class="card-stencil-circle stencil-shimmer"></div>
          <div>
            <div class="card-stencil-line-large stencil-shimmer"></div>
            <div class="card-stencil-line-medium stencil-shimmer"></div>
          </div>
        </div>
        <div class="card-stencil-section">
          <div class="card-stencil-circle stencil-shimmer"></div>
          <div>
            <div class="card-stencil-line-large stencil-shimmer"></div>
            <div class="card-stencil-line-medium stencil-shimmer"></div>
          </div>
        </div>
      </mat-card>
    </div>
  `,
  styleUrls: ['section-stencil.component.scss'],
  standalone: false,
})
export class SectionStencilComponent {}
