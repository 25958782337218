import { Component } from '@angular/core';

@Component({
  selector: 'app-not-found',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
  standalone: false,
})
export class NotFoundComponent {
  titleId: string;

  constructor() {
    this.titleId = 'NOT_FOUND.TITLE';
  }
}
