import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { InkBarComponent } from '../ink-bar/ink-bar.component';

@Component({
  selector: 'atlas-tab-header',
  templateUrl: './tab-header.component.html',
  styleUrls: ['./tab-header.component.scss'],
  standalone: false,
})
export class TabHeaderComponent implements OnChanges, OnDestroy, AfterViewInit {
  @ViewChild(InkBarComponent, { read: ElementRef }) _inkBar: ElementRef;
  @Input() labels: string[];
  @Output() selected: EventEmitter<string> = new EventEmitter<string>();
  public activeLabel$$: ReplaySubject<string> = new ReplaySubject<string>(1);
  private _destroyed$$: Subject<void> = new Subject<void>();

  constructor(private renderer: Renderer2) {}

  ngAfterViewInit(): void {
    this.activeLabel$$
      .pipe(
        takeUntil(this._destroyed$$),
        filter((label: string) => !!label),
      )
      .subscribe((label: string) => {
        this.selected.emit(label);
        if (this._inkBar) {
          const activeTab: HTMLElement = document.getElementById(`atlas-navbar__tab__${label}`);
          this.renderer.setStyle(this._inkBar.nativeElement, 'visibility', 'visible');
          this.renderer.setStyle(this._inkBar.nativeElement, 'width', activeTab.clientWidth + 'px');
          this.renderer.setStyle(this._inkBar.nativeElement, 'left', activeTab.offsetLeft + 'px');
        }
      });
  }

  ngOnChanges(): void {
    if (this.labels && this.labels.length > 0) {
      this.activeLabel$$.next(this.labels[0]);
    }
  }

  setActive(label: string): void {
    this.activeLabel$$.next(label);
  }

  ngOnDestroy(): void {
    this._destroyed$$.next();
    this._destroyed$$.complete();
  }
}
