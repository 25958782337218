import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { PopoverComponent } from '../popover.component';

@Directive({
  selector: '[glxyPopover]',
  exportAs: 'glxyPopover',
  standalone: false,
})
export class GalaxyPopoverDirective implements OnInit {
  /** Passed in reference of the popover */
  @Input('glxyPopover') popover?: PopoverComponent;

  private readonly origin: CdkOverlayOrigin;

  constructor(private elementRef: ElementRef) {
    this.origin = new CdkOverlayOrigin(this.elementRef);
  }

  ngOnInit(): void {
    this.popover?.setOrigin(this.origin);
  }
}
