import { MediaUploader } from '@vendasta/forms';

import { FieldBase, FieldData } from './field-base';

export class ImageUploadField extends FieldBase<Image> {
  acceptedMimeTypes: string[];
  minFileSize?: number;
  maxFileSize?: number;
  uploadUrl: string;
  customMediaUploader: MediaUploader;
  maxWidth?: number;
  maxHeight?: number;

  setFieldData(fieldData: FieldData): void {
    super.setFieldData(fieldData);
    this.value = {
      fileurl: fieldData.value.fileurl || '',
      filename: fieldData.value.filename || '',
      filepath: fieldData.value.filepath || '',
      url: fieldData.value.fileurl || '',
    };
  }

  get uploadData(): any {
    return {
      name: this.name,
      minFileSize: this.minFileSize,
      maxFileSize: this.maxFileSize,
      acceptedMimeTypes: this.acceptedMimeTypes,
      maxHeight: this.maxHeight,
      maxWidth: this.maxWidth,
    };
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isImageUploadField',
  standalone: false,
})
export class IsImageUploadFieldPipe implements PipeTransform {
  transform(value: unknown) {
    const isImageUploadField = value instanceof ImageUploadField;
    if (!isImageUploadField) {
      console.error('Control is not an instance of ImageUpload', value);
    }
    return isImageUploadField;
  }
}

export interface Image {
  fileurl: string;
  filename?: string;
  filepath?: string;
  url?: string;
}
