import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'snap-try-again',
  templateUrl: './try-again.component.html',
  styleUrls: ['./try-again.component.scss'],
  standalone: false,
})
export class TryAgainComponent {
  @Output() refreshSectionClickedEvent: EventEmitter<void> = new EventEmitter<void>();

  refreshSection(): void {
    this.refreshSectionClickedEvent.emit();
  }
}
