import { Component, Input } from '@angular/core';
import { AtlasMenuService } from '../menu/menu.service';

@Component({
  selector: 'atlas-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss', '../common.scss'],
  standalone: false,
})
export class MenuItemComponent {
  @Input() url: string;
  @Input() route: string;
  @Input() label: string;
  @Input() icon: string;
  @Input() closeOnClick = true;
  @Input() disabled: boolean;
  public parentMenuId: string;

  constructor(private menuService: AtlasMenuService) {}

  clicked(): void {
    if (this.closeOnClick) {
      this.menuService.closeMenu(this.parentMenuId);
    }
  }

  // This is a temporary solution for allowing the Academy/Community Conquer Local links to be opened in a new tab when click.
  isConquerLocalLink(url: string): boolean {
    const regEx = new RegExp('(academy|community).conquerlocal.com');
    return regEx.test(url);
  }
}
