import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

// if we want to bypass sanitizer for more data types
// https://medium.com/@swarnakishore/angular-safe-pipe-implementation-to-bypass-domsanitizer-stripping-out-content-c1bf0f1cc36b

/**
 * A pipe to bypass Angular's dom sanitizer. Use when the html you wish to inject is safe, or will be used in a secure manner
 */
@Pipe({
  name: 'iTrustThisHtml',
  standalone: false,
})
export class GalaxyITrustThisHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
