import { CurrencyPipe, DecimalPipe, PercentPipe } from '@angular/common';
import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

const DEFAULT_CURRENCY_CODE = 'USD';
const DEFAULT_LOCALE = 'en-us';
export type DisplayType = 'currency' | 'percentage' | 'number' | 'string' | 'translate' | 'fraction';

export interface DisplayValueDescriptor {
  value: number | string;
  displayType: DisplayType;
  outOfValue?: number | string;
  currencyCode?: string;
  locale?: string;
  maxDecimalPlaces?: number;
}

/**
 * Pipe that transforms a value based on its type.
 *
 * @deprecated Use angular built-in pipes instead.
 * - For currency, use `currency` (CurrencyPipe) from `@angular/common`.
 * - For percentage, use `percent` (PercentPipe) from `@angular/common`.
 * - For number, use `number` (DecimalPipe) from `@angular/common`.
 * - For string, use the value as is.
 * - For translation, use `translate` pipe from `@ngx-translate/core`.
 * - For fraction, use a custom translation key that represents the fraction.
 */
@Pipe({
  name: 'displayValue',
  standalone: false,
})
export class DisplayValuePipe implements PipeTransform, OnDestroy {
  value: number | string;
  outOfValue?: number | string;
  displayType?: DisplayType;
  currencyCode: string;
  locale = DEFAULT_LOCALE;
  displayValue: any;

  onTranslationChange?: Subscription;
  onLangChange?: Subscription;
  onDefaultLangChange?: Subscription;
  digitsInfo?: string;

  constructor(private translateService: TranslateService) {}

  updateValue(): void {
    switch (this.displayType) {
      case 'currency': {
        const currencyPipe = new CurrencyPipe(this.locale);
        this.displayValue = currencyPipe.transform(this.value, this.currencyCode, 'symbol', '1.0-2', this.locale);
        break;
      }
      case 'percentage': {
        const percentPipe = new PercentPipe(this.locale);
        this.displayValue = percentPipe.transform(this.value, this.digitsInfo ? this.digitsInfo : '1.0-2', this.locale);
        break;
      }
      case 'number': {
        const decimalPipe = new DecimalPipe(this.locale);
        this.displayValue = decimalPipe.transform(this.value, this.digitsInfo, this.locale);
        break;
      }
      case 'string':
        this.displayValue = this.value;
        break;
      case 'translate':
        this.displayValue = this.translateService.instant(this.value as string);
        break;
      case 'fraction':
        this.displayValue = this.value + '/' + this.outOfValue;
        break;
    }
  }

  transform(displayValue: DisplayValueDescriptor): any {
    if (!displayValue) {
      return undefined;
    }
    const { value, outOfValue, displayType, currencyCode = DEFAULT_CURRENCY_CODE, locale } = displayValue;
    this.value = value;
    this.outOfValue = outOfValue;
    this.displayType = displayType;
    this.currencyCode = currencyCode;
    this.locale = locale || this.locale;
    this.digitsInfo =
      displayValue.maxDecimalPlaces || displayValue.maxDecimalPlaces === 0
        ? '1.0-' + displayValue.maxDecimalPlaces
        : undefined;

    this.updateValue();

    if (!this.onTranslationChange) {
      this.onTranslationChange = this.translateService.onTranslationChange.subscribe(() => {
        this.updateValue();
      });
    }
    if (!this.onLangChange) {
      this.onLangChange = this.translateService.onLangChange.subscribe(() => {
        this.updateValue();
      });
    }
    if (!this.onDefaultLangChange) {
      this.onDefaultLangChange = this.translateService.onDefaultLangChange.subscribe(() => {
        this.updateValue();
      });
    }

    return this.displayValue;
  }

  ngOnDestroy(): void {
    if (typeof this.onTranslationChange !== 'undefined') {
      this.onTranslationChange.unsubscribe();
      this.onTranslationChange = undefined;
    }
    if (typeof this.onLangChange !== 'undefined') {
      this.onLangChange.unsubscribe();
      this.onLangChange = undefined;
    }
    if (typeof this.onDefaultLangChange !== 'undefined') {
      this.onDefaultLangChange.unsubscribe();
      this.onDefaultLangChange = undefined;
    }
  }
}
